<template>
  <div>
    <div v-if="isLoading" class="loading-container">
      <loading></loading>
    </div>
    <div v-else>
      <div class="tit">
        <h2>仲裁员办案情况</h2>
        <el-link class="link" @click="$parent.openPopup('zcymore',null)">更多<i class="el-icon-d-arrow-right"></i>
        </el-link>
      </div>
      <div class="uittab">
        <div class="tps">
          <h3>姓名</h3>
          <h3>承办<br/>
            总数</h3>
          <h3>已办结</h3>
          <h3>未办结</h3>
          <h3>收结比</h3>
          <h3>超期<br/>
            办结</h3>
          <h3>超期<br/>
            未办结</h3>
          <h3>被撤<br/>
            销案件</h3>
          <h3>不予<br/>
            执行案件</h3>
        </div>
        <dl>
          <template v-for="(item,index) in data">
            <dd :key="index">
              <h4><a style="cursor:pointer;" @click="goToDetails(item.zcyId)">{{ item.zcyName }}</a></h4>
              <h4><a style="cursor:pointer;color: #67f5be;" @click="$parent.openPopup('popup', item)">{{
                  item.zongShu
                }}</a></h4>
              <h4><a style="cursor:pointer;color: #ff7a6b;" @click="$parent.openPopup('ybjpopup', item)">{{
                  item.jieAnShu
                }}</a></h4>
              <h4><a style="cursor:pointer;color: #83acff;"
                     @click="$parent.openPopup('wbjpopup', item)">{{ item.weiJieAnShu }}</a></h4>
              <h4>{{ (item.jieAnShu / item.zongShu * 100).toFixed(2) }}%</h4>
              <h4><a style="cursor:pointer;color: #67d5f5;"
                     @click="$parent.openPopup('cqbjpopup', item)">{{ item.chaoqibanjie }}</a></h4>
              <h4><a style="cursor:pointer;color: #f56792;"
                     @click="$parent.openPopup('cqwbjpopup', item)">{{ item.Chaoqiweibanjie }}</a></h4>
              <h4><a style="cursor:pointer;color: #cff567;"
                     @click="$parent.openPopup('bcxpopup', item)">{{ item.beichexiao }}</a></h4>
              <h4><a style="cursor:pointer;color: #cff567;"
                     @click="$parent.openPopup('byzxpopup', item)">{{ item.buyuzhixing }}</a></h4>
            </dd>
          </template>
        </dl>
      </div>
    </div>
  </div>
</template>
<!--
  /**
   * @Description
   * @className ArbitratorCaseCompletions.vue
   * @author Zhu
   * @date 2023-10-07 9:15
   * @TODO 仲裁员办结案
   * @param
   * @return
   */
-->
<script>
export default {
  name: "ArbitratorCaseCompletions",
  components: {},
  props: {
    selectedyear: Number
  },
  data() {
    return {
      data: null,
      isLoading: true, // 加载状态标志
    }
  },
  computed: {},
  watch: {
    selectedyear(newYear, oldYear) {
      this.init();
    }
  },
  methods: {
    init() {
      this.isLoading = true; // 开始加载数据
      this.axios.get('/pingZhan/report/ArbitratorCaseCompletions', {
        params: {
          currentYear: this.selectedyear
        }
      }).then((response) => {
        //console.log(response);
        let data = response.data;
        if (data.code == 200) {
          this.data = data.data;
        } else {
          console.log('数据获取失败');
        }
      }).catch((error) => {
        console.error('发生错误:', error);
      }).finally(() => {
        this.isLoading = false; // 数据加载完成
      });
    },
    openYbjPop(item) {
      this.$parent.openPopup('ybjpopup', item);
    },
    openWbjPop(item) {
      this.$parent.openPopup('wbjpopup', item);
    },
    openCqbjPop(item) {
      this.$parent.openPopup('cqbjpopup', item);
    },
    openCqwbjPop(item) {
      this.$parent.openPopup('cqwbjpopup', item);
    },
    openByzxPop(item) {
      this.$parent.openPopup('byzxpopup', item);
    },
    goToDetails(id) {
      const baseUrl = window.location.origin; // 获取应用的基础 URL
      const url = `${baseUrl}/zcy/${id}`; // 构建完整 URL
      window.open(url, '_blank'); // 在新标签页打开 URL
    }

  },
  created() {
    this.init()
  },
  mounted() {

  }
};
</script>

<style scoped>
.link {
  color: white;
  position: relative;
  left: 90%;
  margin-top: -11%;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
