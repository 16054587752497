<template>
  <div>
    <div v-if="isLoading" class="loading-container">
      <loading></loading>
    </div>
    <div v-if="!isLoading">
      <div class="tps">
        <ul>
          <li style="cursor:pointer;" @click="$parent.openPopup('zcjdchao', item)">
            <h3><span class="counter">{{ chao30 }}</span></h3>
            <p>超30天未组庭数</p>
          </li>
          <li style="cursor:pointer;" @click="$parent.openPopup('zcjdcsx', item)">
            <h3><span class="counter">{{ chaoshenxian }}</span></h3>
            <p>长期未结数</p>
          </li>
          <li>
            <h3><span class="counter">3</span></h3>
            <p>信访数</p>
          </li>
          <li>
            <h3><span class="counter">5</span></h3>
            <p>12345办件数</p>
          </li>
        </ul>
      </div>
      <div class="tbs">
        <div id="item7" class="covs"></div>
        <!-- <dl>
            <dd>
                <i></i>鉴定
            </dd>
            <dd>
                <i></i>中止
            </dd>
            <dd>
                <i></i>延期
            </dd>
            <dd>
                <i></i>重新组庭
            </dd>
            <dd>
                <i></i>变更请求
            </dd>
            <dd>
                <i></i>反请求
            </dd>
        </dl> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "ArbitrationSupervisor",
  components: {},
  props: {
    selectedyear: Number
  },
  data() {
    return {
      chao30: '',
      chaoshenxian: '',
      item7: [],
      isLoading: true, // 加载状态标志
    }
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    selectedyear(newYear, oldYear) {
      this.initialize();
    }
  },
  //方法集合
  methods: {
    init() {
      this.isLoading = false; // 数据加载完成
      this.$nextTick(() => {
        // 确保 DOM 更新完成后再初始化图表
        this.initChart();
      });


    },
    initialize() {
      this.datas();
    },
    datas() {
      this.isLoading = true; // 开始加载数据
      this.axios.get('/pingZhan/report/ArbitrationSupervisor', {
        params: {
          currentYear: this.selectedyear
        }
      }).then((response) => {
        //console.log(response);
        let data = response.data;
        if (data.code == 200) {
          this.chao30 = data.data.chao30.total;
          this.chaoshenxian = data.data.chaoshenxian.total;
          this.item7 = data.data.echarts;

          this.init();
        } else {
          console.log('数据获取失败');
        }
        this.isLoading = false; // 数据加载完成
      }).catch((error) => {
        console.error('发生错误:', error);
        this.isLoading = false; // 数据加载完成
      });
    },
    initChart() {
      let that = this;
      var chartDom = document.getElementById('item7');
      var myChart7 = echarts.init(chartDom);
      var option7;

      option7 = {
        color: ['#469beb', '#5ed86d', '#ffe400', '#37ded1', '#97e8f4', '#cd6ef4'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: '0',
          left: 'center',
          textStyle: {
            color: '#fff',
          }
        },
        series: [
          {
            name: '仲裁监督',
            type: 'pie',
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#081039',
              borderWidth: 2
            },
            label: {
              formatter: '{c}',
              show: true,
              textStyle: {
                color: '#fff',
                fontWeight: 'bold',
                fontSize: 14,
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold',
              }
            },
            data: that.item7
          }
        ]
      };

      option7 && myChart7.setOption(option7);
      myChart7.on('click', function(params, charts, event) {
        // alert(params.name);  alert(params.value);
        if(params.name=="鉴定"){
          that.$parent.openPopups('zcjd', "jianDing",null)
        }else if(params.name=="中止"){
          that.$parent.openPopups('zcjd', "zhongZhi",null)
        }else if(params.name=="延期"){
          that.$parent.openPopups('zcjd', "yanqi",null)
        }else if(params.name=="重新组庭"){
          that.$parent.openPopups('zcjd', "chongxinzuting",null)
        }else if(params.name=="变更请求"){
          that.$parent.openPopups('zcjd', "biangengqingqiu",null)
        }else if(params.name=="反请求"){
          that.$parent.openPopups('zcjd', "fanqingqiu",null)
        }
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.initialize();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  }
};
</script>

<style scoped>
@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* 向上移动 */
  }
}

.counter {
  animation: floatAnimation 2s ease-in-out infinite; /* 应用动画 */
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
