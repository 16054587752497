<template>
  <div>
    <div v-if="isLoading" class="loading-container">
      <loading></loading>
    </div>
    <div v-else>
      <div class="tit">
        <h2>办案秘书办案情况</h2>
      </div>
      <div class="uittab">
        <div class="tps">
          <h3>姓名</h3>
          <h3>承办<br/>
            总数</h3>
          <h3>已办结</h3>
          <h3>未办结</h3>
          <h3>收结比</h3>
          <h3>超期<br/>
            办结</h3>
          <h3>超期<br/>
            未办结</h3>
          <h3>被撤<br/>
            销案件</h3>
          <h3>不予<br/>
            执行案件</h3>
        </div>
        <dl>
          <template v-for="(item,index) in data">
            <dd>
              <h4><a style="cursor:pointer;" @click="$parent.openPopup('bamsname', item)">{{ item.bamsName }}</a></h4>
              <h4><a style="cursor:pointer;color: #67f5be;" @click="$parent.openPopup('bamsname', item)">{{
                  item.caseCount
                }}</a></h4>
              <h4><a style="cursor:pointer;color: #ff7a6b;" @click="$parent.openPopup('bamsybj', item)">{{
                  item.jieanCount
                }}</a></h4>
              <h4><a style="cursor:pointer;color: #83acff;"
                     @click="$parent.openPopup('bamswbj', item)">{{ item.caseCount - item.jieanCount }}</a></h4>
              <h4>{{ (item.jieanCount / item.caseCount * 100).toFixed(2) }}%</h4>
              <h4><a style="cursor:pointer;color: #67d5f5;"
                     @click="$parent.openPopup('bamscqbj', item)">{{ item.chaoqibanjie }}</a></h4>
              <h4><a style="cursor:pointer;color: #f56792;"
                     @click="$parent.openPopup('bamscqwbj', item)">{{ item.Chaoqiweibanjie }}</a></h4>
              <h4><a style="cursor:pointer;color: #cff567;"
                     @click="$parent.openPopup('bamsbcx', item)">{{ item.beichexiao }}</a></h4>
              <h4><a style="cursor:pointer;color: #cff567;"
                     @click="$parent.openPopup('bamsbyzx', item)">{{ item.buyuzhixing }}</a></h4>
            </dd>
          </template>
        </dl>
      </div>
    </div>
  </div>
</template>
<!--
/**
 * @Description
 * @className CaseClosureAssistant.vue
 * @author Zhu
 * @date 2023-10-07 10:49
 * @TODO 办案秘书结案
 * @param
 * @return
 */
-->
<script>
export default {
  name: "CaseClosureAssistant",
  components: {},
  props: {selectedyear: Number},
  data() {
    return {
      data: null,
      isLoading: true, // 加载状态标志
    }
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    selectedyear(newYear, oldYear) {
      this.init();
    }
  },
  //方法集合
  methods: {
    init() {
      this.isLoading = true; // 开始加载数据
      this.axios.get('/pingZhan/report/CaseClosureAssistant', {
        params: {
          currentYear: this.selectedyear
        }
      }).then((response) => {
        let data = response.data;
        // console.log(data);
        if (data.code == 200) {
          this.data = data.data;
        } else {
          console.log('数据获取失败');
        }
      }).catch((error) => {
        console.error('发生错误:', error);
      }).finally(() => {
        this.isLoading = false; // 数据加载完成
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  }
};
</script>

<style scoped>
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
