<template>
  <div>
    <div v-show="isLoading" class="loading-container">
      <loading></loading>
    </div>
    <div v-show="!isLoading">
      <ul>
        <li style="cursor:pointer;" @click="$parent.openPopup('bazltjja', null)">
          <h3 v-if="tiaojie"><span class="counter" style="font-size:22px;">{{ tiaojie }}</span></h3>
          <p>调解结案</p>
        </li>
        <li style="cursor:pointer;" @click="$parent.openPopup('bazlcaja', null)">
          <h3 v-if="chean"><span class="counter" style="font-size:22px;">{{ chean }}</span></h3>
          <p>撤案结案</p>
        </li>
        <li>
          <h3 v-if="hejielv"><span class="counter" style="font-size:22px;">{{ hejielv }}%</span></h3>
          <p>和解率</p>
        </li>
        <li style="cursor:pointer;" @click="$parent.openPopup('bazlbcx', null)">
          <h3 v-if="beichexiao !== null && beichexiao !== undefined"><span
              class="counter" style="font-size:22px;">{{ beichexiao }}</span></h3>
          <p>被撤销数</p>
        </li>
        <li style="cursor:pointer;" @click="$parent.openPopup('bazlbyzx', null)">
          <h3 v-if="buyuzhixing !== null && buyuzhixing !== undefined"><span
              class="counter" style="font-size:22px;">{{ buyuzhixing }}</span></h3>
          <p>不予执行数</p>
        </li>
      </ul>
      <div class="uittab">
        <div class="tps">
          <h3>年度</h3>
          <h3>调解结案</h3>
          <h3>撤案结案</h3>
          <h3>和解率</h3>
          <h3>被撤销数</h3>
          <h3>不予执行数</h3>
        </div>
        <dl>
          <template v-for="(item,index) in list">
            <dd>
              <h4>{{ item.year }}</h4>
              <h4><a style="cursor:pointer;color: #ffba6b"
                     @click="$parent.openPopup('bazltjja', item.year)">{{ item.tiaojie.tiaojie }}</a>
              </h4>
              <h4><a style="cursor:pointer;color: #67f5be"
                     @click="$parent.openPopup('bazlcaja', item.year)">{{ item.tiaojie.chean }}</a>
              </h4>
              <h4>{{ ((item.tiaojie.tiaojie + item.tiaojie.chean) / item.jieanshu.jieanshu * 100).toFixed(2) }}%</h4>
              <h4><a style="cursor:pointer;color: #83acff" @click="$parent.openPopup('bazlbcx', item.year)">{{
                  item.beichexiao.buyuzhixing
                }}</a></h4>
              <h4><a style="cursor:pointer;color: #83acff" @click="$parent.openPopup('bazlbyzx', item.year)">{{
                  item.buyuzhixing.buyuzhixing
                }}</a></h4>
            </dd>
          </template>

        </dl>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CaseQualityEvaluator",
  components: {},
  props: {selectedyear: Number},
  data() {
    return {
      tiaojie: '',
      chean: '',
      hejielv: '',
      buyuzhixing: '',
      beichexiao: '',
      list: [],
      isLoading: true, // 加载状态标志
    }
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    selectedyear(newYear, oldYear) {
      this.init();
    }
  },
  //方法集合
  methods: {
    init() {
      this.isLoading = true; // 开始加载数据
      this.axios.get('/pingZhan/report/CaseQualityEvaluator', {
        params: {
          currentYear: this.selectedyear
        }
      }).then((response) => {
        let data = response.data;
        if (data.code == 200) {
          //console.log(data);
          this.tiaojie = data.data.data.tiaojie;
          this.chean = data.data.data.chean;
          //和解率：((本年度调解结案数+撤案结案数）/本年度结案案件总数)*100%
          this.hejielv = (((this.tiaojie + this.chean) / data.data.jieanshu.jieanshu) * 100).toFixed(2);
          this.buyuzhixing = data.data.buyuzhixing.buyuzhixing;
          this.beichexiao = data.data.beichexiao.buyuzhixing;
          this.list = data.data.CaseQualityEvaluatorList;
        } else {
          console.log('数据获取失败');
        }
      }).catch((error) => {
        console.error('发生错误:', error);
      }).finally(() => {
        this.isLoading = false; // 数据加载完成
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.init()
  }
};
</script>

<style scoped>
@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* 向上移动 */
  }
}

.counter {
  animation: floatAnimation 2s ease-in-out infinite; /* 应用动画 */
}

.down-5-percent {
  transform: translateY(20%);
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
