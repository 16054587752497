<template>
  <div id="page2">
    <div class="mcpg mcpg1">
      <div class="mcbd">
        <div class="mklt">
          <left></left>
        </div>

        <div class="mkrt">
          <router-view :user="user"></router-view>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import left from "./components/left.vue"
import right from "./components/right.vue"
import OverdueCases from "./components/OverdueCases.vue"

export default {
  name: "zcy_handling",
  components: {
    left, right, OverdueCases
  },
  props: {
    user: Object
  },
  data() {
    return {
      id: null,
    }
  },
  methods: {},
  created() {
    this.id = this.$route.params.id;
  },

}
</script>


<style scoped>

</style>