<template>
  <div  class="item" v-if="user">
    <div class="he">
      <h3>联系信息</h3>
    </div>
    <ul class="ul1">
      <li>手机： <i>{{displayValue('telephone_num')}}</i></li>
      <li>电子邮箱： <i>{{displayValue('email_num')}}</i></li>
      <li>联系地址： <i>{{displayValue('contact_address')}}</i></li>
      <li>联系固定电话： <i>{{displayValue('contact_telephone')}}</i></li>
      <li>传真： <i>{{displayValue('fax_num')}}</i></li>

    </ul>
  </div>
</template>

<script>
export default {
  name: "relation",
  props:{
    user:Object
  },
  methods:{
    displayValue(field) {
      return this.user[field] ? this.user[field] : '暂无';
    },
  }
}
</script>

<style scoped>

</style>