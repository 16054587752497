<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>鉴定</h3>
      <div v-if="authenticateList && authenticateList.checkupFlow" class="r">
        <a @click="openxq('authenticatexq',bananId)">详情</a>
      </div>
    </div>
    <ul v-if="authenticateList && authenticateList.checkupFlow" class="ul1">
      <li class="bolder-and-larger-text" style="width: 100%;">
        状态：<i>{{ this.authenticateList.checkupFlow.proc_flag == '1' ? '已完成' : '未完成' }}</i></li>
      <li>鉴定申请人：<i>{{ jdsqr || '无' }}</i></li>
      <li>鉴定申请时间：<i>{{ DateFormat(this.authenticateList.checkupFlow.apply_date) }}</i></li>
      <li style="width: 100%;">当事人申请鉴定事项：<i>{{
          this.authenticateList.checkupFlow.dsr_jianding_content || '无'
        }}</i></li>
      <li>是否准许鉴定：<i>{{ isJianding || '无' }}</i></li>
      <li v-if="authenticateList.checkupFlow.is_jianding=='1'" style="width: 100%;">不予准许鉴定的理由：<i>{{
          this.authenticateList.checkupFlow.no_jianding_ask || '无'
        }}</i></li>
      <li>鉴定类型：<i>{{ jigouType }}</i></li>
      <li style="width: 100%;">鉴定事项：<i>{{ this.authenticateList.checkupFlow.jianding_content }}</i></li>
      <li>鉴定材料提交方：<i>{{ cailiaoTjf }}</i></li>
      <li>鉴定材料提交期限：<i>{{ DateFormat(this.authenticateList.checkupFlow.tj_date) }}</i></li>
      <li>鉴定费用预交方：<i>{{ fytjf }}</i></li>
      <li>决定委托鉴定机构：<i>{{ this.authenticateList.checkupFlow.jigou_name }}</i></li>

    </ul>
    <ul v-else class="ul1">
      <li>(暂无鉴定信息)</li>
    </ul>

    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialogOpen" class="new-dialog"
               custom-class="addMicroDialog"
               title="详情" width="95%" @close="closeWindow">
      <component v-bind:is="currentPopup" :bananId="bananId"
                 @fatherMethod="closeDialog"></component>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import ImageViewer from "@/components/ImageViewer.vue";
import authenticatexq from "@/views/case/details/components/authenticatexq.vue";

export default {
  name: "authenticate",
  components: {
    ImageViewer,
    authenticatexq

  },
  props: {
    bananId: String,
    caseNum: String,

  },

  data() {
    return {
      authenticateList: null,
      currentPopup: null,
      dialogOpen: false,
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.authenticate_init();
        }
      }
    },
    authenticateList(newVal) {
      if (newVal) {
        const hasData = newVal && newVal.checkupFlow;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    openxq(popupName, id) {
      this.dialogOpen = true
      this.currentPopup = popupName
      this.bananId = id
    },
    closeDialog(cmd) {
      this.closeWindow();
    },
    closeWindow() {
      this.currentPopup = null
      // this.bananId = null
      this.dialogOpen = false
    },
    authenticate_init() {
      this.axios.get('/api/handling/authenticate', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.authenticateList = data.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    DateFormat(value) {
      let date = moment(value).format('YYYY-MM-DD') || '无'
      return date;
    }
  },
  computed: {
    jdsqr() {
      // 使用 split 方法将字符串分割为数组
      let apply_type_array = this.authenticateList.checkupFlow.apply_type.split(",");

      // 使用 map 方法对数组中的每个元素进行处理
      let result_array = apply_type_array.map(item => {
        item = item.trim();

        // 如果数组元素为空，则返回 null
        if (item === '') {
          return null;
        }

        switch (item) {
          case '0':
            return '申请人';
          case '1':
            return '被申请人';
          case '2':
            return '仲裁庭';
          default:
            return '无';
        }
      }).filter(item => item !== null);

      // 如果结果数组中只有一个元素，直接返回该元素
      if (result_array.length === 1) {
        return result_array[0];
      }

      // 使用 join 方法将处理后的数组转换回逗号分隔的字符串
      return result_array.join(", ");
    },
    isJianding() {
      switch (this.authenticateList.checkupFlow.is_jianding) {
        case '0':
          return '是';
        case '1':
          return '否';
        default:
          return '无';
      }
    },
    jigouType() {
      switch (this.authenticateList.checkupFlow.jigou_type) {
        case '0':
          return '保险';
        case '1':
          return '建设工程造价';
        case '2':
          return '文检';
        case '3':
          return '房地产价格评估';
        case '4':
          return '财务审计';
        case '5':
          return '资产评估';
        case '6':
          return '其他';
        case '7':
          return '工程质量、房屋安全';
        case '8':
          return '物证';
        case '9':
          return '知识产权';
        default:
          return '无';
      }
    },
    cailiaoTjf() {
      // 使用 split 方法将字符串分割为数组
      let cailiao_tjf_array = this.authenticateList.checkupFlow.cailiao_tjf.split(",");

      // 使用 map 方法对数组中的每个元素进行处理
      let result_array = cailiao_tjf_array.map(item => {
        item = item.trim();

        // 如果数组元素为空，则返回 null
        if (item === '') {
          return null;
        }

        switch (item) {
          case '0':
            return '申请人';
          case '1':
            return '被申请人';
          default:
            return null; // 如果不匹配任何已知的值，返回 null
        }
      })
          // 使用 filter 方法移除数组中的 null 值
          .filter(item => item !== null);

      // 如果结果数组中只有一个元素，直接返回该元素
      if (result_array.length === 1) {
        return result_array[0];
      }

      // 使用 join 方法将处理后的数组转换回逗号分隔的字符串
      return result_array.join(", ");
    },
    fytjf() {
      // 使用 split 方法将字符串分割为数组
      let cailiao_tjf_array = this.authenticateList.checkupFlow.fy_tjf.split(",");

      // 使用 map 方法对数组中的每个元素进行处理
      let result_array = cailiao_tjf_array.map(item => {
        item = item.trim();

        // 如果数组元素为空，则返回 null
        if (item === '') {
          return null;
        }

        switch (item) {
          case '0':
            return '申请人';
          case '1':
            return '被申请人';
          default:
            return null; // 如果不匹配任何已知的值，返回 null
        }
      })
          // 使用 filter 方法移除数组中的 null 值
          .filter(item => item !== null);

      // 如果结果数组中只有一个元素，直接返回该元素
      if (result_array.length === 1) {
        return result_array[0];
      }

      // 使用 join 方法将处理后的数组转换回逗号分隔的字符串
      return result_array.join(", ");
    },
  }
}
</script>

<style scoped>
.bolder-and-larger-text {
  font-weight: bold;
  font-size: 1.2em;
}
</style>