<template>
  <div class="item">
    <div class="he">
      <h3>培训情况</h3>
    </div>
    <el-table
        :data="tableData"
        stripe
        style="width: 100%">
      <el-table-column
          prop="xh"
          label="序号"
          width="50">
      </el-table-column>
      <el-table-column
          prop="type"
          label="培训类型">
      </el-table-column>
      <el-table-column
          prop="time"
          label="培训时间">
      </el-table-column>
      <el-table-column
          prop="content"
          label="培训内容">
      </el-table-column>
      <el-table-column
          prop="assess"
          label="是否考核">
      </el-table-column>
      <el-table-column
          prop="grade"
          label="考核成绩">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "train",
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    initTrain() {
      this.axios.get('/pingZhan/inquire/zcyTrainById', {
        params: {
          id: this.$route.params.id
        }
      }).then(response => {
        const data = response.data;
        if (data.code == 200) {
          //data中取到的数据
          const originalData = data.data;
          //按照tableData格式转换为新数组，如果不存在则默认“暂无”
          const newData = originalData.map(item => {
            return {
              xh: item.xh || "暂无",
              type: item.px_type === 0 ? "场内培训" : item.px_type === 1 ? "场外培训" : "暂无",
              time: item.px_data || "暂无",
              content: item.px_content || "暂无",
              assess: item.if_check=== 0 ? "否" : item.if_check === 1 ? "是" : "暂无",
              grade: item.check_grade=== 0 ? "否" : item.check_grade === 1 ? "是" : "暂无",
            };
          });
          this.tableData = newData;
        } else {
          this.$message.error("获取培训情况失败")
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.initTrain();
  }
}
</script>

<style scoped>

</style>