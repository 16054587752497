<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>执行信息</h3>
    </div>
    <span v-if="tableData!=''">
    <el-table
        :cell-style="{
      background:'#fff',
      color: '#7883c5'}"
        :header-cell-style="{
      background:'#F5F7FA',
      color: '#3d4053'}"
        border
        :data="tableData"
        stripe
        style="width: 100%">
      <el-table-column
          align="center"
          label="序号"
          prop="xh"
          width="50">
      </el-table-column>
      <el-table-column
          align="center"
          label="执行案号"
          prop="ah">
      </el-table-column>
      <el-table-column
          align="center"
          label="执行时间"
          prop="executeTime">
      </el-table-column>
      <el-table-column
          align="center"
          label="提请法院"
          prop="sumbitFy">
      </el-table-column>
      <el-table-column
          align="center"
          label="办案秘书名称"
          prop="bananMishuName">
      </el-table-column>
      <el-table-column
          align="center"
          label="执行送达人名称"
          prop="exsdrName">
      </el-table-column>
      <!--      <el-table-column
                label="操作"
               >
              <template v-slot="{ row }">
                <el-link type="primary" @click="open('xqy',row.id)">查看</el-link>
              </template>
            </el-table-column>-->

    </el-table>
   </span>
    <span v-else>
      (暂无执行信息)
    </span>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialogwjscjl" class="new-dialog"
               custom-class="addMicroDialog"
               title="查看" width="80%" @close="closewjscjl">
      <component v-bind:is="currentPopup" :ywid="ywid"
                 @fatherMethod="closeDialog"></component>
    </el-dialog>
  </div>
</template>

<script>
//import xqy from "@/views/case/details/components/zxxqy.vue"

export default {
  name: "execute",
  props: {
    bananId: String,
    caseNum: String,
  },
  components: {
    //xqy

  },
  data() {
    return {
      ywid: '',
      currentPopup: null,
      dialogwjscjl: false,
      tableData: []
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.execute_init();
        }
      }
    },
    tableData(newVal) {
      if (newVal) {
        const hasData = newVal && newVal.length > 0;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    open(popupName, id) {
      this.dialogwjscjl = true
      this.currentPopup = popupName
      this.ywid = id
    },
    closeDialog(cmd) {
      this.closewjscjl();
    },
    closewjscjl() {
      this.currentPopup = null
      this.ywid = null
      this.dialogwjscjl = false
    },
    execute_init() {
      //alert(this.bananId);
      this.axios.get('/api/handling/execute', {
        //params: {id: 'b547efdc6fad412c87d6c1b2bab5d860'}
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          //data中取到的数据
          const originalData = Array.isArray(data.data) ? data.data : [];
          //按照tableData格式转换为新数组，如果不存在则默认“暂无”
          const newData = originalData.map(item => {
            return {
              id: item.id,
              xh: item.xh,
              ah: item.executeYear + '-' + item.executeNum || "暂无",
              executeTime: item.executeTime || "暂无",
              sumbitFy: item.sumbitFy || "暂无",
              bananMishuName: item.bananMishuName || "暂无",
              exsdrName: item.exsdrName || "暂无",
            };
          });
          this.tableData = newData;
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

</style>