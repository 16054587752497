<template>
  <div>
    <span style="font-size: 20px; font-weight: bold; ">{{ currentView }}</span>
    <div style="margin-top: 2%"></div>
    <!-- 表单部分 -->
    <el-form inline>
      <el-form-item label="年度：">
        <el-date-picker
            v-model="selectedYear"
            format="yyyy"
            placeholder="选择年份"
            type="year"
            value-format="yyyy">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="案号：">
        <el-input v-model="caseNumber"></el-input>
      </el-form-item>
      <el-form-item label="申请人：">
        <el-input v-model="sqrName"></el-input>
      </el-form-item>
      <el-form-item label="被申请人：">
        <el-input v-model="bsqrName"></el-input>
      </el-form-item>
      <el-form-item label="办结状态：">
        <el-select v-model="status">
          <el-option label="未办结" value="0"></el-option>
          <el-option label="已办结" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="其他状态：">
        <el-radio-group v-model="tslc_type">
          <el-radio label="0">超期办结</el-radio>
          <el-radio label="1">超期未办结</el-radio>
          <el-radio label="2">长期未结</el-radio>
          <el-radio label="3">鉴定</el-radio>
          <el-radio label="4">中止</el-radio>
          <el-radio label="5">延期</el-radio>
          <el-radio label="cxbcx">被撤销</el-radio>
          <el-radio label="cxbyzx">不予执行</el-radio>

        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="resetPageAndSearch">查询</el-button>
        <el-button type="warning" @click="clear">清除</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格部分 -->
    <el-table :data="tableData" border>
      <el-table-column prop="order" label="序号" width="50px" align="center"></el-table-column>
      <!--      <el-table-column prop="prefileNumber" label="预立案号" align="center"></el-table-column>-->
      <el-table-column align="center" label="案号" prop="caseNumber">
        <template v-slot="{ row }">
          <el-link type="primary" @click="handleClick(row)">{{ row.caseNumber }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="dateOfFiling" label="立案日期" align="center"></el-table-column>
      <el-table-column prop="applicant" label="申请人" align="center"></el-table-column>
      <el-table-column prop="respondent" label="被申请人" align="center"></el-table-column>
      <el-table-column prop="summary" label="案由" align="center"></el-table-column>
      <el-table-column prop="currentLink" label="当前环节" align="center"></el-table-column>
      <el-table-column prop="isClose" label="是否结案" align="center"></el-table-column>
      <el-table-column prop="closingTime" label="结案时间" align="center"></el-table-column>
    </el-table>
    <el-pagination
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        layout="total, prev, pager, next"
        @prev-click="prevPage"
        @next-click="nextPage"
        @current-change="PageChange"
    ></el-pagination>
  </div>
</template>


<script>
export default {
  name: "right",
  props: {
    user: Object
  },
  data() {
    return {
      selectedYear: new Date().getFullYear().toString(), // 初始化为当前年份
      caseNumber: '',
      sqrName: '',
      bsqrName: '',
      status: '',
      tslc_type: '',
      tableData: [],
      pagination: {
        type: '',
        total: 0,
        currentPage: 1,
        pageSize: 7
      },
      shouldResetPagination: true,//是否需要重置分页
      routeToStateMap: {
        '全部案件': {status: '', tslc_type: ''},
        '已办结': {status: '1', tslc_type: ''},
        '未办结': {status: '0', tslc_type: ''},
        '超期办结': {status: '', tslc_type: '0'},
        '超期未办结': {status: '', tslc_type: '1'},
        '长期未结': {status: '', tslc_type: '2'},
        '鉴定': {status: '', tslc_type: '3'},
        '中止': {status: '', tslc_type: '4'},
        '延期': {status: '', tslc_type: '5'},
        '被撤销': {status: '', tslc_type: 'cxbcx'},
        '不予执行': {status: '', tslc_type: 'cxbyzx'},

      },
    };
  },
  watch: {
    '$route'(newRoute, oldRoute) {
      console.log(newRoute)
      this.resetForm();
      this.applyRouteState(newRoute.name);

      // 根据currentView设置selectedYear
      if (newRoute.name === '全部案件') {
        this.selectedYear = new Date().getFullYear().toString();
      } else {
        this.selectedYear = '';
      }

      this.pagination.currentPage = 1;
      this.init();
    }
  },
  methods: {
    init() {
      const routeToActionMap = {
        '全部案件': this.AllCases,
        '已办结': this.CompletedCases,
        '未办结': this.UnsettledCases,
        '超期办结': this.DeadlineCases,
        '超期未办结': this.SettledCases,
        '长期未结': this.OutstandingCases,
        '鉴定': this.AuthenticateCases,
        '中止': this.SuspendCases,
        '延期': this.PostponeCases,
        '被撤销': this.CourtCasesBcx,
        '不予执行': this.CourtCasesByzx
      };
      this.pagination.type = 'init';
      const action = routeToActionMap[this.currentView];
      if (action) {
        this.pagination.type = 'init';
        action.call(this);
      }
    },
    search() {
      this.pagination.type = 'Query';
      const params = this.createParams({
        caseNumber: this.caseNumber,
        sqrName: this.sqrName,
        bsqrName: this.bsqrName,
        status: this.status,
        tslc_type: this.resolveTslcType()  // 假设这是处理tslc_type的方法
      });
      if (this.tslc_type === 'cxbcx') {
        const params = this.createParams({ask_type: 0}, false);
        this.bcxhbyzx(params);
      } else if (this.tslc_type === 'cxbyzx') {
        const params = this.createParams({ask_type: 1}, false);
        this.bcxhbyzx(params);
      } else {
        this.fetchCase(params);
      }

    },
    resetPageAndSearch() {
      this.pagination.currentPage = 1;
      this.search();
    },
    createParams(additionalParams, includeYear = true) {
      let params = {
        current: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        id: this.user.id,
        ...additionalParams // 合并额外的参数
      };

      if (includeYear) {
        params.year = this.selectedYear;
      }

      return params;
    },
    AllCases() {
      const params = this.createParams({});
      this.fetchCase(params)
    },
    CompletedCases() {
      const params = this.createParams({jiean: 1});
      this.fetchCase(params)
    },
    UnsettledCases() {
      const params = this.createParams({jiean: 0});
      this.fetchCase(params)
    },
    DeadlineCases() {
      const params = this.createParams({chaoqi: 1});
      this.fetchCase(params)
    },
    SettledCases() {
      const params = this.createParams({chaoqi: 0}, false);
      this.fetchCase(params)
    },
    OutstandingCases() {
      const params = this.createParams({changqi: 0}, false);
      this.fetchCase(params)
    },
    AuthenticateCases() {
      const params = this.createParams({tslc_type: 3}, false);
      this.fetchCase(params)
    },
    SuspendCases() {
      const params = this.createParams({tslc_type: 5}, false);
      this.fetchCase(params)
    },
    PostponeCases() {
      const params = this.createParams({tslc_type: 6}, false);
      this.fetchCase(params)
    },
    CourtCasesBcx() {
      const params = this.createParams({ask_type: 0}, false);
      this.bcxhbyzx(params)
    },
    CourtCasesByzx() {
      const params = this.createParams({ask_type: 1}, false);
      this.bcxhbyzx(params)
    },
    bcxhbyzx(params, ask_type) {
      this.axios.get('/pingZhan/inquire/bcxhbyzx', {
        params: params,
        ask_type: ask_type,
      }).then(response => {
        let data = response.data;
        //console.log(data.data);
        if (data.code == 200) {
          this.tableData = data.data.list.map((item, index) => ({
            order: (this.pagination.currentPage - 1) * this.pagination.pageSize + (index + 1),
            /*prefileNumber: item.yuCaseName,*/
            id: item.id,
            caseNumber: item.caseNum,
            dateOfFiling: this.formatDate(item.liAnDate),
            applicant: item.sqrStr,
            respondent: item.bsqrStr,
            summary: item.caseReasonName,
            currentLink: item.act != null ? item.act.taskName : '',
            isClose: this.jieanType(item.jieanType),
            closingTime: item.jieanDate != null ? this.formatDate(item.jieanDate) : ''
          }));
          this.pagination.total = data.data.total
        } else {
          this.showErrorMessage('数据加载失败，请重试');
        }
      }).catch(error => {
        console.log(error)
        this.showErrorMessage('请求失败，请检查');
      })
    },
    fetchCase(params) {
      this.axios.get('/pingZhan/inquire/zcyCaseById', {
        params: params
      }).then(response => {
        let data = response.data;
        //console.log(data.data);
        if (data.code == 200) {
          this.tableData = data.data.list.map((item, index) => ({
            order: (this.pagination.currentPage - 1) * this.pagination.pageSize + (index + 1),
            /*prefileNumber: item.yuCaseName,*/
            id: item.id,
            caseNumber: item.caseNum,
            dateOfFiling: this.formatDate(item.liAnDate),
            applicant: item.sqrStr,
            respondent: item.bsqrStr,
            summary: item.caseReasonName,
            currentLink: item.act != null ? item.act.taskName : '',
            isClose: this.jieanType(item.jieanType),
            closingTime: item.jieanDate != null ? this.formatDate(item.jieanDate) : ''
          }));
          console.log(this.tableData);
          this.pagination.total = data.data.total
        } else {
          this.showErrorMessage('数据加载失败，请重试');
        }
      }).catch(error => {
        console.log(error)
        this.showErrorMessage('请求失败，请检查');
      })
    },
    fetchPageDate() {
      if (this.pagination.type === 'init') {
        this.init();
      } else {
        this.search();
      }
    },
    prevPage() {
      if (this.pagination.currentPage > 1) {
        this.pagination.currentPage--;
        this.fetchPageDate();
      }
    },
    nextPage() {
      if (this.pagination.currentPage < Math.ceil(this.pagination.total / this.pagination.pageSize)) {
        this.pagination.currentPage++;
        this.fetchPageDate();
      }
    },
    PageChange(val) {
      this.pagination.currentPage = val;
      this.fetchPageDate();
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    jieanType(val) {
      if (val == 0) {
        return "裁决结案";
      } else if (val == 1) {
        return "调解结案";
      } else if (val == 2) {
        return "撤案结案";
      } else if (val == 3) {
        return "未结案";
      } else if (val == 4) {
        return "管辖异议成立";
      } else {
        return "未知";
      }
    },
    clear() {
      this.init();
      this.caseNumber = '';
      this.sqrName = '';
      this.bsqrName = '';
      this.status = '';
      this.tslc_type = '';
    },
    resolveTslcType() {
      // 根据 this.tslc_type 的值返回相应的数据
      if (this.tslc_type === '3') {
        return 3;
      } else if (this.tslc_type === '4') {
        return 5;
      } else if (this.tslc_type === '5') {
        return 6;
      }
    },
    showErrorMessage(message) {
      this.$message.error(message);
    },
    resetForm() {
      this.caseNumber = '';
      this.sqrName = '';
      this.bsqrName = '';
    },
    applyRouteState(routeName) {
      const state = this.routeToStateMap[routeName];
      if (state) {
        this.status = state.status;
        this.tslc_type = state.tslc_type;
      }
    },
    handleClick(row) {
      const baseUrl = window.location.origin; // 获取应用的基础 URL
      const url = `${baseUrl}/handling/${row.id}`; // 构建完整 URL
      //console.log(url)
      window.open(url, '_blank'); // 在新标签页打开 URL
    }
  },
  computed: {
    currentView() {
      // 返回当前路由的名称，作为视图标题
      return this.$route.name
    }
  },
  mounted() {
    if (this.user) {
      this.init();
    }
  }
}
</script>


<style scoped>
.el-form-item {
  margin-right: 20px; /* 根据需要调整间距 */
}
</style>
