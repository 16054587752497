<template>
  <div class="item" v-if="user">
    <div class="he">
      <h3>相关从业信息</h3>
    </div>
    <ul class="ul1">
      <li>从事仲裁工作年限：
        <i v-if="user.zc_enddata && user.zc_startdata">
          {{ new Date(user.zc_enddata).getFullYear() - new Date(user.zc_startdata).getFullYear() }}年
        </i>
        <i v-else>暂无</i>
      </li>

      <li>从事律师工作年限：
        <i v-if="user.ls_enddata && user.ls_startdata">
          {{ new Date(user.ls_enddata).getFullYear() - new Date(user.ls_startdata).getFullYear() }}年
        </i>
        <i v-else>暂无</i>
      </li>
      <li>曾任法官年限：
        <i v-if="user.fg_enddata && user.fg_startdata">
          {{ new Date(user.fg_enddata).getFullYear() - new Date(user.fg_startdata).getFullYear() }}年
        </i>
        <i v-else>暂无</i>
      </li>
      <li>从事法律教学研究年限：
        <i v-if="user.fl_enddata && user.fl_startdata">
          {{ new Date(user.fl_enddata).getFullYear() - new Date(user.fl_startdata).getFullYear() }}年
        </i>
        <i v-else>暂无</i>
      </li>
      <li>从事本职工作年限：
        <i v-if="user.ownwork_enddata && user.ownwork_startdata">
          {{ new Date(user.ownwork_enddata).getFullYear() - new Date(user.ownwork_startdata).getFullYear() }}年
        </i>
        <i v-else>暂无</i>
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  name: "business",
  props: {
    user: Object
  }
}
</script>

<style scoped>

</style>