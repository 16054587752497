import CryptoJS from 'crypto-js';

const encryptionKey = 'somy';

// 加密
function encrypt(value) {
    const ciphertext = CryptoJS.AES.encrypt(value, encryptionKey)
        .toString()
        .replace(/\//g, '_'); // Replace "/" with "_"
    return ciphertext;
}

// 解密
function decrypt(value) {
    // Replace "_" with "/"
    const modifiedValue = value.replace(/_/g, '/');
    const bytes = CryptoJS.AES.decrypt(modifiedValue, encryptionKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}

export {encrypt, decrypt};
