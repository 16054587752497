<template>
  <div id="page2">
    <div class="mcpg mcpg1">
      <div class="mcbd">
        <!-- 时间选择和查询按钮 -->
        <div class="search-area">
          <span>结案时间：</span>
          <el-date-picker
              v-model="startDate"
              type="date"
              placeholder="开始时间"
              value-format="yyyy-MM-dd">
          </el-date-picker>

          <el-date-picker
              v-model="endDate"
              type="date"
              placeholder="结束时间"
              value-format="yyyy-MM-dd">
          </el-date-picker>
          <span>得分筛选：</span>
          <el-select v-model="score">
            <el-option label="全部" value="1"></el-option>
            <el-option label="满分" value="2"></el-option>
            <el-option label="非满分" value="3"></el-option>
          </el-select>


          <el-button type="primary" @click="search">查询</el-button>
        </div>
        <el-table v-show="isTableVisible" v-loading="loading" :data="tableData" border height="60.5vh"
                  style="width: 100%;">
          <el-table-column type="index" label="序号" align="center"></el-table-column>
          <el-table-column align="center" label="案号" prop="caseNum">
            <template v-slot="{ row }">
              <el-link type="primary" @click="openPopupDialog('oldlhkh',row.caseInfoId)">{{ row.caseNum }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="zcyName" label="仲裁员" align="center"></el-table-column>
          <el-table-column prop="sqrNames" label="申请人" align="center"></el-table-column>
          <el-table-column prop="bsqrNames" label="被申请人" align="center"></el-table-column>
          <el-table-column prop="mishuName" label="办案秘书" align="center"></el-table-column>
          <el-table-column prop="jieAnTypeName" label="结案方式" align="center"></el-table-column>
          <!--          <el-table-column prop="pointDeduction" label="扣分情况" align="center">
                      <el-table-column prop="pro1" label="拒绝指定" align="center"></el-table-column>
                      <el-table-column prop="pro2" label="庭前合议" align="center"></el-table-column>
                      <el-table-column prop="pro3" label="出庭安排" align="center"></el-table-column>
                      <el-table-column prop="pro4" label="按时开庭" align="center"></el-table-column>
                      <el-table-column prop="pro5" label="开庭纪律" align="center"></el-table-column>
                      <el-table-column prop="pro6" label="庭后合议" align="center"></el-table-column>
                      <el-table-column prop="pro7" label="庭限情况" align="center"></el-table-column>
                    </el-table-column>-->
          <el-table-column prop="sumPro" label="合计" align="center"></el-table-column>
          <el-table-column prop="particularly" label="特别项" align="center">
            <el-table-column prop="pro14" label="承办大案	" align="center"></el-table-column>
            <el-table-column prop="pro15" label="提前结案" align="center"></el-table-column>
          </el-table-column>
        </el-table>
        <el-table v-loading="loading" :data="tableDataSecond" border style="width: 100%;" height="74.5vh"
        >
          <el-table-column type="index" label="序号" align="center"></el-table-column>
          <el-table-column align="center" label="案号" prop="caseNum">
            <template v-slot="{ row }">
              <el-link type="primary" @click="openPopupDialog('lhkh',row.caseInfoId)">{{ row.caseNum }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="zcyName" label="仲裁员" align="center"></el-table-column>
          <el-table-column prop="sqrNames" label="申请人" align="center"></el-table-column>
          <el-table-column prop="bsqrNames" label="被申请人" align="center"></el-table-column>
          <el-table-column prop="mishuName" label="办案秘书" align="center"></el-table-column>
          <el-table-column prop="jieAnTypeName" label="结案方式" align="center"></el-table-column>
          <!--          <el-table-column prop="pointDeduction" label="加分情况" align="center">
                      <el-table-column prop="pro1" label="阅卷排庭" align="center"></el-table-column>
                      <el-table-column prop="pro2" label="庭前合议" align="center"></el-table-column>
                      <el-table-column prop="pro3" label="签署声明" align="center"></el-table-column>
                      <el-table-column prop="pro4" label="按时到庭" align="center"></el-table-column>
                      <el-table-column prop="pro5" label="庭审纪律" align="center"></el-table-column>
                      <el-table-column prop="pro52" label="平等待人" align="center"></el-table-column>
                      <el-table-column prop="pro53" label="披露意见" align="center"></el-table-column>
                      <el-table-column prop="pro54" label="庭审水平" align="center"></el-table-column>
                      <el-table-column prop="pro6" label="保密" align="center"></el-table-column>
                      <el-table-column prop="pro7" label="庭后合议" align="center"></el-table-column>
                      <el-table-column prop="pro8" label="限期裁决" align="center"></el-table-column>
                      <el-table-column prop="pro9" label="促使调解" align="center"></el-table-column>
                      <el-table-column prop="pro10" label="形式无误" align="center"></el-table-column>
                      <el-table-column prop="pro11" label="证据无误" align="center"></el-table-column>
                      <el-table-column prop="pro12" label="法律无误" align="center"></el-table-column>
                      <el-table-column prop="pro13" label="公平合理" align="center"></el-table-column>
                    </el-table-column>-->
          <el-table-column prop="sumPro" label="合计" align="center"></el-table-column>
          <el-table-column prop="particularly" label="特别项" align="center">
            <el-table-column prop="pro14" label="承办大案	" align="center"></el-table-column>
            <el-table-column prop="pro15" label="提前结案" align="center"></el-table-column>
          </el-table-column>

        </el-table>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" title="量化考核详情" width="80%"
               class="new-dialog" custom-class="addMicroDialog" @close="closePopup">
      <component v-bind:is="currentPopup" :caseInfoId="caseInfoId" @fatherMethod="closeDialog"></component>
    </el-dialog>
  </div>
</template>

<script>

import lhkh from "@/views/zcy/zcy_point/zcy_assess.vue"
import oldlhkh from "@/views/zcy/zcy_point/zcy_assessOld.vue"

export default {
  name: "zcy_point",
  props: {
    user: Object
  },
  components: {
    lhkh,
    oldlhkh
  },
  data() {
    return {
      id: null,
      startDate: '', // 存储开始日期
      endDate: '',   // 存储结束日期
      score: '', //得分筛选
      StatusOptions: [
        {label: "全部", value: "1"},
      ], //得分筛选默认
      tableData: [],// 表格数据
      tableDataSecond: [],// 表格数据
      loading: false,
      isTableVisible: true,// 默认为不显示表格
      currentPopup: null,
      caseInfoId: '',
      dialogVisible: false
    }
  },
  methods: {
    closeDialog(cmd) {
      this.closePopup();
    },
    openPopupDialog(popupName, caseInfoId) {
      this.dialogVisible = true
      this.currentPopup = popupName
      this.caseInfoId = caseInfoId

    },
    closePopup() {
      this.currentPopup = null
      this.caseInfoId = null
      this.dialogVisible = false
    },
    search() {
      if (this.startDate.length == 0) {
        this.remind("请输入开始时间");
        return;
      }
      if (this.endDate.length == 0) {
        this.remind("请输入结束时间");
        return;
      }
      this.startLoading();
      //this.loading = true;
      this.axios.get('/pingZhan/inquire/zcyDeductionById', {
        params: {
          startTime: this.startDate,
          endTime: this.endDate,
          score: this.score,
          id: this.id,
          number: 'first'
        }
      }).then(response => {

        let data = response.data;
        if (data.code == 200) {
          if (data.data.length == 0) {
            this.isTableVisible = false;
          } else {
            this.isTableVisible = true;
          }
          this.tableData = data.data.map(item =>
              ({
                caseInfoId: item.caseInfoId,
                caseNum: item.caseNum,
                zcyName: item.zcyName,
                sqrNames: item.sqrNames,
                bsqrNames: item.bsqrNames,
                mishuName: item.mishuName,
                jieAnTypeName: item.jieAnTypeName,
                pro1: item.pro1,
                pro2: item.pro2,
                pro3: item.pro3,
                pro4: item.pro4,
                pro5: item.pro5,
                pro6: item.pro6,
                pro7: item.pro7,
                pro11: item.pro11,
                sumPro: item.sumPro,
                pro14: item.pro14 == null ? "0" : item.pro14,
                pro15: item.pro15 == null ? "0" : item.pro15
              })
          );
        }
      }).catch(error => {

      })
      this.searchSecond();
    },
    searchSecond() {
      if (this.startDate.length == 0) {
        this.remind("请输入开始时间");
        return;
      }
      if (this.endDate.length == 0) {
        this.remind("请输入结束时间");
        return;
      }
      this.axios.get('/pingZhan/inquire/zcyDeductionById', {
        params: {
          startTime: this.startDate,
          endTime: this.endDate,
          score: this.score,
          id: this.id,
          number: 'second'
        }
      }).then(response => {
        //this.loading = false;
        this.Loading.close();
        let data = response.data;
        if (data.code == 200) {
          this.tableDataSecond = data.data.map(item =>
              ({
                caseInfoId: item.caseInfoId,
                caseNum: item.caseNum,
                zcyName: item.zcyName,
                sqrNames: item.sqrNames,
                bsqrNames: item.bsqrNames,
                mishuName: item.mishuName,
                jieAnTypeName: item.jieAnTypeName,
                pro1: item.pro1,
                pro2: item.pro2,
                pro3: item.pro3,
                pro4: item.pro4,
                pro5: item.pro5,
                pro6: item.pro6,
                pro7: item.pro7,
                pro8: item.pro8,
                pro9: item.pro9,
                pro10: item.pro10,
                pro11: item.pro11,
                pro12: item.pro12,
                pro13: item.pro13,
                pro52: item.pro52,
                pro53: item.pro53,
                pro54: item.pro54,
                sumPro: item.sumPro,
                pro14: item.pro14 == null ? "0" : item.pro14,
                pro15: item.pro15 == null ? "0" : item.pro15
              })
          );
        }
      }).catch(error => {

      })
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    remind(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'warning'
      });
    },
    startLoading() {
      this.Loading = this.$loading({
        lock: false,
        text: '正在加载中...',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.6)'//遮罩背景色

      })
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.score = this.StatusOptions[0].value;//得分筛选默认
  },
}

</script>

<style>
.new-dialog .el-dialog__header {
  background: #409EFF;
}

.el-dialog__headerbtn .el-dialog__close {
  color: #303133;
}

/*.addMicroDialog {
  height: 700px !important;
  overflow-y: scroll !important;
}*/
/*.el-dialog__wrapper {
  overflow: hidden;
}*/
</style>