<template>
  <div>
    <span style="font-size: 20px; font-weight: bold; ">{{ currentView }}</span>
    <div style="margin-top: 2%"></div>
    <!-- 表单部分 -->
    <el-form inline>
      <el-form-item label="案号：">
        <el-input v-model="caseNumber"></el-input>
      </el-form-item>
      <el-form-item label="申请人：">
        <el-input v-model="sqrName"></el-input>
      </el-form-item>
      <el-form-item label="被申请人：">
        <el-input v-model="bsqrName"></el-input>
      </el-form-item>
      <el-form-item label="案由：">
        <el-input v-model="summary"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button type="warning" @click="clear">清除</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格部分 -->
    <el-table :data="tableData" border>
      <el-table-column align="center" label="序号" prop="order" width="50px"></el-table-column>
      <el-table-column align="center" label="案号" prop="caseNumber"></el-table-column>
      <el-table-column align="center" label="立案时间" prop="filing_time"></el-table-column>
      <el-table-column align="center" label="申请人" prop="applicant"></el-table-column>
      <el-table-column align="center" label="被申请人" prop="respondent"></el-table-column>
      <el-table-column align="center" label="案由" prop="summary"></el-table-column>
      <el-table-column align="center" label="争议金额" prop="money"></el-table-column>
      <el-table-column align="center" label="办案秘书" prop="secretary"></el-table-column>
      <el-table-column align="center" label="报酬扣除" prop="deduction"></el-table-column>
      <!--      <el-table-column prop="closingTime" label="操作" align="center"></el-table-column>-->
    </el-table>
    <el-pagination
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        layout="total, prev, pager, next"
        @prev-click="prevPage"
        @next-click="nextPage"
        @current-change="PageChange"
    ></el-pagination>
  </div>
</template>


<script>
export default {
  name: "OverdueCases",
  props: {
    user: Object
  },
  data() {
    return {
      caseNumber: '',
      sqrName: '',
      bsqrName: '',
      summary: '',
      tableData: [],
      pagination: {
        type: '',
        total: 0,
        currentPage: 1,
        pageSize: 7
      },
      shouldResetPagination: true,//是否需要重置分页
    };
  },
  methods: {
    init() {
      const routeToActionMap = {
        '逾期归档案件': this.lateFiling
      };
      this.pagination.type = 'init';
      const action = routeToActionMap[this.currentView];
      if (action) {
        this.pagination.type = 'init';
        action.call(this);
      }
    },
    search(resetPagination = true) {
      if (resetPagination) {
        this.pagination.currentPage = 1;
      }
      this.pagination.type = 'Query';
      const params = this.createParams({
        caseNumber: this.caseNumber,
        sqrName: this.sqrName,
        bsqrName: this.bsqrName,
        summary: this.summary,
      });
      this.fetchCase(params);
    },
    createParams(additionalParams) {
      return {
        current: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        id: this.user.id,
        ...additionalParams
      };
    },
    lateFiling() {
      const params = this.createParams({});
      this.fetchCase(params)
    },
    fetchCase(params) {
      this.axios.get('/pingZhan/inquire/zcyLateFiling', {
        params: params
      }).then(response => {
        let data = response.data;
        if (data.code == 200) {
          this.tableData = data.data.list.map((item, index) => ({
            order: (this.pagination.currentPage - 1) * this.pagination.pageSize + (index + 1),
            caseNumber: item.caseYearNum + '-' + item.caseSerialNumber,
            filing_time: this.formatDate(item.liAnDate),
            applicant: item.sqrStr,
            respondent: item.bsqrStr,
            summary: item.caseReason,
            money: item.jfMoney,
            secretary: item.songjuanUser.name,
            deduction: item.kcRewardFlag === undefined || item.kcRewardFlag === '0' ? '不扣除' : item.kcRewardFlag === '1' ? '扣除' : ''
          }));
          this.pagination.total = data.data.total
        } else {
          this.showErrorMessage('数据加载失败，请重试');
        }
      }).catch(error => {
        console.log(error)
        this.showErrorMessage('请求失败，请检查');
      })
    },
    prevPage(val) {
      if (this.pagination.currentPage > 1) {
        this.pagination.currentPage--;
        if (this.pagination.type === 'init') {
          this.init(); // 如果是初始化，调用 init 方法
        } else {
          this.search(false);
        }
      }
    },
    nextPage(val) {
      if (this.pagination.currentPage < Math.ceil(this.pagination.total / this.pagination.pageSize)) {
        this.pagination.currentPage++;
        if (this.pagination.type === 'init') {
          this.init(); // 如果是初始化，调用 init 方法
        } else {
          this.search(false);
        }
      }
    },
    PageChange(val) {
      if (val !== this.pagination.currentPage) {
        this.pagination.currentPage = val;
        if (this.pagination.type === 'init') {
          this.init(); // 如果是初始化，调用 init 方法
        } else {
          this.search(false);
        }
      }
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      const hours = ('0' + d.getHours()).slice(-2);
      const minutes = ('0' + d.getMinutes()).slice(-2);
      const seconds = ('0' + d.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    clear() {
      this.init();
      this.caseNumber = '';
      this.sqrName = '';
      this.bsqrName = '';
      this.status = '';
      this.tslc_type = '';
    },
    showErrorMessage(message) {
      this.$message.error(message);
    },
    resetForm() {
      this.caseNumber = '';
      this.sqrName = '';
      this.bsqrName = '';
      this.summary = '';
    },

  },
  computed: {
    currentView() {
      // 返回当前路由的名称，作为视图标题
      return this.$route.name
    }
  },
  mounted() {
    if (this.user && this.user.id) {
      this.resetForm();
      this.pagination.currentPage = 1;
      this.init();
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-right: 20px; /* 根据需要调整间距 */
}
</style>