<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>延期</h3>
    </div>
    <ul v-if="postponeList && postponeList.extend.length>0" class="ul1">
      <template v-for="(item,index) in postponeList.extend">
        <li class="bolder-and-larger-text" style="width: 100%;">
          状态：<i>{{ item.proc_flag == '1' ? '已完成' : '未完成' }}</i></li>
        <li>原审限到期时间：<i>{{ formatTime(item.old_date) || '无' }}</i></li>
        <li>延期天数：<i>{{ item.reason_day || '无' }}</i></li>
        <li style="width: 100%;">延期理由：<i>{{ item.Reason || '无' }}</i></li>
        <li>处长是否不予执行：<i>{{ czDecision(item.cz_decision) || '无' }}</i></li>
        <li>延长审限申请表：<i>{{ item.wod != '' ? '已上传' : '暂无附件' }}</i></li>
        <li>通知书：<i>{{ item.notice != '' ? '已上传' : '暂无附件' }}</i></li>
      </template>
    </ul>
    <ul v-else class="ul1">
      <li>(暂无延期信息)</li>
    </ul>
  </div>
</template>


<script>
import moment from "moment/moment";

export default {
  name: "postpone",
  props: {
    bananId: String,
    caseNum: String,
  },
  data() {
    return {
      postponeList: null,
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.postpone_init();
        }
      }
    },
    postponeList(newVal) {
      if (newVal) {
        const hasData = newVal.extend && newVal.extend.length > 0;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    postpone_init() {
      this.axios.get('/api/handling/postpone', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.postponeList = data.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    formatTime(date) {
      return date ? moment(date).format('YYYY-MM-DD') : '无';
    },
    czDecision(type) {
      switch (type) {
        case '0':
          return '否';
        case '1':
          return '是';
        default:
          return '暂无决定';
      }
    },
  }
}
</script>

<style scoped>
.bolder-and-larger-text {
  font-weight: bold;
  font-size: 1.2em;
}
</style>