<template>
  <div id="page2">
    <div style="width:100%">
      <div style="width:80%; margin-left :auto ; margin-right:auto;">
        <h1 style="text-align: center;">济南仲裁委员会仲裁员个案量化考核表</h1>
        <table v-if="khjbxxData" id="contentTable1" class="table1 table-striped1 table-bordered1 table-condensed1"
               style="table-layout: fixed;">
          <tr>
            <td colspan="12">
              <table v-if="ajxxData" class="table1 table-striped1 table-bordered1 table-condensed1"
                     style="table-layout: fixed;">
                <tr>
                  <td class="tdbj">案号</td>
                  <td colspan="2">{{ ajxxData.anhao }}</td>
                  <td class="tdbj">案由</td>
                  <td colspan="2">{{ ajxxData.caseReasonName }}</td>
                  <td class="tdbj">结案方式</td>
                  <td colspan="2">{{ ajxxData.jieanType }}</td>
                  <td class="tdbj">办案秘书签字</td>
                  <td colspan="2">{{ ajxxData.su_bnms }}</td>
                </tr>
                <tr>
                  <td class="tdbj"> 首席仲裁员</td>
                  <td colspan="2">{{ ztxxData.shouxi }}</td>
                  <td class="tdbj">仲裁员(申)</td>
                  <td colspan="2"> {{ ztxxData.zcyuan1 }}</td>
                  <td class="tdbj">仲裁员(被)</td>
                  <td colspan="5">{{ ztxxData.zcyuan2 }}</td>
                </tr>
                <tr>
                  <td class="tdbj">申请人</td>
                  <td colspan="5">{{ ajxxData.sqrStr }}</td>
                  <td class="tdbj">被申请人</td>
                  <td colspan="5">{{ ajxxData.bsqrStr }}</td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td class="tdbj" colspan="12" style="text-align: center;">考核基本信息</td>
          </tr>
          <tr>
            <td align="center" class="tdbj" rowspan="2">序号</td>
            <td class="tdbj" colspan="4" rowspan="2">考核项目</td>
            <td class="tdbj" rowspan="2" style="text-align: center;">扣分分值</td>
            <td class="tdbj" colspan="6" style="text-align: center;">扣分情况</td>
          </tr>
          <tr>
            <td class="tdbj" colspan="2" style="text-align: center;">首席</td>
            <td class="tdbj" colspan="2" style="text-align: center;">仲裁员(申)</td>
            <td class="tdbj" colspan="2" style="text-align: center;">仲裁员(被)</td>
          </tr>
          <tr>
            <td align="center" class="tdbj">一</td>
            <td colspan="4">接受当事人选定或者指定担任仲裁员情况(无正当理由拒绝接受的)</td>
            <td>10分</td>
            <td colspan="2">
              {{ khjbxxData.project1 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy1 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt1 }}
            </td>
          </tr>
          <tr>
            <td align="center" class="tdbj" rowspan="2">二</td>
            <td colspan="4" rowspan="2">庭前合议(庭前阅卷)情况(接到组庭通知后5日内阅卷、组织庭前合议)</td>
            <td>20分(首席)</td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.project2 }}
            </td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.zcy2 }}
            </td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.qt2 }}
            </td>
          </tr>
          <tr>
            <td align="center">10分</td>
          </tr>
          <tr>
            <td align="center" class="tdbj" rowspan="2">三</td>
            <td colspan="4" rowspan="2">及时安排出庭情况(庭前合议是安排首次开庭时间,开庭时间安排在在组庭通知后15日内)
            </td>
            <td>20分(首席)</td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.project3 }}
            </td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.zcy3 }}
            </td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.qt3 }}
            </td>
          </tr>
          <tr>
            <td align="center">10分</td>
          </tr>
          <tr>
            <td align="center" class="tdbj">四</td>
            <td colspan="4">确定开庭时间后，无法按预定时间开庭的情况</td>
            <td align="center">10分</td>
            <td colspan="2">
              {{ khjbxxData.project4 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy4 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt4 }}
            </td>
          </tr>
          <tr>
            <td align="center" class="tdbj" rowspan="4">五</td>
            <td colspan="4">1、有开庭迟到、早退现象的</td>
            <td>10分</td>
            <td colspan="2">
              {{ khjbxxData.project5 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy5 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt5 }}
            </td>
          </tr>
          <tr>
            <td colspan="4">2、有酒后开庭现象的</td>
            <td>10分</td>
            <td colspan="2">
              {{ khjbxxData.project52 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy52 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt52 }}
            </td>
          </tr>
          <tr>
            <td colspan="4">3、开庭时不遵守仲裁庭纪律，随意离席走动、打电话、吸烟或进行与庭审无关的活动的</td>
            <td>10分</td>
            <td colspan="2">
              {{ khjbxxData.project53 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy53 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt53 }}
            </td>
          </tr>
          <tr>
            <td colspan="4">4、开庭时与当事人辩论，进行提示性询问，不能平等对待双方当事人的</td>
            <td>10分</td>
            <td colspan="2">
              {{ khjbxxData.project54 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy54 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt54 }}
            </td>
          </tr>

          <tr>
            <td align="center" class="tdbj" rowspan="2"> 六</td>
            <td colspan="4" rowspan="2">庭后合议情况(最后一次开庭后5日内组织评议)</td>
            <td>20分(首席)</td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.project6 }}
            </td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.zcy6 }}
            </td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.qt6 }}
            </td>
          </tr>
          <tr>
            <td align="center">10分</td>
          </tr>

          <tr>
            <td align="center" class="tdbj" rowspan="2">七</td>
            <td colspan="4" rowspan="2">庭限情况(接到组庭通知后2个月内完成案件的审理工作)</td>
            <td align="center">20分(首席)</td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.project7 }}
            </td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.zcy7 }}
            </td>
            <td colspan="2" rowspan="2">
              {{ khjbxxData.qt7 }}
            </td>
          </tr>
          <tr>
            <td align="center">10分</td>
          </tr>
          <tr>
            <td align="center" class="tdbj">合计</td>
            <td colspan="4"></td>
            <td align="center"></td>
            <td colspan="2">
              {{ khjbxxData.hjproject }}
            </td>
            <td colspan="2">
              {{ khjbxxData.hjzc1 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.hjqt }}
            </td>
          </tr>

          <tr>


            <td colspan="4"></td>
            <td colspan="4">
              <div class="control-group">
                <label class="control-label tdbj">量化考核表：</label>
                <div class="controls">

                </div>
              </div>
            </td>
            <td colspan="4"></td>
          </tr>

        </table>
      </div>
    </div>
  </div>


</template>

<script>


export default {
  name: "zcy_assessOld.vue",
  props: {
    caseInfoId: '',
  },
  components: {},

  data() {
    return {
      ajxxData: null,// 存放获取到的数据
      ztxxData: null,
      khjbxxData: null,
      tzjfxxData: null
    }
  },
  //方法集合
  methods: {
    init() {
      this.startLoading();
      //alert("=="+this.caseInfoId);
      this.axios.get('/pingZhan/assess/getajcx', {
        params: {
          caseInfoId: this.caseInfoId
        }
      }).then(response => {
        this.Loading.close();
        const data = response.data;
        if (data.code == 200) {
          this.ajxxData = data.data.ajxx;
          this.ztxxData = data.data.ztxx;
          this.khjbxxData = data.data.khjbxx;
          this.tzjfxxData = data.data.tzjfxx;
        } else {
          this.remind(data.message);
          this.$emit("fatherMethod", "");
        }
      }).catch(error => {
        this.remind('发生错误:', error);
        this.Loading.close();
        this.$emit("fatherMethod", "");
        console.error('发生错误:', error);
      })

    },


    remind(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'warning'
      });
    },
    startLoading() {
      this.Loading = this.$loading({
        lock: false,
        text: '正在加载中...',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.6)'//遮罩背景色

      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

    this.init()
  }
}

</script>

<style scoped>
h1 {
  color: #317eac;
  font-size: 2em;
  margin-block-end: 0.67em;
  font-weight: bold;
}

.tdbj {
  font-weight: bold;
  background: #F5F7FA;
  /*font-style: italic;*/
}

.table1 th, .table td {
  line-height: 40px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table1 {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  margin-bottom: 8px;
  background-color: #fdfdfd;
}

.table1 thead {
  color: #878d99;
  background: #f3f3f3;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;

}

.table-bordered1 {
  box-shadow: 0 2px 0 #ddd, 2px 0 0 #ddd;
  border: 1px solid #ddd;
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
}

.table1 thead tr th {
  text-align: center;
  vertical-align: middle;
}

.table1 tr td {
  text-align: center;
  vertical-align: middle;
}

.table-bordered1 th {
  border-left: 1px solid #ddd;
}

.table-bordered1 th, .table-bordered1 td {
  border-left: 1px solid #ddd;
}

.table1 th, .table1 td {
  line-height: 40px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.toptd {
  background: #ececec;
  padding-top: 30px;
}

.trbackground {
  background: #fdfdfd;
}

.numInput {
  width: 80%;
}
</style>