<template>
  <div class="item" v-if="user">
    <div class="he">
      <h3>基本信息</h3>
      <div class="r">
        <a @click="photo(user.photo_url)">照片<span v-if="user.photo_url==''">（未上传）</span></a>
        <a @click="idPhotoUp(user.idphoto_urlup)">身份证正面<span v-if="user.idphoto_urlup==''">（未上传）</span></a>
        <a @click="idPhotoDown(user.idphoto_urldown)">身份证反面<span
            v-if="user.idphoto_urldown==''">（未上传）</span></a>
        <image-viewer ref="imageViewer" :title="photoName" :imageUrl="photoUrl"></image-viewer>
      </div>
    </div>

    <ul class="ul1">
      <li>姓名：<i>{{ displayValue('name') }}</i></li>
      <li>姓名拼音： <i>{{ nameToPinyin(user.name) }}</i></li>
      <li>国籍：<i>{{ displayValue('guoji') }}</i></li>
      <li>性别：<i>{{ user.sex ? displayGender(user.sex) : '暂无' }}</i></li>
      <li>民族：<i>{{ displayValue('nation') }}</i></li>
      <li>政治面貌：<i>{{ politicsStatusText }}</i></li>
      <li>身份证号码：<i>{{ displayValue('card_num') }}</i></li>
      <li>所属地：<i>{{ gsLocalText }}</i></li>
      <li>居住地：<i>{{ displayValue('address') }}</i></li>
      <li>是否公务员：<i>{{ user.is_servant ? displayYesNo(user.is_servant) : '暂无' }}</i></li>
      <li>职称级别：<i>{{ displayValue('protitle_name') }}</i></li>
      <li>专业特长：<i v-if="user.proexpertise">{{ displayValue('proexpertise') }}</i></li>
      <li>执业律师、国家法律职业资格：<i>{{ user.practicing_fl ? displayYesNo(user.practicing_fl) : '暂无' }}</i></li>
      <li style="width: 100%;">个人介绍：<i v-html="user.introduce || '暂无'"></i></li>
    </ul>
  </div>
</template>

<script>
import pinyin from "pinyin";
import ImageViewer from '@/components/ImageViewer.vue';
import {Axios, AxiosError} from "axios";
/*
* 图片附件表：jnzcw_zhengshicase.dbo.accessory
* */
export default {
  name: "basic",
  props: {
    user: Object,
  },
  data() {
    return {
      photoUrl: '',
      photoName: ''
    }
  },
  components: {
    ImageViewer
  },
  methods: {
    nameToPinyin(name) {
      return pinyin(name, {
        style: pinyin.STYLE_NORMAL, // 普通风格，不带声调
      }).join('').replace(/\s+/g, '');
    },
    handleError(type) {
      this.$message.error(`加载${type}图片时出错`);
    },
    photo(url) {
      this.loadImage(url, '照片');
    },
    idPhotoUp(url) {
      this.loadImage(url, '身份证正面');
    },
    idPhotoDown(url) {
      this.loadImage(url, '身份证反面');
    },
    loadImage(url, type) {
      this.axios.get('/pingZhan/inquire/zcyPhotoById', {
        params: {url}
      }).then(response => {
        let data = response.data;
        if (data.code == 200) {
          this.photoName = data.data.file_Name;
          this.photoUrl = data.data.server_url;
          this.$refs.imageViewer.open();
        } else {
          this.handleError(type);
        }
      }).catch(error => {
        console.log(error);
        this.handleError(type);
      });
    },
    displayValue(field) {
      return this.user[field] ? this.user[field] : '暂无';
    },
    displayGender(sex) {
      return sex === '1' ? '男' : '女';
    },
    displayYesNo(value) {
      return value === '0' ? '否' : '是';
    }
  },
  computed: {
    // 所属地
    gsLocalText() {
      const locationMap = {
        '0': '济南市',
        '1': '外地',
        '2': '港澳台',
        '3': '海外'
      };
      return this.user.gs_local in locationMap ? locationMap[this.user.gs_local] : '暂无';
    },
    //   政治面貌
    politicsStatusText() {
      const politicsMap = {
        '0': '中共党员',
        '1': '预备党员',
        '2': '共青团员',
        '3': '中国国民党革命委员会',
        '4': '中国民主同盟',
        '5': '中国民主促进会',
        '6': '中国农工民主党',
        '7': '中国致公党',
        '8': '台湾民主自治同盟',
        '9': '无党派人士',
        '10': '民建会员'
      };
      return this.user.politics_status in politicsMap ? politicsMap[this.user.politics_status] : '暂无';
    }
  }

}
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>