<template>
  <div>
    <div class="layout-login">
      <div class="header">
        <h2>仲裁信息化监督考评系统</h2>
      </div>
      <div class="hcaid">
        <div class="lt">
          <select class="lsele" v-model="selectedYear">
            <option :value="year">{{ year }}年</option>
            <option :value="year-1">{{ year - 1 }}年</option>
            <option :value="year-2">{{ year - 2 }}年</option>
          </select>
        </div>
        <div class="nav">
          <ul>
            <li><a href="#" @click="dlajxt()">案件管理系统</a></li>
            <li><a>你好，{{ this.username }}</a></li>
            <li><a href="#" @click="tuichu()">退出</a></li>

          </ul>
        </div>
      </div>
      <div class="hmain">
        <div class="hlts">
          <div class="lhds">
            <!--仲裁员办结案-->
            <arbitrator :selectedyear="selectedYear"></arbitrator>
          </div>
          <div class="lbds" style="margin-top: -3.6%;">
            <!--办案秘书结案-->
            <caseclosure :selectedyear="selectedYear"></caseclosure>
          </div>
          <div class="lfts">
            <div class="tit">
              <h2>办案效率</h2>
            </div>
            <caseefficiency :selectedyear="selectedYear "></caseefficiency>
          </div>

        </div>
        <div class="hcent">
          <div class="mcehds">
            <div class="tit">
              <h2>2023年案件办理情况动态展示</h2>
            </div>
            <dynamicul :selectedyear="selectedYear "></dynamicul>
          </div>
          <div class="mcebds">
          <dynamicecharts :selectedyear="selectedYear "></dynamicecharts>
          </div>
          <!--        案件类型分析-->
          <casetype :selectedyear="selectedYear "></casetype>
        </div>
        <div class="hrts">
          <div class="rhds">
            <div class="tit">
              <h2>办案质量</h2>
            </div>
            <casequality :selectedyear="selectedYear"></casequality>
          </div>
          <div class="rbds">
            <div class="tit">
              <h2>仲裁监督</h2>
            </div>
            <arbitrationsupervisor :selectedyear="selectedYear"></arbitrationsupervisor>
          </div>
        </div>
      </div>
    </div>
    <centerarea :selectedyear="selectedYear" ref="centerComponent"
                v-show="showCenterComponent"></centerarea>
    <component :typeMethod="typeMethod" :popup-data="popupData" :selected-year="selectedYear" @close="closePopup" v-bind:is="currentPopup"
               v-if="currentPopup"></component>
  </div>

</template>

<script>
import arbitrator from "./pingzhan/ArbitratorCaseCompletions/ArbitratorCaseCompletions.vue"
import caseclosure from "./pingzhan/CaseClosureAssistant/CaseClosureAssistant.vue"
import caseefficiency from "./pingzhan/CaseEfficiencyOptimizer/CaseEfficiencyOptimizer.vue"
import dynamicul from "./pingzhan/2023dynamic/dynamicUl.vue"
import dynamicecharts from "./pingzhan/2023dynamic/dynamicEcharts.vue"
import casetype from "@/views/pingzhan/CaseTypeAnalyzer.vue"
import casequality from "./pingzhan/CaseQualityEvaluator/CaseQualityEvaluator.vue"
import arbitrationsupervisor from "./pingzhan/ArbitrationSupervisor/ArbitrationSupervisor.vue"
import centerarea from "./pingzhan/2023dynamic/Center/centerComponent.vue"
import popup from "./pingzhan/ArbitratorCaseCompletions/popUp/popUpComponent.vue"
import ybjpopup from "./pingzhan/ArbitratorCaseCompletions/popUp/YbjPopUp.vue"
import wbjpopup from "./pingzhan/ArbitratorCaseCompletions/popUp/WbjPopUp.vue"
import cqbjpopup from "./pingzhan/ArbitratorCaseCompletions/popUp/CqbjPopUp.vue"
import cqwbjpopup from "./pingzhan/ArbitratorCaseCompletions/popUp/CqwbjPopUp.vue"
import byzxpopup from "./pingzhan/ArbitratorCaseCompletions/popUp/ByzxPopUp.vue"
import bcxpopup from "./pingzhan/ArbitratorCaseCompletions/popUp/BcxPopUp.vue"
import zcymore from "./pingzhan/ArbitratorCaseCompletions/popUp/more.vue"
import bamsname from "./pingzhan/CaseClosureAssistant/popUp/namePop.vue"
import bamsybj from "./pingzhan/CaseClosureAssistant/popUp/ybjPop.vue"
import bamswbj from "./pingzhan/CaseClosureAssistant/popUp/wbjPop.vue"
import bamscqbj from "./pingzhan/CaseClosureAssistant/popUp/CqbjPopUp.vue"
import bamscqwbj from "./pingzhan/CaseClosureAssistant/popUp/CqwbjPopUp.vue"
import bamsbcx from "./pingzhan/CaseClosureAssistant/popUp/BcxPopUp.vue"
import bamsbyzx from "./pingzhan/CaseClosureAssistant/popUp/ByzxPopUp.vue"
import bazltjja from "./pingzhan/CaseQualityEvaluator/popUp/TjjaPopUp.vue"
import bazlcaja from "./pingzhan/CaseQualityEvaluator/popUp/CajaPopUp.vue"
import bazlbyzx from "./pingzhan/CaseQualityEvaluator/popUp/ByzxPopUp.vue"
import bazlbcx from "./pingzhan/CaseQualityEvaluator/popUp/BcxPopUp.vue"
import zcjdchao from "./pingzhan/ArbitrationSupervisor/popUp/chaoPopUp.vue"
import zcjdcsx from "./pingzhan/ArbitrationSupervisor/popUp/csxPopUp.vue"
import ycjas from "./pingzhan//CaseEfficiencyOptimizer/popUp/ycjasPopUp.vue"
import zcjd from "./pingzhan/ArbitrationSupervisor/popUp/zcjdPopUp.vue"
import Vue from "vue";

export default {
  name: "home",
  components: {
    arbitrator,
    caseclosure,
    caseefficiency,
    dynamicul,
    dynamicecharts,
    casetype,
    casequality,
    arbitrationsupervisor,
    centerarea,
    popup,
    ybjpopup,
    wbjpopup,
    cqbjpopup,
    cqwbjpopup,
    byzxpopup,
    bcxpopup,
    bamsname,
    bamsybj,
    bamswbj,
    bamscqbj,
    bamscqwbj,
    bamsbcx,
    bamsbyzx,
    bazltjja,
    bazlcaja,
    bazlbyzx,
    bazlbcx,
    zcjdchao,
    zcjdcsx,
    zcymore,
    ycjas,
    zcjd

  },
  data() {
    return {
      loginname: '',
      username: '',
      currentPopup: null,
      popupData: null,
      showCenterComponent: false,
      selectedYear: new Date().getFullYear(),
      typeMethod:'',
    }
  },
  mounted() {
    const token = localStorage.getItem('jwt_token');
    this.inquire(token);
    window.currentYear = this.selectedYear;

  },
  watch: {
    selectedYear(newYear, oldYear) {
      window.currentYear = newYear;
    }
  },
  methods: {
    post(URL, PARAMS) {
      var temp_form = document.createElement("form");
      temp_form.action = URL;
      temp_form.target = "_blank";
      temp_form.method = "post";
      temp_form.style.display = "none";
      for (var x in PARAMS) {
        var opt = document.createElement("textarea");
        opt.name = x;
        opt.value = PARAMS[x];
        temp_form.appendChild(opt);
      }

      document.body.appendChild(temp_form);
      temp_form.submit();
      document.body.removeChild(temp_form);

    },
    tuichu() {
      setTimeout(() => {
        this.$confirm('确认退出系统吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false, //是否可以通过点击空白处关闭弹窗
        }).then(() => {
          this.jdquit();

          this.$message({
            type: 'success',
            message: '退出成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出'
          });
        });
      }, 200)

    },
    dlajxt() {
      this.axios.get('/dl', {}).then(response => {
        setTimeout(() => {
          this.$confirm('确认登录"济南仲裁委员会案件信息管理系统"吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false, //是否可以通过点击空白处关闭弹窗
          }).then(() => {

            const token = localStorage.getItem('jwt_token');
            if (token) {
              this.post(Vue.prototype.$ajxxURL + "a/login", {token: token});
            } else {
              this.$message.error("Token为空!");

            }
            this.$message({
              type: 'success',
              message: '成功!'
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
        }, 200)
      }).catch(error => {
      });
    },
    inquire(token) {
      this.axios.get('/gain', {
        params: {
          token: token,
        }
      }).then(response => {
        let data = response.data;
        if (data.code == 200) {
          this.username = data.data.username
          this.loginname = data.data.loginname
        }


      }).catch(error => {
        this.$message.error("获取登录信息失败!");
      });
    },
    jdquit() {
      const token = localStorage.getItem('jwt_token');
      if (token) {
        this.axios.get('/jdquit', {
          params: {
            token: token,
          }
        }).then(response => {
          let data = response.data;
          if (data.code == 200) {
            localStorage.removeItem('jwt_token');
            var myWindow = window.open(Vue.prototype.$ajxxURL + "public/loginController/quit?token=" + token);
            myWindow.close();
            this.$router.push('/login');

          } else {
            this.$message.error("退出失败!");
          }
        }).catch(error => {
          this.$message.error("退出失败!");
        });


      } else {
        this.$message.error("Token为空!");
      }

    },
    openPopups(popupName,typeMethod,data) {
      this.currentPopup = popupName
      this.popupData = data
      this.typeMethod=typeMethod
    },
    openPopup(popupName, data) {
      this.currentPopup = popupName
      this.popupData = data
    },
    openPopup2(popupName, data) {
      this.$refs.centerComponent.opens(data,popupName)
    },
    closePopup() {
      this.currentPopup = null
      this.popupData = null
    },
    showCenter(type) {
      this.showCenterComponent = type;
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  }
}
</script>
<style lang="css" scoped >
@import "@/assets/css/home/style.css";
@import "@/assets/css/home/slicy.css";
</style>

