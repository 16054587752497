<template>
  <div class="mcpg">
    <top :user="user"></top>
    <router-view :user="user"></router-view>
  </div>
</template>

<script>
import top from './top.vue'
import zcy_details from './zcy_details/zcy_details.vue'
import zcy_documents from './zcy_documents/zcy_documents.vue'
import zcy_handling from './zcy_handling/zcy_handling.vue'
import zcy_point from './zcy_point/zcy_point.vue'
import zcy_reward from './zcy_reward/zcy_reward.vue'

export default {
  name: "zcy",
  components: {
    top,
    zcy_details,
    zcy_documents,
    zcy_handling,
    zcy_point,
    zcy_reward,
  },
  data() {
    return {
      id: null,
      user: null, // 存储用户信息
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.initUser();
  },
  methods: {
    initUser() {
      this.axios.get('/pingZhan/inquire/zcyById', {
        params: {
          id: this.id
        }
      }).then((response) => {
        let data = response.data;
        if (data.code == 200) {
          this.user = data.data;
        } else {
          console.error('数据获取失败');
          this.$message.error("数据获取失败")
        }
      }).catch((error) => {
        console.error('发生错误:', error);
      });
    },
  },
}
</script>

<style scoped>
@import "@/assets/css/zcy/style.css";
@import "@/assets/css/zcy/slicy.css";

a {
  cursor: pointer;
}
</style>