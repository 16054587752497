<template>
  <div v-if="user">
    <span class="mctit">
      <img alt="" src="../../assets/image/zcy/person64.png">
      <div class="text-content">
        <div class="name-and-tags">
           <span v-if="user">仲裁员：{{ user.name }}</span>
          <el-tag v-if="user.excellent==1" type="danger" style="margin-left: 10px;">
            <img src="@/assets/image/zcy/youxiu.png" alt="优秀" class="excellents">
            优秀
          </el-tag>
          <el-tag v-if="user.backbone==1" type="warning" style="margin-left: 10px;">
            <img src="@/assets/image/zcy/gugan.png" alt="骨干" class="excellents">
            骨干
          </el-tag>
        </div>
        <span style="font-size: 16px;">届别：第五届</span>
      </div>

    </span>

    <div class="mchd">
      <ul>
        <li :class="{ on: isActive('/zcy_details/' + id) }">
          <router-link :to="{ name: 'zcy_details', params: { id: id } }">基本信息</router-link>
        </li>
        <li :class="{ on: isActive('/zcy_documents/' + id) }">
          <router-link :to="{ name: 'zcy_documents', params: { id: id } }">证件信息</router-link>
        </li>
        <li :class="{ on: isActive('/zcy_handling/' + id) }">
          <router-link :to="{ name: 'zcy_handling', params: { id: id } }">办案信息</router-link>
        </li>
        <li :class="{ on: isActive('/zcy_reward/' + id) }">
          <router-link :to="{ name: 'zcy_reward', params: { id: id } }">报酬信息</router-link>
        </li>
        <li :class="{ on: isActive('/zcy_point/' + id) }">
          <router-link :to="{ name: 'zcy_point', params: { id: id } }">量化考核</router-link>
        </li>
      </ul>
      <!--      <h6>仲裁员状态：在职</h6>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "top",
  props: {
    user: Object,
  },
  data() {
    return {};
  },
  computed: {
    // 计算属性用于判断路由
    isActive() {
      return (path) => {
        return this.$route.path.includes(path);
      };
    },
    id() {
      return this.$route.params.id;
    },
  },
}
</script>

<style scoped>
a {
  cursor: pointer;
}

.excellents {

  height: 20px;
  width: 20px;
  margin-right: 2px;
  position: relative;
  top: 5px;
}

.el-tag >>> {
  height: 50px;
}
</style>