<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>基本信息</h3>
    </div>
    <ul v-if="basicList" class="ul1">
      <li>预立案号：<i>{{ basicList.caseInfo.case_num || '无' }}</i></li>
      <li>立案秘书：<i>{{ basicList.liAnBy.name || '无' }}</i></li>
      <li>立案日期：<i>{{ DateFormat(basicList.liAnDate) }}</i></li>
      <li>案号：<i>{{ basicList.anhao || '无' }}</i></li>
      <li>案由：<i>{{ basicList.anyou || '无' }}</i></li>
      <li>是否首次申请仲裁：<i>{{ basicList.caseInfo.is_first == '0' ? '否' : '是' }}</i></li>
      <li style="width: 100%;">争议金额：<i>{{ basicList.zhengyijine || '无' }}</i></li>
      <li style="width: 100%;">仲裁请求：<i>{{ basicList.caseInfo.zc_ask_one || '无' }}</i></li>
      <li style="width: 100%;">仲裁请求二：<i>{{ basicList.caseInfo.zc_ask_two || '无' }}</i></li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "basic",
  props: {
    bananId: String,
    caseNum: String,
  },
  data() {
    return {
      basicList: null,
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.basic_init();
        }
      }
    },
    basicList(newVal) {
      if (newVal) {
        const hasData = !!newVal;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    basic_init() {
      this.axios.get('/api/handling/Basic', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.basicList = data.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    DateFormat(value) {
      return moment(value).format('YYYY-MM-DD') || '无';
    }
  }
}
</script>

<style scoped>

</style>