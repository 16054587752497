import zcyRight from "../../views/zcy/zcy_handling/components/right.vue";
import OverdueCases from "../../views/zcy/zcy_handling/components/OverdueCases.vue";

export default [
    /*办案信息right路由*/
    {
        path: 'right/all-cases',
        name: '全部案件',
        component: zcyRight
    },
    {
        path: 'right/completed',
        name: '已办结',
        component: zcyRight
    },
    {
        path: 'right/unsettled',
        name: '未办结',
        component: zcyRight
    },
    {
        path: 'right/deadline',
        name: '超期办结',
        component: zcyRight
    },
    {
        path: 'right/settled',
        name: '超期未办结',
        component: zcyRight
    },
    {
        path: 'right/outstanding',
        name: '长期未结',
        component: zcyRight
    },
    {
        path: 'right/authenticate',
        name: '鉴定',
        component: zcyRight
    },
    {
        path: 'right/suspend',
        name: '中止',
        component: zcyRight
    },
    {
        path: 'right/postpone',
        name: '延期',
        component: zcyRight
    },
    {
        path: 'right/cxbcx',
        name: '被撤销',
        component: zcyRight
    },
    {
        path: 'right/court',
        name: '不予执行',
        component: zcyRight
    },
    {
        path: 'right/overdue',
        name: '逾期归档案件',
        component: OverdueCases
    },
];