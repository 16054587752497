<template>
  <el-dialog :title="title" :visible.sync="visible">
    <el-image :src="imageUrl" style="width: 100%; height: auto;"></el-image>
  </el-dialog>
</template>

<script>
export default {
  name: "ImageViewer",
  props: {
    title: {
      type: String,
      default: '查看图片'
    },
    imageUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    open() {
      this.visible = true;
    }
  }
}
</script>

<style scoped>

</style>