<template>
  <div>
    <div v-if="isLoading" class="loading-container">
      <loading></loading>
    </div>
    <div v-if="!isLoading">
      <div class="mcefts">
        <div class="uflex">
          <div class="lt">
            <div class="tit">
              <h2>案件类型分析</h2>
            </div>
            <div class="lse">
              <select v-model="selectedOption" class="lsele">
                <option value="anjianleixin">案件类型分析</option>
                <option value="biaodee">标的额分析</option>
                <option value="anjianshoufei">案件收费</option>
              </select>
            </div>
          </div>
          <ul>
            <li :class="{  'on': isCurrentYear }" @click="currentYear = selectedyear">
              {{ selectedyear }}年
            </li>
            <li :class="{ 'on': isPreviousYear }" @click="currentYear = selectedyear - 1">
              {{ selectedyear - 1 }}年
            </li>
          </ul>
        </div>
        <div class="box">
          <div id="item4" class="covs on"></div>
          <div id="item5" class="covs"></div>
          <div id="item6" class="covs"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "CaseTypeAnalyzer",
  components: {},
  props: {selectedyear: Number},
  data() {
    return {
      data: null,
      selectedOption: 'anjianleixin', // 设置默认选择
      Type: [],
      titles: null,
      caseCounts: null,
      titlesLastYear: null,
      caseCountsLastYear: null,
      currentYear: this.selectedyear, // 初始时等于 prop 的值
      isLoading: true, // 加载状态标志
    }
  },
  computed: {
    isCurrentYear() {
      return this.currentYear === this.selectedyear;
    },
    isPreviousYear() {
      return this.currentYear === this.selectedyear - 1;
    }
  },
  watch: {
    selectedOption: 'initialize',
    currentYear(newYear, oldYear) {
      this.initialize();
    },
    selectedyear(newYear, oldYear) {
      this.currentYear = newYear;
    }

  },
  methods: {
    init(data) {
      this.isLoading = false; // 数据加载完成
      this.$nextTick(() => {
        // 确保 DOM 更新完成后再初始化图表
        this.initChart();
      });

    },
    initialize() {
      this.isLoading = true; // 开始加载数据
      this.axios.get('/pingZhan/report/CaseTypeAnalyzer', {
        params: {
          type: this.selectedOption,
          currentYear: this.currentYear
        }
      }).then((response) => {
        let data = response.data;
        //console.log(data);
        if (data.code == 200) {
          this.data = data.data;

          this.init(this.data);
        } else {
          console.log('数据获取失败');
        }
      }).catch((error) => {
        console.error('发生错误:', error);
        this.isLoading = false; // 数据加载完成
      });
    },
    loadChartData(key) {
      const nowYear = this.data[this.currentYear];
      this.titles = nowYear.map((item) => item.title);
      if (this.selectedOption == "biaodee" || this.selectedOption == "anjianshoufei") {
        this.caseCounts = nowYear.map((item) => {
          const numericValue = parseFloat(item[key]);
          if (!isNaN(numericValue)) {
            return numericValue.toFixed(2);
          } else {
            return item[key]; // 如果转换失败，返回原始值
          }
        });
      } else {
        this.caseCounts = nowYear.map((item) => item[key]);
      }

    },
    loadChartDataLastYear(key) {
      const lastYear = this.data[this.currentYear - 1];
      this.titlesLastYear = lastYear.map((item) => item.title);
      if (this.selectedOption == "biaodee" || this.selectedOption == "anjianshoufei") {
        this.caseCountsLastYear = lastYear.map((item) => {
          const numericValue = parseFloat(item[key]);
          if (!isNaN(numericValue)) {
            return numericValue.toFixed(2);
          } else {
            return item[key]; // 如果转换失败，返回原始值
          }
        });
      } else {
        this.caseCountsLastYear = lastYear.map((item) => item[key]);
      }
    },
    initChart() {
      // 处理响应数据
      if (this.selectedOption === 'anjianleixin') {
        this.loadChartData('case_count');
        this.loadChartDataLastYear('case_count');
      } else if (this.selectedOption === 'biaodee') {
        this.loadChartData('biaoDiE');
        this.loadChartDataLastYear('biaoDiE');
      } else if (this.selectedOption === 'anjianshoufei') { // 新增的条件
        this.loadChartData('fee'); // 假设 'fee' 是表示收费的字段
        this.loadChartDataLastYear('fee');
      }


      var chartDom = document.getElementById('item4');
      var myChart4 = echarts.init(chartDom);
      var option4;
      option4 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          left: "8%",
          right: "3%",
          top: "18%",
          bottom: "22%",
        },
        xAxis: [{
          type: 'category',
          data: this.titles,
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 9,
            margin: 5,
            color: "#fff",

          },
        }],
        yAxis: [{
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#656981",

          },
        },],
        series: [{
          name: this.selectedyear + '年',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value;
            }
          },
          barWidth: '30%',
          showBackground: true,
          label: {
            show: true,
            position: 'top',
            color: "#5edad1",
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#60dcd2'
            },
              {
                offset: 1,
                color: '#40bdb4'
              }
            ])
          },
          data: this.caseCounts
        }
        ]
      };

      option4 && myChart4.setOption(option4);


      var chartDom = document.getElementById('item5');
      var myChart5 = echarts.init(chartDom);
      var option5;

      option5 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          left: "8%",
          right: "3%",
          top: "18%",
          bottom: "22%",
        },
        xAxis: [{
          type: 'category',
          data: this.titlesLastYear,
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#fff",
          },
        }],
        yAxis: [{
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#656981",
          },
        },],
        series: [{
          name: this.selectedyear - 1 + '年',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value;
            }
          },
          barWidth: '30%',
          showBackground: true,
          label: {
            show: true,
            position: 'top',
            color: "#5edad1",
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#60dcd2'
            },
              {
                offset: 1,
                color: '#40bdb4'
              }
            ])
          },
          data: this.caseCountsLastYear
        }
        ]
      };

      option5 && myChart5.setOption(option5);

      var chartDom = document.getElementById('item6');
      var myChart6 = echarts.init(chartDom);
      var option6;

      option6 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          left: "8%",
          right: "3%",
          top: "18%",
          bottom: "22%",
        },
        xAxis: [{
          type: 'category',
          data: this.titlesLastYear,
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#fff",
          },
        }],
        yAxis: [{
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#656981",
          },
        },],
        series: [{
          name: this.selectedyear - 1 + '年',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value;
            }
          },
          barWidth: '30%',
          showBackground: true,
          label: {
            show: true,
            position: 'top',
            color: "#5edad1",
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#60dcd2'
            },
              {
                offset: 1,
                color: '#40bdb4'
              }
            ])
          },
          data: this.caseCountsLastYear
        }
        ]
      };

      option6 && myChart6.setOption(option6);

    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  }
};
</script>

<style scoped>
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
