<template>
  <div id="page2">
    <div style="width:100%">
      <div style="width:80%; margin-left :auto ; margin-right:auto;">
        <h1 style="text-align: center;">济南仲裁委员会仲裁员个案量化考核表</h1>
        <table v-if="khjbxxData" id="contentTable1" class="table1 table-striped1 table-bordered1 table-condensed1"
               style="table-layout: fixed;">
          <tr>
            <td colspan="12">
              <table v-if="ajxxData" class="table1 table-striped1 table-bordered1 table-condensed1"
                     style="table-layout: fixed;">
                <tr>
                  <td class="tdbj">案号</td>
                  <td colspan="2">{{ ajxxData.anhao }}</td>
                  <td class="tdbj">案由</td>
                  <td colspan="2">{{ ajxxData.caseReasonName }}</td>
                  <td class="tdbj">结案方式</td>
                  <td colspan="2">{{ ajxxData.jieanType }}</td>
                  <td class="tdbj">办案秘书签字</td>
                  <td colspan="2">{{ ajxxData.su_bnms }}</td>
                </tr>
                <tr>
                  <td class="tdbj"> 首席仲裁员</td>
                  <td colspan="2">{{ ztxxData.shouxi }}</td>
                  <td class="tdbj">仲裁员(申)</td>
                  <td colspan="2"> {{ ztxxData.zcyuan1 }}</td>
                  <td class="tdbj">仲裁员(被)</td>
                  <td colspan="5">{{ ztxxData.zcyuan2 }}</td>
                </tr>
                <tr>
                  <td class="tdbj">申请人</td>
                  <td colspan="5">{{ ajxxData.sqrStr }}</td>
                  <td class="tdbj">被申请人</td>
                  <td colspan="5">{{ ajxxData.bsqrStr }}</td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td class="tdbj" colspan="12" style="text-align: center;">考核基本信息</td>
          </tr>
          <tr>
            <td align="center" class="tdbj" rowspan="2">序号</td>
            <td class="tdbj" colspan="4" rowspan="2">考核项目</td>
            <td class="tdbj" rowspan="2" style="text-align: center;">得分分值</td>
            <td class="tdbj" colspan="6" style="text-align: center;">得分情况</td>
          </tr>
          <tr>
            <td class="tdbj" colspan="2" style="text-align: center;">首席</td>
            <td class="tdbj" colspan="2" style="text-align: center;">仲裁员(申)</td>
            <td class="tdbj" colspan="2" style="text-align: center;">仲裁员(被)</td>
          </tr>

          <!-- 一、 庭前准备情况（共15分） -->
          <tr>
            <td align="center" class="tdbj" rowspan="3">一、庭前准备情况（共15分）</td>
            <td class="textContent" colspan="4">1、及时审阅案卷材料并安排开庭</td>
            <td>5分</td>
            <td colspan="2">
              {{ khjbxxData.project1 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy1 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt1 }}
            </td>
          </tr>
          <tr>
            <td class="textContent" colspan="4">2、组织庭前合议并拟定庭审提纲</td>
            <td>5分</td>
            <td colspan="2">
              {{ khjbxxData.project2 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy2 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt2 }}
            </td>
          </tr>
          <tr>
            <td class="textContent" colspan="4">3、签署公正声明</td>
            <td>5分</td>
            <td colspan="2">
              {{ khjbxxData.project3 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy3 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt3 }}
            </td>
          </tr>

          <!-- 二 、开庭情况（共40分） -->
          <tr>
            <td align="center" class="tdbj" rowspan="5">二 、开庭情况（共40分）</td>
            <td class="textContent" colspan="4">1、不缺席、按时到庭审理，注重仪容仪表，不早退</td>
            <td>10分</td>
            <td colspan="2">
              {{ khjbxxData.project4 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy4 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt4 }}
            </td>
          </tr>
          <tr>
            <td class="textContent" colspan="4">
              2、严格遵守仲裁庭纪律，不随意离席走动，不进行其他与庭审无关的活动，不酒后开庭
            </td>
            <td>5分</td>
            <td colspan="2">
              {{ khjbxxData.project5 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy5 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt5 }}
            </td>
          </tr>
          <tr>
            <td class="textContent" colspan="4">
              3、平等对待双方当事人，不与当事人辩论，不进行偏向性提示，保障双方当事人都有充分陈述的权利
            </td>
            <td>5分</td>
            <td colspan="2">
              {{ khjbxxData.project52 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy52 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt52 }}
            </td>
          </tr>
          <tr>
            <td class="textContent" colspan="4">
              4、不随意发表结论性意见或在当事人面前直接反驳其他仲裁员的意见或披露仲裁庭内部意见的分歧
            </td>
            <td>10分</td>
            <td colspan="2">
              {{ khjbxxData.project53 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy53 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt53 }}
            </td>
          </tr>
          <tr>
            <td class="textContent" colspan="4">5、能够掌握庭审技巧、善于运用审判思维、有效控制庭审节奏</td>
            <td>10分</td>
            <td colspan="2">
              {{ khjbxxData.project54 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy54 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt54 }}
            </td>
          </tr>

          <!-- 三 -->
          <tr>
            <td align="center" class="tdbj">三</td>
            <td class="textContent" colspan="4">严格遵守保密规定，不存在向当事人或其代理人透露仲裁秘密的情况</td>
            <td>5分</td>
            <td colspan="2">
              {{ khjbxxData.project6 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy6 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt6 }}
            </td>
          </tr>

          <!-- 四 -->
          <tr>
            <td align="center" class="tdbj">四</td>
            <td class="textContent" colspan="4">及时庭后合议，积极发表意见</td>
            <td align="center">5分</td>
            <td colspan="2">
              {{ khjbxxData.project7 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy7 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt7 }}
            </td>
          </tr>

          <!-- 五 -->
          <tr>
            <td align="center" class="tdbj">五</td>
            <td class="textContent" colspan="4">认真审查裁决意见，审限内裁决</td>
            <td>10分</td>
            <td colspan="2">
              {{ khjbxxData.project8 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy8 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt8 }}
            </td>
          </tr>

          <!-- 六 -->
          <tr>
            <td align="center" class="tdbj">六</td>
            <td class="textContent" colspan="4">始终促使当事人和解、调解</td>
            <td>5分</td>
            <td colspan="2">
              {{ khjbxxData.project9 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy9 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt9 }}
            </td>
          </tr>

          <!-- 七、裁决书内容（共20分） -->
          <tr>
            <td align="center" class="tdbj" rowspan="4">七、裁决书内容（共20分）</td>
            <td class="textContent" colspan="4">1、格式、结构规范；无文字、语法错误；无计算错误</td>
            <td align="center">5分</td>
            <td colspan="2">
              {{ khjbxxData.project10 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy10 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt10 }}
            </td>
          </tr>
          <tr>
            <td class="textContent" colspan="4">2、证据列举完整，证据内容具体明确，证据认定充分</td>
            <td align="center">5分</td>
            <td colspan="2">
              {{ khjbxxData.project11 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy11 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt11 }}
            </td>
          </tr>
          <tr>
            <td class="textContent" colspan="4">3、法律适用准确</td>
            <td align="center">5分</td>
            <td colspan="2">
              {{ khjbxxData.project12 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy12 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt12 }}
            </td>
          </tr>
          <tr>
            <td class="textContent " colspan="4">4、裁判结果公平合理，无超裁、漏裁情况</td>
            <td align="center">5分</td>
            <td colspan="2">
              {{ khjbxxData.project13 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy13 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt13 }}
            </td>
          </tr>

          <tr>
            <td align="center" class="tdbj">合计</td>
            <td colspan="4"></td>
            <td align="center"></td>
            <td colspan="2">
              {{ khjbxxData.hjproject }}
            </td>
            <td colspan="2">
              {{ khjbxxData.hjzc1 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.hjqt }}
            </td>
          </tr>

          <tr>
            <td align="center" class="tdbj">报酬提取</td>
            <td class="tdbj">标的</td>
            <td> {{ tzjfxxData.dis_money }}</td>
            <td class="tdbj">实收仲裁受理费</td>
            <td>{{ tzjfxxData.sh_slf }}</td>
            <td class="tdbj">报酬金额</td>
            <td colspan="6"></td>
          </tr>

          <tr>
            <td align="center" class="tdbj" rowspan="2">特别项（共20分）</td>
            <td class="textContent" colspan="4">1、承办重大、疑难、复杂案件，社会影响力大，为本会赢得了良好的社会信誉</td>
            <td align="center">10分</td>
            <td colspan="2">
              {{ khjbxxData.project14 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy14 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt14 }}
            </td>
          </tr>
          <tr>
            <td class="textContent" colspan="4">
              2、提前结案：除当事人撤回申请或自行和解的以外，简易程序提前一个月结案，普通程序提前二个月结案
            </td>
            <td align="center">10分</td>
            <td colspan="2">
              {{ khjbxxData.project15 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.zcy15 }}
            </td>
            <td colspan="2">
              {{ khjbxxData.qt15 }}
            </td>
          </tr>
          <tr>
            <td colspan="4"></td>
            <td colspan="4">
              <div class="control-group">
                <label class="control-label tdbj">量化考核表：</label>
                <div class="controls">
                  <span @click="downloadFile('1.docx')">下载</span>
                  <br/>
                  <span @click="openWordHistory('wjscjl')">文件历史记录</span>
                </div>
              </div>
            </td>
            <td colspan="4"></td>
          </tr>

        </table>
      </div>
    </div>

    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialogwjscjl" class="new-dialog"
               custom-class="addMicroDialog"
               title="文件上传记录" width="80%" @close="closewjscjl">
      <component v-bind:is="currentPopup" :caseInfoId_s="caseInfoId" :lhkhWod="lhkhWod"
                 @fatherMethod="closeDialog"></component>
    </el-dialog>

  </div>


</template>

<script>

import wjscjl from "@/views/zcy/zcy_point/zcy_wjscjl.vue"

export default {
  name: "zcy_assess",
  props: {
    caseInfoId: '',
  },
  components: {
    wjscjl

  },

  data() {
    return {
      caseInfoId_s: '',
      lhkhWod: '',
      currentPopup: null,
      dialogwjscjl: false,
      ajxxData: null,// 存放获取到的数据
      ztxxData: null,
      khjbxxData: null,
      tzjfxxData: null
    }
  },
  //方法集合
  methods: {
    downloadFile(fileName) {
      const fileUrl = 'http://localhost:8080/' + fileName; // 文件的URL地址
      window.open(fileUrl);
    },
    closeDialog(cmd) {
      this.closewjscjl();
    },
    openWordHistory(popupName) {
      this.dialogwjscjl = true
      this.currentPopup = popupName
      this.lhkhWod = this.khjbxxData.lhkh_wod
    },
    closewjscjl() {
      this.currentPopup = null
      this.caseInfoId_s = null
      this.lhkhWod = null
      this.dialogwjscjl = false
    },
    init() {
      this.startLoading();
      //alert("=="+this.caseInfoId);
      this.axios.get('/pingZhan/assess/getajcx', {
        params: {
          caseInfoId: this.caseInfoId
        }
      }).then(response => {
        this.Loading.close();
        const data = response.data;
        if (data.code == 200) {
          this.ajxxData = data.data.ajxx;
          this.ztxxData = data.data.ztxx;
          this.khjbxxData = data.data.khjbxx;
          this.tzjfxxData = data.data.tzjfxx;
        } else {
          this.remind(data.message);
          this.$emit("fatherMethod", "");
        }
      }).catch(error => {
        this.remind('发生错误:', error);
        this.Loading.close();
        this.$emit("fatherMethod", "");
        console.error('发生错误:', error);
      })

    },


    remind(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'warning'
      });
    },
    startLoading() {
      this.Loading = this.$loading({
        lock: false,
        text: '正在加载中...',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.6)'//遮罩背景色

      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

    this.init()
  }
}

</script>

<style scoped>
@import "@/assets/css/zcy/dialogStyle.css";
h1 {
  color: #317eac;
  font-size: 2em;
  margin-block-end: 0.67em;
  font-weight: bold;
}

.tdbj {
  font-weight: bold;
  background: #F5F7FA;
  /*font-style: italic;*/
}
.table1 th, .table td {
  line-height: 40px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table1 {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  margin-bottom: 8px;
  background-color: #fdfdfd;
}

.table1 thead {
  color: #878d99;
  background: #f3f3f3;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;

}

.table-bordered1 {
  box-shadow: 0 2px 0 #ddd, 2px 0 0 #ddd;
  border: 1px solid #ddd;
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
}

.table1 thead tr th {
  text-align: center;
  vertical-align: middle;
}

.table1 tr td {
  text-align: center;
  vertical-align: middle;
}

.table-bordered1 th {
  border-left: 1px solid #ddd;
}

.table-bordered1 th, .table-bordered1 td {
  border-left: 1px solid #ddd;
}

.table1 th, .table1 td {
  line-height: 40px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.toptd {
  background: #ececec;
  padding-top: 30px;
}

.trbackground {
  background: #fdfdfd;
}

.numInput {
  width: 80%;
}
</style>