<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>反请求信息</h3>
    </div>
    <template v-if="counterclaimList">
      <ul v-if="counterclaimList.sqr.dsrType=='0'" class="ul1">
        <li class="bolder-and-larger-text" style="width: 100%;">申请人为{{
            dsrType(this.counterclaimList.sqr.dsrType)
          }}
        </li>
        <li>姓名：<i>{{ counterclaimList.sqr.dsrName || '无' }}</i></li>
        <li>性别：<i>{{ gender(this.counterclaimList.sqr.dsrSex) }}</i></li>
        <li>地域：<i>{{ district(this.counterclaimList.sqr.dsrDistrict) }}</i></li>
        <li>证件类型：<i>{{ zjType(this.counterclaimList.sqr.zjType) }}</i></li>
        <li>证件号码：<i>{{ counterclaimList.sqr.zjNum || '无' }}</i></li>
        <li>联系电话： <i>{{ counterclaimList.sqr.dsrPhone || '无' }}</i></li>
        <li>邮箱：<i>{{ counterclaimList.sqr.dsrEmail }}</i></li>
        <li>地址：<i>{{ counterclaimList.sqr.dsrAddress || '无' }}</i></li>
        <li>邮政编码：<i>{{ counterclaimList.sqr.postalCode || '无' }}</i></li>
        <li style="width: 100%;">仲裁文书送达地：<i>{{ counterclaimList.sqr.wsSdAddress || '无' }}</i></li>
        <li>法律文书收件人：<i>{{ counterclaimList.sqr.shoujianMan || '无' }}</i></li>
        <li>法律文书收件人电话：<i>{{ counterclaimList.sqr.shoujianPhone || '无' }}</i></li>
        <li>其它联系方式：<i>{{ counterclaimList.sqr.otherFs || '无' }}</i></li>
      </ul>
      <ul v-if="counterclaimList.sqr.dsrType=='1'" class="ul1">
        <li class="bolder-and-larger-text" style="width: 100%;">申请人为{{
            dsrType(this.counterclaimList.sqr.dsrType)
          }}
        </li>
        <li>名称：<i>{{ counterclaimList.sqr.danweiName || '无' }}</i></li>
        <li>法定代表人/负责人：<i>{{ counterclaimList.sqr.dsrName || '无' }}</i></li>
        <li>性别：<i>{{ gender(this.counterclaimList.sqr.dsrSex) }}</i></li>
        <li>地域：<i>{{ district(this.counterclaimList.sqr.dsrDistrict) }}</i></li>
        <li>是否三证合一：<i>{{
            counterclaimList.sqr && counterclaimList.sqr.isSanzheng ? counterclaimList.sqr.isSanzheng == '0' ? '是' : '否' : '无'
          }}</i></li>
        <li>统一社会信用代码：<i>{{ counterclaimList.sqr.businessNum || '无' }}</i></li>
        <li>法定代表人证件类型：<i>{{ zjType(this.counterclaimList.sqr.zjType) }}</i></li>
        <li>法定代表人证件号码：<i>{{ counterclaimList.sqr.zjNum || '无' }}</i></li>
        <template v-if="counterclaimList.sqr.isSanzheng==1">
          <li>法人登记注册账号：<i>{{ counterclaimList.sqr.frDjzcZhanghao || '无' }}</i></li>
          <li>组织机构代码：<i>{{ counterclaimList.sqr.zzjgDaima || '无' }}</i></li>
        </template>
        <li>联系电话：<i>{{ counterclaimList.sqr.zjNum || '无' }}</i></li>
        <li>邮箱：<i>{{ counterclaimList.sqr.dsrEmail || '无' }}</i></li>
        <li>地址：<i>{{ counterclaimList.sqr.dsrAddress || '无' }}</i></li>
        <li>邮政编码：<i>{{ counterclaimList.sqr.postalCode || '无' }}</i></li>
        <li>仲裁文书送达地：<i>{{ counterclaimList.sqr.wsSdAddress || '无' }}</i></li>
        <li>法律文书收件人：<i>{{ counterclaimList.sqr.shoujianMan || '无' }}</i></li>
        <li>法律文书收件人电话：<i>{{ counterclaimList.sqr.shoujianPhone || '无' }}</i></li>
        <li>其它联系方式：<i>{{ counterclaimList.sqr.otherFs || '无' }}</i></li>

      </ul>
      <ul v-if="counterclaimList.sqr.dsrType=='2'" class="ul1">
        <li class="bolder-and-larger-text" style="width: 100%;">申请人为{{
            dsrType(this.counterclaimList.sqr.dsrType)
          }}
        </li>
        <li>名称：<i>{{ counterclaimList.sqr.danweiName || '无' }}</i></li>
        <li>法定让代表人/负责人：<i>{{ counterclaimList.sqr.dsrName || '无' }}</i></li>
        <li>地域：<i>{{ district(this.counterclaimList.sqr.dsrDistrict) }}</i></li>
        <li>联系电话： <i>{{ counterclaimList.sqr.dsrPhone || '无' }}</i></li>
        <li>邮箱：<i>{{ counterclaimList.sqr.dsrEmail }}</i></li>
        <li>地址：<i>{{ counterclaimList.sqr.dsrAddress || '无' }}</i></li>
        <li>邮政编码：<i>{{ counterclaimList.sqr.postalCode || '无' }}</i></li>
        <li style="width: 100%;">仲裁文书送达地：<i>{{ counterclaimList.sqr.wsSdAddress || '无' }}</i></li>
        <li>法律文书收件人：<i>{{ counterclaimList.sqr.shoujianMan || '无' }}</i></li>
        <li>法律文书收件人电话：<i>{{ counterclaimList.sqr.shoujianPhone || '无' }}</i></li>
        <li>其它联系方式：<i>{{ counterclaimList.sqr.otherFs || '无' }}</i></li>
      </ul>
      <ul v-if="counterclaimList.sqr.dailiIdOne!=null" class="ul1">
        <li style="width: 100%;">代理人一信息</li>
        <li>代理人类型：<i>{{ dlrType(this.counterclaimList.sqr.dlrTypeOne) }}</i></li>
        <template v-if="counterclaimList.sqr.dlrTypeOne==0">
          <li>姓名：<i>{{ counterclaimList.sqr.dlrNameOne || '无' }}</i></li>
          <li>证件类型：<i>{{ zjType(counterclaimList.sqr.dlrZjTypeOne) }}</i></li>
          <li>证件号码：<i>{{ counterclaimList.sqr.dlrZjNumOne || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.sqr.dlrPhoneOne || '无' }}</i></li>
          <li>邮编：<i>{{ counterclaimList.sqr.dlrEmailOne || '无' }}</i></li>
        </template>
        <template v-if="counterclaimList.sqr.dlrTypeOne==1 || counterclaimList.sqr.dlrTypeOne==2">
          <li>姓名：<i>{{ counterclaimList.sqr.dlrNameOne || '无' }}</i></li>
          <li>工作单位：<i>{{ counterclaimList.sqr.danweiNameOne || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.sqr.dlrPhoneOne || '无' }}</i></li>
          <li>执业证号：<i>{{ counterclaimList.sqr.zhiyeNumOne || '无' }}</i></li>
        </template>
        <template v-if="counterclaimList.sqr.dlrTypeOne==3">
          <li>姓名：<i>{{ counterclaimList.sqr.dlrNameOne || '无' }}</i></li>
          <li>证件类型：<i>{{ zjType(counterclaimList.sqr.dlrZjTypeOne) }}</i></li>
          <li>证件号码：<i>{{ counterclaimList.sqr.dlrZjNumOne || '无' }}</i></li>
          <li>公司名称：<i>{{ counterclaimList.sqr.gongsiNameOne || '无' }}</i></li>
          <li>职务：<i>{{ counterclaimList.sqr.gsPostOne || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.sqr.dlrPhoneOne || '无' }}</i></li>
          <li>邮编：<i>{{ counterclaimList.sqr.dlrEmailOne || '无' }}</i></li>
        </template>
      </ul>
      <ul v-if="counterclaimList.sqr.dailiIdTwo!=null" class="ul1">
        <li style="width: 100%;">代理人二信息</li>
        <li>代理人类型：<i>{{ dlrType(this.counterclaimList.sqr.dlrTypeTwo) }}</i></li>
        <template v-if="counterclaimList.sqr.dlrTypeTwo==0">
          <li>姓名：<i>{{ counterclaimList.sqr.dlrNameTwo || '无' }}</i></li>
          <li>证件类型：<i>{{ zjType(counterclaimList.sqr.dlrNameTwo) }}</i></li>
          <li>证件号码：<i>{{ counterclaimList.sqr.dlrZjNumTwo || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.sqr.dlrPhoneTwo || '无' }}</i></li>
          <li>邮编：<i>{{ counterclaimList.sqr.dlrEmailTwo || '无' }}</i></li>
        </template>
        <template v-if="counterclaimList.sqr.dlrTypeTwo==1 || counterclaimList.sqr.dlrTypeTwo==2">
          <li>姓名：<i>{{ counterclaimList.sqr.dlrNameTwo || '无' }}</i></li>
          <li>工作单位：<i>{{ counterclaimList.sqr.danweiNameTwo || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.sqr.dlrPhoneTwo || '无' }}</i></li>
          <li>执业证号：<i>{{ counterclaimList.sqr.zhiyeNumTwo || '无' }}</i></li>
        </template>
        <template v-if="counterclaimList.sqr.dlrTypeTwo==3">
          <li>姓名：<i>{{ counterclaimList.sqr.dlrNameTwo || '无' }}</i></li>
          <li>证件类型：<i>{{ zjType(counterclaimList.sqr.dlrZjTypeTwo) }}</i></li>
          <li>证件号码：<i>{{ counterclaimList.sqr.dlrZjNumTwo || '无' }}</i></li>
          <li>公司名称：<i>{{ counterclaimList.sqr.gongsiNameTwo || '无' }}</i></li>
          <li>职务：<i>{{ counterclaimList.sqr.gsPostTwo || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.sqr.dlrPhoneTwo || '无' }}</i></li>
          <li>邮编：<i>{{ counterclaimList.sqr.dlrEmailTwo || '无' }}</i></li>
        </template>
      </ul>

      <ul v-if="counterclaimList.bsqr.dsrType=='0'" class="ul1">
        <li class="bolder-and-larger-text" style="width: 100%;">被申请人为{{
            dsrType(this.counterclaimList.bsqr.dsrType)
          }}
        </li>
        <li>姓名：<i>{{ counterclaimList.bsqr.dsrName || '无' }}</i></li>
        <li>性别：<i>{{ gender(this.counterclaimList.bsqr.dsrSex) }}</i></li>
        <li>地域：<i>{{ district(this.counterclaimList.bsqr.dsrDistrict) }}</i></li>
        <li>证件类型：<i>{{ zjType(this.counterclaimList.bsqr.zjType) }}</i></li>
        <li>证件号码：<i>{{ counterclaimList.bsqr.zjNum || '无' }}</i></li>
        <li>联系电话： <i>{{ counterclaimList.bsqr.dsrPhone || '无' }}</i></li>
        <li>邮箱：<i>{{ counterclaimList.bsqr.dsrEmail }}</i></li>
        <li>地址：<i>{{ counterclaimList.bsqr.dsrAddress || '无' }}</i></li>
        <li>邮政编码：<i>{{ counterclaimList.bsqr.postalCode || '无' }}</i></li>
        <li style="width: 100%;">仲裁文书送达地：<i>{{ counterclaimList.bsqr.wsSdAddress || '无' }}</i></li>
        <li>法律文书收件人：<i>{{ counterclaimList.bsqr.shoujianMan || '无' }}</i></li>
        <li>法律文书收件人电话：<i>{{ counterclaimList.bsqr.shoujianPhone || '无' }}</i></li>
        <li>其它联系方式：<i>{{ counterclaimList.bsqr.otherFs || '无' }}</i></li>
      </ul>
      <ul v-if="counterclaimList.bsqr.dsrType=='1'" class="ul1">
        <li class="bolder-and-larger-text" style="width: 100%;">被申请人为{{
            dsrType(this.counterclaimList.bsqr.dsrType)
          }}
        </li>
        <li>名称：<i>{{ counterclaimList.bsqr.danweiName || '无' }}</i></li>
        <li>法定代表人/负责人：<i>{{ counterclaimList.bsqr.dsrName || '无' }}</i></li>
        <li>性别：<i>{{ gender(this.counterclaimList.bsqr.dsrSex) }}</i></li>
        <li>地域：<i>{{ district(this.counterclaimList.bsqr.dsrDistrict) }}</i></li>
        <li>是否三证合一：<i>{{
            counterclaimList.bsqr && counterclaimList.bsqr.isSanzheng ? counterclaimList.bsqr.isSanzheng == '0' ? '是' : '否' : '无'
          }}</i></li>
        <li>统一社会信用代码：<i>{{ counterclaimList.bsqr.businessNum || '无' }}</i></li>
        <li>法定代表人证件类型：<i>{{ zjType(this.counterclaimList.bsqr.zjType) }}</i></li>
        <li>法定代表人证件号码：<i>{{ counterclaimList.bsqr.zjNum || '无' }}</i></li>
        <template v-if="counterclaimList.bsqr.isSanzheng==1">
          <li>法人登记注册账号：<i>{{ counterclaimList.bsqr.frDjzcZhanghao || '无' }}</i></li>
          <li>组织机构代码：<i>{{ counterclaimList.bsqr.zzjgDaima || '无' }}</i></li>
        </template>
        <li>联系电话：<i>{{ counterclaimList.bsqr.zjNum || '无' }}</i></li>
        <li>邮箱：<i>{{ counterclaimList.bsqr.dsrEmail || '无' }}</i></li>
        <li>地址：<i>{{ counterclaimList.bsqr.dsrAddress || '无' }}</i></li>
        <li>邮政编码：<i>{{ counterclaimList.bsqr.postalCode || '无' }}</i></li>
        <li>仲裁文书送达地：<i>{{ counterclaimList.bsqr.wsSdAddress || '无' }}</i></li>
        <li>法律文书收件人：<i>{{ counterclaimList.bsqr.shoujianMan || '无' }}</i></li>
        <li>法律文书收件人电话：<i>{{ counterclaimList.bsqr.shoujianPhone || '无' }}</i></li>
        <li>其它联系方式：<i>{{ counterclaimList.bsqr.otherFs || '无' }}</i></li>

      </ul>
      <ul v-if="counterclaimList.bsqr.dsrType=='2'" class="ul1">
        <li class="bolder-and-larger-text" style="width: 100%;">被申请人为{{
            dsrType(this.counterclaimList.bsqr.dsrType)
          }}
        </li>
        <li>名称：<i>{{ counterclaimList.bsqr.danweiName || '无' }}</i></li>
        <li>法定让代表人/负责人：<i>{{ counterclaimList.bsqr.dsrName || '无' }}</i></li>
        <li>地域：<i>{{ district(this.counterclaimList.bsqr.dsrDistrict) }}</i></li>
        <li>联系电话： <i>{{ counterclaimList.bsqr.dsrPhone || '无' }}</i></li>
        <li>邮箱：<i>{{ counterclaimList.bsqr.dsrEmail }}</i></li>
        <li>地址：<i>{{ counterclaimList.bsqr.dsrAddress || '无' }}</i></li>
        <li>邮政编码：<i>{{ counterclaimList.bsqr.postalCode || '无' }}</i></li>
        <li style="width: 100%;">仲裁文书送达地：<i>{{ counterclaimList.bsqr.wsSdAddress || '无' }}</i></li>
        <li>法律文书收件人：<i>{{ counterclaimList.bsqr.shoujianMan || '无' }}</i></li>
        <li>法律文书收件人电话：<i>{{ counterclaimList.bsqr.shoujianPhone || '无' }}</i></li>
        <li>其它联系方式：<i>{{ counterclaimList.bsqr.otherFs || '无' }}</i></li>
      </ul>
      <ul v-if="counterclaimList.bsqr.dailiIdOne!=null" class="ul1">
        <li style="width: 100%;">代理人一信息</li>
        <li>代理人类型：<i>{{ dlrType(this.counterclaimList.bsqr.dlrTypeOne) }}</i></li>
        <template v-if="counterclaimList.bsqr.dlrTypeOne==0">
          <li>姓名：<i>{{ counterclaimList.bsqr.dlrNameOne || '无' }}</i></li>
          <li>证件类型：<i>{{ zjType(counterclaimList.bsqr.dlrZjTypeOne) }}</i></li>
          <li>证件号码：<i>{{ counterclaimList.bsqr.dlrZjNumOne || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.bsqr.dlrPhoneOne || '无' }}</i></li>
          <li>邮编：<i>{{ counterclaimList.bsqr.dlrEmailOne || '无' }}</i></li>
        </template>
        <template v-if="counterclaimList.bsqr.dlrTypeOne==1 || counterclaimList.bsqr.dlrTypeOne==2">
          <li>姓名：<i>{{ counterclaimList.bsqr.dlrNameOne || '无' }}</i></li>
          <li>工作单位：<i>{{ counterclaimList.bsqr.danweiNameOne || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.bsqr.dlrPhoneOne || '无' }}</i></li>
          <li>执业证号：<i>{{ counterclaimList.bsqr.zhiyeNumOne || '无' }}</i></li>
        </template>
        <template v-if="counterclaimList.bsqr.dlrTypeOne==3">
          <li>姓名：<i>{{ counterclaimList.bsqr.dlrNameOne || '无' }}</i></li>
          <li>证件类型：<i>{{ zjType(counterclaimList.bsqr.dlrZjTypeOne) }}</i></li>
          <li>证件号码：<i>{{ counterclaimList.bsqr.dlrZjNumOne || '无' }}</i></li>
          <li>公司名称：<i>{{ counterclaimList.bsqr.gongsiNameOne || '无' }}</i></li>
          <li>职务：<i>{{ counterclaimList.bsqr.gsPostOne || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.bsqr.dlrPhoneOne || '无' }}</i></li>
          <li>邮编：<i>{{ counterclaimList.bsqr.dlrEmailOne || '无' }}</i></li>
        </template>
      </ul>
      <ul v-if="counterclaimList.bsqr.dailiIdTwo!=null" class="ul1">
        <li style="width: 100%;">代理人二信息</li>
        <li>代理人类型：<i>{{ dlrType(this.counterclaimList.bsqr.dlrTypeTwo) }}</i></li>
        <template v-if="counterclaimList.bsqr.dlrTypeTwo==0">
          <li>姓名：<i>{{ counterclaimList.bsqr.dlrNameTwo || '无' }}</i></li>
          <li>证件类型：<i>{{ zjType(counterclaimList.bsqr.dlrNameTwo) }}</i></li>
          <li>证件号码：<i>{{ counterclaimList.bsqr.dlrZjNumTwo || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.bsqr.dlrPhoneTwo || '无' }}</i></li>
          <li>邮编：<i>{{ counterclaimList.bsqr.dlrEmailTwo || '无' }}</i></li>
        </template>
        <template v-if="counterclaimList.bsqr.dlrTypeTwo==1 || counterclaimList.bsqr.dlrTypeTwo==2">
          <li>姓名：<i>{{ counterclaimList.bsqr.dlrNameTwo || '无' }}</i></li>
          <li>工作单位：<i>{{ counterclaimList.bsqr.danweiNameTwo || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.bsqr.dlrPhoneTwo || '无' }}</i></li>
          <li>执业证号：<i>{{ counterclaimList.bsqr.zhiyeNumTwo || '无' }}</i></li>
        </template>
        <template v-if="counterclaimList.bsqr.dlrTypeTwo==3">
          <li>姓名：<i>{{ counterclaimList.bsqr.dlrNameTwo || '无' }}</i></li>
          <li>证件类型：<i>{{ zjType(counterclaimList.bsqr.dlrZjTypeTwo) }}</i></li>
          <li>证件号码：<i>{{ counterclaimList.bsqr.dlrZjNumTwo || '无' }}</i></li>
          <li>公司名称：<i>{{ counterclaimList.bsqr.gongsiNameTwo || '无' }}</i></li>
          <li>职务：<i>{{ counterclaimList.bsqr.gsPostTwo || '无' }}</i></li>
          <li>联系电话：<i>{{ counterclaimList.bsqr.dlrPhoneTwo || '无' }}</i></li>
          <li>邮编：<i>{{ counterclaimList.bsqr.dlrEmailTwo || '无' }}</i></li>
        </template>
      </ul>
    </template>
    <template v-else>
      <span style="font-size: 14px;">
      (暂无反请求信息)
      </span>
    </template>

  </div>
</template>

<script>
export default {
  name: "counterclaim",
  props: {
    bananId: String,
    caseNum: String,
  },
  data() {
    return {
      counterclaimList: null,
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.counterclaim_init();
        }
      }
    },
    counterclaimList(newVal) {
      if (newVal) {
        const hasData = newVal.sqr && newVal.sqr.length > 0;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }

  },
  methods: {
    counterclaim_init() {
      this.axios.get('/api/handling/counterclaim', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.counterclaimList = data.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    zjType(type) {
      switch (type) {
        case '0':
          return '居民身份证';
        case '1':
          return '护照';
        case '2':
          return '军官证';
        default:
          return '无';
      }
    },
    dlrType(type) {
      switch (type) {
        case '0':
          return '公民';
        case '1':
          return '法律工作者';
        case '2':
          return '律师';
        case '3':
          return '员工';
        default:
          return '无';
      }
    },
    dsrType(type) {
      switch (type) {
        case '0':
          return '自然人';
        case '1':
          return '法人';
        case '2':
          return '非法人其他组织';
        default:
          return '无';
      }
    },
    gender(type) {
      switch (type) {
        case '1':
          return '男';
        case '2':
          return '女';
        default:
          return '无';
      }
    },
    district(type) {
      switch (type) {
        case '0':
          return '济南市';
        case '1':
          return '外地';
        case '2':
          return '港澳台';
        case '3':
          return '海外';
        default:
          return '无';
      }
    },
  },

}
</script>

<style scoped>
.bolder-and-larger-text {
  font-weight: bold;
  font-size: 1.2em;
}
</style>