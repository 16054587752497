<template>
  <div>
    <div v-if="isLoading" class="loading-container">
      <loading></loading>
    </div>
    <div v-if="!isLoading">
      <div class="ds">
        <div class="uflex">
          <h2><img alt="" src="../../../assets/image/home/qms12.png"></h2>
          <dl>
            <dd><i></i>{{ selectedyear }}年</dd>
            <dd><i></i>{{ selectedyear - 1 }}年</dd>
          </dl>
        </div>
        <div id="item1" class="covs"></div>
      </div>
      <div class="ds">
        <div class="uflex">
          <h2><img alt="" src="../../../assets/image/home/qms13.png"></h2>
          <dl>
            <dd><i></i>裁决结案</dd>
            <dd><i></i>调解结案</dd>
            <dd><i></i>撤案结案</dd>
            <dd><i></i>管辖异议成立</dd>
          </dl>
        </div>
        <div id="item2" class="covs"></div>
      </div>
      <div class="ds">
        <div class="uflex">

          <h2><img alt="" src="../../../assets/image/home/qms14.png"></h2>

          <dl>
            <dd><i></i>本年度标的额（月）</dd>
            <dd><i></i>上年度标的额（月）</dd>
          </dl>
        </div>
        <div id="item3" class="covs"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "dynamicEcharts",
  components: {},
  props: {
    selectedyear: Number
  },
  data() {
    return {
      nowYear: this.selectedyear,
      lastYear: this.selectedyear - 1,
      item1: {
        LastYear: null,
        NowYear: null,
      },
      item2: {
        caijueData: [],
        tiaojieData: [],
        cheanData: [],
        guanxiaData: [],
        zongData: [],
      },
      item3: {
        now: null,
        last: null,
      },
      month: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      isLoading: true, // 加载状态标志
    }
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    selectedyear(newYear, oldYear) {
      this.nowYear = newYear;
      this.lastYear = newYear - 1;
      this.initialize();
    }
  },
  //方法集合
  methods: {
    init() {
      this.isLoading = false; // 数据加载完成
      this.$nextTick(() => {
        // 确保 DOM 更新完成后再初始化图表
        this.initChart();
      });
    },
    initialize() {
      this.isLoading = true; // 开始加载数据
      this.shouanshu();
      this.jieanshu();
      this.biaodie();

    },
    // 数据加载完成后的检查
    checkDataAndInit() {
      if (this.item1.LastYear && this.item1.NowYear &&
          this.item2.caijueData &&
          this.item2.tiaojieData &&
          this.item2.cheanData &&
          this.item2.guanxiaData &&
          this.item3.now && this.item3.last) {
        this.init();

      }
    },
    shouanshu() {
      this.axios.get('/pingZhan/report/dynamicEcharts/shouan', {
        params: {
          currentYear: this.selectedyear
        }
      }).then((response) => {
        //console.log(response);
        let data = response.data;
        if (data.code == 200) {
          let arrLastYear = [];
          let arr = [];
          data.data.shouanshuLastYear.forEach(item => {
            arrLastYear.push(item.num)
          })
          data.data.shouanshu.forEach(item => {
            arr.push(item.num)
          })
          let itemLastYear = {"name": data.data.shouanshuLastYear[0].y, "values": arrLastYear};
          let item = {"name": data.data.shouanshu[0].y, "values": arr};
          this.item1.LastYear = (itemLastYear)
          this.item1.NowYear = (item)
        } else {
          console.log('数据获取失败');
        }
        this.checkDataAndInit();
      }).catch((error) => {
        console.error('发生错误:', error);
      });
    },
    jieanshu() {
      this.axios.get('/pingZhan/report/dynamicEcharts/jiean', {
        params: {
          currentYear: this.selectedyear
        }
      }).then((response) => {
        let data = response.data;
        //console.log(data);
        if (data.code == 200) {
          data.data.JieAnShu.forEach(item => {
            this.item2.caijueData.push(item.caijue);
            this.item2.tiaojieData.push(item.tiaojie);
            this.item2.cheanData.push(item.chean);
            this.item2.guanxiaData.push(item.guanxia);
            this.item2.zongData.push(item.caijue + item.tiaojie + item.chean + item.guanxia)
          });

        } else {
          console.log('数据获取失败');
        }
        this.checkDataAndInit();
      }).catch((error) => {
        console.error('发生错误:', error);
      });
    },
    biaodie() {
      this.axios.get('/pingZhan/report/dynamicEcharts/biaodie', {
        params: {
          currentYear: this.selectedyear
        }
      }).then((response) => {
        let data = response.data;
        //console.log(data);
        if (data.code == 200) {
          let now = [];
          let last = [];
          this.month.forEach(item => {
            now.push(data.data.Now[0][this.nowYear][item]);
            last.push(data.data.Now[0][this.lastYear][item]);
          })
          this.item3.now = now;
          this.item3.last = last;
        } else {
          console.log('数据获取失败');
        }
        this.checkDataAndInit();
      }).catch((error) => {
        console.error('发生错误:', error);
      });
    },
    openCenter(item, caseType) {
      index.showCenter(true);
      this.$parent.$refs.centerComponent.opens(item, caseType);

    },
    initChart() {
      let that = this;
      var chartDom1 = document.getElementById('item1');
      var myChart = echarts.init(chartDom1);
      var option;

      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          left: "8%",
          right: "3%",
          top: "18%",
          bottom: "16%",
        },
        xAxis: [{
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#fff",
          },
        }],
        yAxis: [{
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#656981",
          },
        },],
        series: [{
          name: that.item1.NowYear.name + '年',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value;
            }
          },
          barWidth: '30%',
          showBackground: true,
          label: {
            show: true,
            position: 'top',
            color: "#57b0f6",
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#7bc5fd'
            },
              {
                offset: 1,
                color: '#3476c5'
              }
            ])
          },
          data: that.item1.NowYear.values
        },
          {
            name: that.item1.LastYear.name + '年',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            barWidth: '30%',
            showBackground: true,
            label: {
              show: true,
              position: 'top',
              color: "#5edad1",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#60dcd2'
              },
                {
                  offset: 1,
                  color: '#40bdb4'
                }
              ])
            },
            data: that.item1.LastYear.values
          }
        ]
      };

      option && myChart.setOption(option);


      var chartDom2 = document.getElementById('item2');
      var myChart2 = echarts.init(chartDom2);
      var option2;

      option2 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          left: "8%",
          right: "3%",
          top: "18%",
          bottom: "16%",
        },
        xAxis: [{
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#fff",
          },
        }],
        yAxis: [{
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#656981",
          },
        }],
        series: [
          {
            name: '裁决结案',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            barWidth: '30%',
            showBackground: true,
            label: {
              show: false,
              position: 'top',
              color: "#57b0f6",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#7bc5fd'
              },
                {
                  offset: 1,
                  color: '#3476c5'
                }
              ])
            },
            data: that.item2.caijueData
          },
          {
            name: '调解结案',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            barWidth: '30%',
            showBackground: true,
            label: {
              show: false,
              position: 'top',
              color: "#5edad1",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#60dcd2'
              },
                {
                  offset: 1,
                  color: '#40bdb4'
                }
              ])
            },
            data: that.item2.tiaojieData
          },
          {
            name: '撤案结案',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            barWidth: '30%',
            showBackground: true,
            label: {
              show: false,
              position: 'top',
              color: "#d97474",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#dedd80'
              },
                {
                  offset: 1,
                  color: '#925b5b'
                }
              ])
            },
            data: that.item2.cheanData
          },
          {
            name: '管辖异议成立',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            barWidth: '30%',
            showBackground: true,
            label: {
              show: false,
              position: 'top',
              color: "#c2d96f",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#8f78d0'
              },
                {
                  offset: 1,
                  color: '#5f6742'
                }
              ])
            },
            data: that.item2.guanxiaData
          },
          {
            name: '总结案数',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            barWidth: '30%',
            showBackground: true,
            label: {
              show: false,
              position: 'top',
              color: "#c2d96f",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#fe0060'
              },
                {
                  offset: 1,
                  color: '#5f6742'
                }
              ])
            },
            data: that.item2.zongData
          }
        ]
      };


      option2 && myChart2.setOption(option2);


      var chartDom3 = document.getElementById('item3');
      var myChart3 = echarts.init(chartDom3);
      var option3;

      option3 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          left: "8%",
          right: "3%",
          top: "22%",
          bottom: "16%",
        },
        xAxis: [{
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#fff",
          },
          // boundaryGap: false,
        }],
        yAxis: [{
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },

          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            margin: 5,
            color: "#656981",
          },
        },],
        series: [{
          name: that.nowYear,
          type: 'line',
          itemStyle: {
            color: '#4da6f0',  // 更新这里
          },
          tooltip: {
            valueFormatter: function (value) {
              return value;
            }
          },
          label: {
            show: false,
            position: 'top',
            color: "#ff8c8c",
          },
          data: that.item3.now,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(255, 140, 140, 0.5)'  // 修改颜色格式为 rgba
              },
              {
                offset: 1,
                color: 'rgba(255, 255, 255, 0)'
              }
            ])
          },
        },
          {
            name: that.nowYear - 1,
            type: 'line',
            itemStyle: {
              color: '#ce7683',  // 更新这里
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            showBackground: true,
            label: {
              show: false,
              position: 'top',
              color: "#74c2ff",
            },
            data: that.item3.last,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(116, 194, 255, 0.5)'  // 修改颜色格式为 rgba
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)'
                }
              ])
            },
          }
        ]
      };

      option3 && myChart3.setOption(option3);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.initialize();
  }
};
</script>

<style scoped>
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
