<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>组庭信息</h3>
    </div>
    <el-table
        :cell-style="{
      background:'#fff',
      color: '#7883c5'}"
        :header-cell-style="{
      background:'#F5F7FA',
      color: '#3d4053'}"
        border
        :data="tableData"
        stripe
        style="width: 100%">
      <el-table-column
          align="center"
          label="仲裁庭"
          prop="zct"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="办案秘书"
          prop="bams"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="起草审批时间"
          prop="qcspTime"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="组庭时间"
          prop="ztTime"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "group",
  props: {
    bananId: String,
    caseNum: String,
  },
  data() {
    return {
      groupList: null,
      tableData: [],
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.group_init();
        }
      }
    },
    groupList(newVal) {
      if (newVal) {
        const hasData = newVal && newVal.length > 0;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    group_init() {
      this.axios.get('/api/handling/group', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.groupList = data.data.zutingList;
          this.groupList.forEach((item) => {
            let params = {};
            if (item.type == 0) {
              params = {
                "zct": item.shouxiName + '(首席)、' + item.zcyuanName1 + '、' + item.zcyuanName2
              }
            } else {
              params = {
                "zct": item.shouxiName + '(独任)'
              }
            }
            params.qcspTime = moment(item.create_date).format('YYYY-MM-DD HH:mm:ss') || '无';
            params.ztTime = moment(item.zuting_date).format('YYYY-MM-DD') || '无';
            params.bams = data.data.bams.name || '无';
            this.tableData.push(params);
          })
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

</style>


