<template>

</template>

<script>
import Vue from "vue";

export default {
  name: "sing",
  data() {
    return {
     /* tokens: '',
      principalId: ''*/
    };
  },
  mounted() {
    const tokens = this.$route.query.tokens;
    /*  const principalId = this.$route.query.principalId;
      const loginName = this.$route.query.loginName;*/
   // alert(tokens);
    //alert(principalId);
    this.ontoken(tokens);
  },
  methods: {

    ontoken(tokens) {
      this.axios.post('/singleLogin', {
        tokens: tokens,
        /*       principalId: principalId,
               loginName: loginName*/
      }).then(response => {
        // console.log("ddddd")
        let data =response.data;
        // console.log(response)
        // 提取 JWT 令牌
        const token = response.headers['authorization'] || response.headers['Authorization'];
        //console.log(token);
        if (token) {
        //  alert(token);
          // 存储 JWT 令牌
          localStorage.setItem('jwt_token', token);
        }
        Vue.prototype.$isTokenExpired = false;
        this.$router.push('/home');
      }).catch(error => {
        //console.log(error.response.status);
        this.$message.error("登录失败!");
        localStorage.removeItem('jwt_token');
        //router.push('/login')
      });
    }
  },
  created() {
    localStorage.removeItem('jwt_token');
    // this.tokens = this.$route.params.tokens;
    // this.principalId = this.$route.params.principalId;

  },
}
</script>

<style lang="css" scoped >
@import "@/assets/css/loginCss/style.css";
@import "@/assets/css/loginCss/slicy.css";
</style>