<template>
  <div id="page2">
    <div style="width:100%">
      <div style="width:80%; margin-left :auto ; margin-right:auto;">
        <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
                  :data="jdtzsd" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
                  stripe
                  style="width: 100%">
          <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
          <el-table-column align="center" label="送达次数" prop="sendNum" width="50"></el-table-column>
          <el-table-column align="center" label="送达类型" prop="sdTypeName"></el-table-column>
          <el-table-column align="center" label="被送达人" prop="dsrFlag"></el-table-column>
          <el-table-column align="center" label="被送达人名称" prop="dsrName"></el-table-column>
          <el-table-column align="center" label="寄出时间" prop="beginTime"></el-table-column>
          <el-table-column align="center" label="送达形式" prop="sdxsName"></el-table-column>
          <el-table-column align="center" label="单号" prop="danhao"></el-table-column>
          <el-table-column align="center" label="送达结果" prop="sdjg"></el-table-column>
          <el-table-column align="center" label="送达时间" prop="endTime"></el-table-column>
          <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
        </el-table>

      </div>
    </div>


  </div>


</template>

<script>


import moment from "moment/moment";

export default {
  name: "sendResultsList",
  props: {
    banan_Id: '',
    checkupFlowid: '',
    sd_type: '',
  },
  components: {},

  data() {
    return {
      jdtzsd: [],
    }
  },
  //方法集合
  methods: {

    init() {
      this.startLoading();
      // alert("=="+this.lhkhWod);
      this.axios.get('/api/handling/sendResult', {
        params: {
          id: this.banan_Id,
          sd_type: this.sd_type,
        }
      }).then(response => {
        this.Loading.close();
        let data = response.data

        if (data.code == 200) {

          this.tableData(data.data.jdtzsd, "jdtzsd");

        } else {
          this.remind(data.message);
          this.$emit("fatherMethod", "");
        }
      }).catch(error => {
        this.remind('发生错误:', error);
        this.Loading.close();
        this.$emit("fatherMethod", "");
        console.error('发生错误:', error);
      })

    },
    tableData(data, dataname) {
      this.CourtList = data;
      this.CourtList.forEach((item) => {
        let params = {};
        params.xh = item.xh || '无';
        params.sendNum = item.sendNum || '无';
        params.sdTypeName = item.sdTypeName || '无';
        params.dsrFlag = this.bsdr(item.dsrFlag) || '无';
        params.dsrName = item.dsrType == "0" ? item.dsrName : item.danweiName || '无';
        params.beginTime = item.beginTime != null ? moment(item.beginTime).format('YYYY-MM-DD') : '' || '无'
        params.sdxsName = item.sdxsName || '无';
        params.danhao = item.danhao || '无';
        params.sdjg = item.sdxs == "0" ? this.yjsd(item.sendEnd) : item.sdxs == "1" ? this.dmsd(item.sendEnd) : '无' || '无';
        params.endTime = item.endTime != null ? moment(item.endTime).format('YYYY-MM-DD') : '' || '无'
        params.remarks = item.remarks || '无';
        if (dataname == "jdtzsd") {
          this.jdtzsd.push(params);
        }

      })
    },
    dmsd(type) {
      switch (type) {
        case '0':
          return '签收';
        case '1':
          return '拒收';
        case '2':
          return '留置';
        default:
          return '无';
      }
    },
    yjsd(type) {
      switch (type) {
        case '0':
          return '退件';
        case '1':
          return '妥投';
        case '2':
          return '其他';
        default:
          return '无';
      }
    },
    bsdr(type) {
      switch (type) {
        case '0':
          return '申请人';
        case '1':
          return '被申请人';
        default:
          return '无';
      }
    },
    remind(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'warning'
      });
    },
    startLoading() {
      this.Loading = this.$loading({
        lock: false,
        text: '正在加载中...',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.6)'//遮罩背景色

      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    //alert(this.checkupFlowid); alert(this.banan_Id); alert(this.sd_type);
    this.init()
  }
}

</script>

<style scoped>
.bolder-and-larger-text {
  font-weight: bold;
  font-size: 1.2em;
}
</style>