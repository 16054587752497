<template>
  <div class="item">
    <div class="he">
      <h3>奖惩情况</h3>
    </div>
    <el-table
        :data="tableData"
        stripe
        style="width: 100%">
      <el-table-column
          prop="xh"
          label="序号"
          width="50">
      </el-table-column>
      <el-table-column
          prop="type"
          label="奖惩类型">
      </el-table-column>
      <el-table-column
          prop="explain"
          label="情况说明">
      </el-table-column>
      <el-table-column
          prop="time"
          label="奖惩时间">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "rewards",
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    initRewards() {
      this.axios.get('/pingZhan/inquire/zcyRewardsById', {
        params: {
          id: this.$route.params.id
        }
      }).then(response => {
        const data = response.data;
        if (data.code == 200) {
          //data中取到的数据
          const originalData = data.data;
          //按照tableData格式转换为新数组，如果不存在则默认“暂无”
          const newData = originalData.map(item => {
            return {
              xh: item.xh || "暂无",
              type: item.jc_type === 0 ? "通报情况" : item.jc_type === 1 ? "奖励情况" : item.jc_type === 2 ? "投诉情况" : "暂无",
              explain: item.px_content || "暂无",
              time: item.jc_data || "暂无",
            };
          });
          this.tableData = newData;
        } else {
          this.$message.error("获取奖惩情况失败")
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.initRewards();
  }
}
</script>

<style scoped>

</style>