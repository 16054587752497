import Vue from 'vue'
import ElementUI, {Message} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router' //路由
import store from './store' //vuex管理器
import axios from 'axios';
import loaders from './components/Util/Loaders.vue';
import loading from './components/Util/loading.vue';
import NProgress from 'nprogress'; //进度条插件
import 'nprogress/nprogress.css';
import {decrypt, encrypt} from '@/assets/js/global/cryptoUtils';


Vue.config.productionTip = false
Vue.use(ElementUI)

//全局加密解密
Vue.prototype.$crypto = {
    encrypt,
    decrypt
};

// 设置Axios的全局默认值
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.withCredentials = true;
Vue.prototype.axios = axios;
Vue.prototype.$ajxxURL = process.env.VUE_APP_API_ajxx_URL;
//全局组件
Vue.component('loaders', loaders);
Vue.component('loading', loading);

//全局变量
Vue.prototype.$isTokenExpired = false;

// 全局日期格式化过滤器
Vue.filter('formatDate', function (value) {
    if (value) {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    return '';
});

// 请求拦截器
axios.interceptors.request.use(
    config => {
        NProgress.start(); // 启动进度条
        const token = localStorage.getItem('jwt_token');
        //alert("token"+token);
        //console.log("请求拦截器"+token);
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    error => {
        NProgress.done(); // 如果出错，结束进度条
        return Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    response => {
        NProgress.done(); // 响应成功，结束进度条
        const newToken = response.headers['authorization'] || response.headers['Authorization'];
        // console.log("响应拦截器"+newToken);
        if (newToken) {
            // 如果有新的 Token，则更新本地存储的 Token
            localStorage.setItem('jwt_token', newToken);
            //localStorage.setItem('jwt_token', newToken.split(' ')[1]); // 去掉 "Bearer " 并保存新 Token
        }

        return response;
    },
    error => {
        NProgress.done(); // 如果出错，结束进度条
        if (error.response && error.response.status === 401) {
            // 检查错误消息是否指示 Token 过期
            if (error.response.data.error === 'Token expired' && !Vue.prototype.$isTokenExpired) {
                Vue.prototype.$isTokenExpired = true;
                Message.error('Token有效期已过，请重新登录!');
                localStorage.removeItem('jwt_token');
                if (router.currentRoute.path !== '/login') {
                    router.push('/login').catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            throw err;
                        }
                    });
                }
            }
            if (error.response.data.error === 'Unauthorized' && !Vue.prototype.$isTokenExpired) {
                Vue.prototype.$isTokenExpired = true;
                Message.error('Token验证异常，请重新登录!');
                localStorage.removeItem('jwt_token');
                if (router.currentRoute.path !== '/login') {
                    router.push('/login').catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            throw err;
                        }
                    });
                }
            }
            if (error.response.data.error === 'quit' && !Vue.prototype.$isTokenExpired) {
                Vue.prototype.$isTokenExpired = true;
                Message.error('已退出，请重新登录!');
                localStorage.removeItem('jwt_token');
                if (router.currentRoute.path !== '/login') {
                    router.push('/login').catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            throw err;
                        }
                    });
                }
            }

        } else if (error.response && error.response.status === 403) {
            Message.error('请重新登录!');
            router.push('/login').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });
        }
        return Promise.reject(error);
    }
);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


