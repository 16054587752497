<template>
  <div class="mcpg mcpg1">
    <el-container>
      <el-aside style="background-color: #ffffff; height: 500px; overflow-y: auto;">
        <!-- 全部展开、全部收起和搜索按钮 -->
        <el-autocomplete
            v-model="searchQuery"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            style="width: 100%;"
            value-key="label"
            @select="handleSelect"
        ></el-autocomplete>
        <el-button size="mini" @click="expandAll">全部展开</el-button>
        <el-button size="mini" @click="collapseAll">全部收起</el-button>
        <!-- 树状控件 -->
        <el-tree
            :key="treeKey"
            ref="dataTree"
            :data="treeData"
            :default-expanded-keys="defaultExpandedKeys"
            node-key="id"
            @node-click="handleNodeClick"
        >
          <template #default="{ node, data }">
            <span class="custom-tree-node">
              <!-- 注意，图片路径可能要根据实际的项目路径进行调整 -->
              <img :src="getNodeIcon(node)" style="width: 18px; height: 18px;"/>
              <span>{{ node.label }}</span>
            </span>
          </template>
        </el-tree>
      </el-aside>
      <el-container style="height: 500px; overflow-y: auto;">
        <el-header style="height: 30px;background-color: rgba(235,235,235,0.49);">
          <div style="margin-top: 10px;">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                  v-for="(item, index) in selectedNodePath"
                  :key="index"
              >
                {{ item.label }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>

        </el-header>
        <el-main style="background-color: rgba(235,235,235,0.49);">

          <div v-if="selectedNode">
            <div v-if="selectedNode.fileType === 'word'">

            </div>
            <div v-else-if="selectedNode.fileType === 'pdf'">

            </div>
            <div v-else-if="selectedNode.fileType === 'excel'">

            </div>
            <div v-else-if="selectedNode.children && selectedNode.children.every(child => child.fileType === 'image')">
              <div style="display: flex; flex-wrap: wrap; justify-content: start;">
                <div
                    v-for="(child, index) in selectedNode?.children"
                    :key="index"
                    style="flex-basis: 200px; height: 225px; margin: 30px; text-align: center;"
                >
                  <el-image
                      :preview-src-list="selectedNode?.children.map(child => child.url)"
                      :src="child.url"
                      style="width: 100%; height: 200px;"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  {{ child.label }}
                </div>
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无内容"></el-empty>
            </div>
          </div>
          <div v-else>
            请选择目录
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {Button, Col, Input, Row, Tree} from "element-ui";
import treeData from "./data"; //节点数据

export default {
  name: "handling_record",
  data() {
    return {
      selectedNode: null,
      selectedNodePath: [],
      searchQuery: "",
      treeData: treeData,
      defaultExpandedKeys: [],
      treeKey: 0,
      showLargeImage: false,
    };
  },
  methods: {
    handleNodeClick(data, node = null) {
      let tempNode = node ? {...node} : null;

      const path = [];
      while (tempNode && tempNode.data) {
        path.unshift(tempNode.data);
        tempNode = tempNode.parent;
      }

      path.shift(); // 移除路径列表中的第一个元素

      this.selectedNodePath = path;

      if (data.fileType) {
        this.selectedNode = {label: data.label, children: [data]};
      } else {
        this.selectedNode = data;
      }
    },
    getNodeIcon(node) {
      if (node.level > 2) {
        // 如果节点深度大于2，显示文件图标
        return require('@/assets/image/case_record/file.png')
      } else if (node.childNodes.length === 0) {
        // 如果这个节点是叶节点没子节点（使用 kong 图标）
        return require('@/assets/image/case_record/kong.png');
      } else if (node.expanded) {
        // 如果这个节点有子节点并且正在展开（使用 kai 图标）
        return require('@/assets/image/case_record/kai.png');
      } else {
        // 如果这个节点有子节点并且没有展开（使用 zong 图标）
        return require('@/assets/image/case_record/zong.png');
      }
    },
    expandAll() {
      this.defaultExpandedKeys = this.getKeysFromData(this.treeData);
      this.treeKey++;
    },
    collapseAll() {
      this.defaultExpandedKeys = [];
      this.treeKey++;
    },
    getKeysFromData(data, keys = []) {
      data.forEach(node => {
        keys.push(node.id);
        if (node.children) {
          this.getKeysFromData(node.children, keys);
        }
      });
      return keys;
    },
    querySearch(queryString, cb) {
      const result = this.findNodes(this.treeData, queryString);
      cb(result);
    },
    handleSelect(item) {
      this.selectedNode = item;
      let nodeInTree = this.$refs.dataTree.getNode(this.selectedNode.id);

      let outerNodeInTree = nodeInTree;

      while (outerNodeInTree) {
        outerNodeInTree.expand();
        outerNodeInTree = outerNodeInTree.parent;
      }

      this.handleNodeClick(this.selectedNode, nodeInTree);
    },
    findNodes(nodes, query) {
      const searchResults = [];
      for (const node of nodes) {
        if (node.label.toLowerCase().includes(query.toLowerCase())) {
          searchResults.push(node);
        }
        if (node.children) {
          const childResults = this.findNodes(node.children, query)
          searchResults.push(...childResults)
        }
      }
      return searchResults
    },
  },
  components: {
    [Tree.name]: Tree,
    [Button.name]: Button,
    [Input.name]: Input,
    [Row.name]: Row,
    [Col.name]: Col
  }
};
</script>

<style lang="scss">
/*
修改树节点前三角图标
*/
.el-tree .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.el-tree .el-icon-caret-right:before {
  content: "\e723";
}

.el-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
  content: "\e722";
}
</style>