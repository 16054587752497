<template>
  <div class="item" >
    <div class="he">
      <h3>学习经历</h3>
    </div>
    <el-table
        :data="tableData"
        stripe
        style="width: 100%">
      <el-table-column
          prop="xh"
          label="序号"
          width="50">
      </el-table-column>
      <el-table-column
          prop="school"
          label="学校">
      </el-table-column>
      <el-table-column
          prop="major"
          label="专业">
      </el-table-column>
      <el-table-column
          prop="education"
          label="学历">
      </el-table-column>
      <el-table-column
          prop="degree"
          label="学位">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "study",
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    initStudy() {
      this.axios.get('/pingZhan/inquire/zcyStudyById', {
        params: {
          id: this.$route.params.id
        }
      }).then(response => {
        const data = response.data;
        if (data.code == 200) {
          const educationMap = {
            '0': '大专',
            '1': '本科',
            '2': '硕士研究生',
            '3': '博士'
          };
          //data中取到的数据
          const originalData = data.data;
          //按照tableData格式转换为新数组，如果不存在则默认“暂无”
          const newData = originalData.map(item => {
            return {
              xh: item.xh || "暂无",
              school: item.school_name || "暂无",
              major: item.specialty || "暂无",
              education: item.education in educationMap ? educationMap[item.education] : "暂无",
              degree: item.degreee || "暂无"
            };
          });
          this.tableData = newData;
        } else {
          this.$message.error("获取学习经历失败")
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
      this.initStudy();
  }

}
</script>

<style scoped>

</style>