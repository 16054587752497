<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>中止</h3>
    </div>
    <ul v-if="suspendList && suspendList.suspend.length>0" class="ul1">
      <template v-for="(item,index) in suspendList.suspend">
        <li v-if="item.proc_flag==0" class="bolder-and-larger-text" style="width: 100%;">(未结束)</li>
        <li v-if="item.proc_flag==1" class="bolder-and-larger-text" style="width: 100%;">(已结束)</li>
        <li>中止开始时间：<i>{{ formatTime(item.zhongzhi_beginDate) }}</i></li>
        <li style="width: 100%;">中止原因：<i>{{ item.zz_reason || '无' }}</i></li>
        <li>中止结束时间：<i>{{ formatTime(item.zhongzhi_endDate) }}</i></li>
        <li style="width: 100%;">恢复原因：<i>{{ item.hf_reason || '无' }}</i></li>
        <li>处长决定：<i>{{ czDecision(item.cz_decision) }}</i></li>
        <li>附件：<i>{{ item.fujian_id != '' ? '已上传' : '暂无附件' }}</i></li>
        <li>恢复通知书：<i>{{ item.hf_notice != '' ? '已上传' : '暂无附件' }}</i></li>
      </template>
    </ul>
    <ul v-else class="ul1">
      <li>(暂无中止信息)</li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "suspend",
  props: {
    bananId: String,
    caseNum: String,
  },
  data() {
    return {
      suspendList: null,
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.suspend_init();
        }
      }
    },
    suspendList(newVal) {
      if (newVal) {
        const hasData = newVal.suspend && newVal.suspend.length > 0;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    suspend_init() {
      this.axios.get('/api/handling/suspend', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.suspendList = data.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    formatTime(date) {
      return date ? moment(date).format('YYYY-MM-DD') : '无';
    },
    czDecision(type) {
      switch (type) {
        case '0':
          return '同意';
        case '1':
          return '不予批准';
        default:
          return '暂无决定';
      }
    },
  }
}
</script>

<style scoped>
.bolder-and-larger-text {
  font-weight: bold;
  font-size: 1.2em;
}
</style>