import { render, staticRenderFns } from "./namePop.vue?vue&type=template&id=eda37d0c&scoped=true"
import script from "./namePop.vue?vue&type=script&lang=js"
export * from "./namePop.vue?vue&type=script&lang=js"
import style0 from "./namePop.vue?vue&type=style&index=0&id=eda37d0c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eda37d0c",
  null
  
)

export default component.exports