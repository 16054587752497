<template>
  <div class="mcpg">
    <div class="mcbd">
      <div class="mclt">
        <component
            :is="component.name"
            v-for="(component, index) in componentConfig"
            :id="component.section.toLowerCase()"
            :key="component.name"
            :bananId="id"
            :caseNum="caseNum"
            @data-exists="updateDataStatus">
        </component>
      </div>
      <div class="mcrt">
        <ul>
          <li v-for="component in componentConfig" :key="component.name" :class="{ on: section === component.section }">
            <a @click="scrollToSection(component.section)">
              {{ component.number }}.{{ component.title }}
              <span v-if="!component.hasData" class="no-data">（无）</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Authenticate from "@/views/case/details/components/authenticate.vue";
import Basic from "@/views/case/details/components/basic.vue";
import Change from "@/views/case/details/components/change.vue";
import CheckAndApprove from "@/views/case/details/components/CheckAndApprove.vue";
import Closing from "@/views/case/details/components/Closing.vue";
import Counterclaim from "@/views/case/details/components/counterclaim.vue";
import CounterclaimChange from "@/views/case/details/components/counterclaimChange.vue";
import Court from "@/views/case/details/components/Court.vue";
import Delivery from "@/views/case/details/components/delivery.vue";
import Execute from "@/views/case/details/components/execute.vue";
import Group from "@/views/case/details/components/group.vue";
import Postpone from "@/views/case/details/components/postpone.vue";
import Preserve from "@/views/case/details/components/preserve.vue";
import Proposer from "@/views/case/details/components/proposer.vue";
import Repeal from "@/views/case/details/components/repeal.vue";
import Respondent from "@/views/case/details/components/respondent.vue";
import Suspend from "@/views/case/details/components/suspend.vue";

import {componentsConfig} from "./componentsConfig";

export default {
  name: "handling_details",
  components: {
    Authenticate,
    Basic,
    Change,
    CheckAndApprove,
    Closing,
    Counterclaim,
    CounterclaimChange,
    Court,
    Delivery,
    Execute,
    Group,
    Postpone,
    Preserve,
    Proposer,
    Repeal,
    Respondent,
    Suspend,
  },
  props: {
    id: String,
    caseNum: String,
  },
  data() {
    return {
      section: 'jiben',
      componentConfig: componentsConfig,
      componentDataStatus: {},
    }
  },
  methods: {
    scrollToSection(sectionId) {
      this.section = sectionId
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    },
    updateDataStatus({componentName, dataExists}) {
      const lowerCaseComponentName = componentName.toLowerCase();
      const componentConfigItem = this.componentConfig.find(c => c.name.toLowerCase() === lowerCaseComponentName);
      if (componentConfigItem) {
        this.$set(componentConfigItem, 'hasData', dataExists);
      }
    }
  },
}
</script>

<style scoped>
@import "@/assets/css/zcy/style.css";
@import "@/assets/css/zcy/slicy.css";

a {
  cursor: pointer;
}

.no-data {
  color: red;
}
</style>