<template>
  <div class="layout">
    <div class="fkozhipin uufkozhipin">
      <h1 class="txth1">仲裁信息化监督考评系统</h1>
      <div class="login">
        <h2>欢迎登录</h2>
        <form v-on:submit.prevent="onSubmit">
          <div class="bor">
            <div class="icon">
              <img alt="" src="../assets/image/loginImages/images/lico2.png">
            </div>
            <input class="txt" placeholder="请输入用户名" type="text" v-model="username">
          </div>
          <div class="bor">
            <div class="icon">
              <img alt="" src="../assets/image/loginImages/images/lico3.png">
            </div>
            <input class="txt" placeholder="请输入密码" type="password" v-model="password">
          </div>
          <!-- <div class="clfx bor">
      <div class="bor">
          <div class="icon">
              <img src="images/lico4.png" alt="">
          </div>
          <input type="text" class="txt" placeholder="请输验证码">
      </div>
      <div class="pic">
          <img src="images/lpic1.png" alt="">
      </div>
  </div> -->
          <input class="btn" type="submit" value="登录">
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Login",
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    onSubmit() {
      // 验证用户名和密码是否为空
      if (!this.username || !this.password) {
        this.$message.error('用户名和密码不能为空！');
        return;
      }

      this.axios.post('/singleLogin', {
        username: this.username,
        password: this.password
      }).then(response => {
        //console.log(response)
        // 提取 JWT 令牌
        const token = response.headers['authorization'] || response.headers['Authorization'];
        //console.log(token);
        if (token) {
          // 存储 JWT 令牌
          localStorage.setItem('jwt_token', token);
        }
        Vue.prototype.$isTokenExpired = false;
        this.$router.push('/home');
      }).catch(error => {
        //console.log(error);
        localStorage.removeItem('jwt_token');
        this.$message.error('登录失败，请检查用户名密码是否正确！');
      });
    }
  },
  created() {
    localStorage.removeItem('jwt_token');
  },
}
</script>

<style lang="css" scoped>
@import "@/assets/css/loginCss/style.css";
@import "@/assets/css/loginCss/slicy.css";
</style>