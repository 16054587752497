<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>反请求变更请求信息</h3>
    </div>
    <ul v-if="counterclaimChangeList && 'updateZcask' in counterclaimChangeList && 'caseZcAsk' in counterclaimChangeList"
        class="ul1"><!---->
      <li>申请人：<i>{{ counterclaimChangeList.bananInfo.sqrNames || '无' }}</i></li>
      <li>被申请人：<i>{{ counterclaimChangeList.bananInfo.bsqrNames || '无' }}</i></li>
      <li>案由：<i>{{ counterclaimChangeList.bananInfo.anyouName || '无' }}</i></li>
      <li style="width: 100%;">最新仲裁请求一:<i>{{ counterclaimChangeList.caseInfo.zc_ask_one || '无' }}</i></li>
      <li style="width: 100%;">
        最新仲裁请求二:<i>{{
          counterclaimChangeList.caseInfo.zc_ask_two != ' ' ? counterclaimChangeList.caseInfo.zc_ask_two : '暂无'
        }}</i></li>
      <li style="width: 100%;">原仲裁请求：<i>{{ counterclaimChangeList.updateZcask.old_zcask || '无' }}</i></li>
      <!--      <li>申请人受理通知书：<i></i></li>
            <li>被申请人受理通知书：<i></i></li>
            <li>申请人送达回证：<i></i></li>
            <li>被申请人送达回证：<i></i></li>-->
      <li>变更请求发起时间：<i>{{ new Date(counterclaimChangeList.updateZcask.create_date).toLocaleString() }}</i></li>
    </ul>
    <ul v-else class="ul1">
      <li>(暂无反请求变更请求信息)</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "counterclaimChange",
  props: {
    bananId: String,
    caseNum: String
  },
  data() {
    return {
      counterclaimChangeList: null,
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.counterclaimChange_init();
        }
      }
    },
    counterclaimChangeList(newVal) {
      if (newVal) {
        const hasData = newVal && 'updateZcask' in newVal && 'caseZcAsk' in newVal;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }

  },
  methods: {
    counterclaimChange_init() {
      this.axios.get('/api/handling/change', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.counterclaimChangeList = data.data;
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

</style>


