<template>
  <div class="mcpg">
    <div class="mcbd">
      <div class="mclt" style="width: 100%">
        <div class="item">
          <div class="he">
            <h3>案件基本情况</h3>
          </div>
          <ul v-if="bananInfo && checkupFlow " class="ul1">
            <li>申请人：<i> {{ bananInfo.sqrNames || '无' }}</i></li>
            <li>鉴定申请人：<i>{{ jdsqr || '无' }}</i></li>
            <li>被申请人：<i>{{ bananInfo.bsqrNames || '无' }}</i></li>
            <li style="width: 100%;">当事人申请鉴定事项：<i>{{ checkupFlow.dsr_jianding_content || '无' }}</i></li>
            <li style="width: 100%;">鉴定申请书附件：<i>
              <table v-for="(item,index) in AccessoryInfo" style="border: 1px solid #ccc;">
                <tr>
                  <td>
                    {{ item.fileName }}
                  </td>
                </tr>
              </table>
            </i></li>
            <li>是否准许鉴定：<i> {{ isJianding || '无' }} </i></li>
            <li>鉴定类型：<i>{{ jigouType }} </i></li>
          </ul>
          <ul v-else class="ul1">
            <li>(暂无信息)</li>
          </ul>
        </div>
      </div>

      <div class="mclt" style="width: 100%">
        <div class="item">
          <div class="he">
            <h3>仲裁庭决定</h3>
          </div>
          <ul v-if="bananInfo && checkupFlow  && pingyi" class="ul1">
            <li style="width: 100%;">鉴定事项：<i> {{ checkupFlow.jianding_content || '无' }}</i></li>
            <li>鉴定材料提交方：<i> {{ cailiaoTjf }}</i></li>
            <li>鉴定材料提交期限：<i v-if="checkupFlow.tijiao_date==null">无</i><i v-else>{{
                checkupFlow.tijiao_date
              }}天</i></li>
            <li>鉴定费用预交方：<i> {{ fytjf }}</i></li>

            <li>首席是否同意：<i>{{ this.sfty(this.pingyi.shouxi_is) }}</i></li>
            <li>首席签字：<i>{{ this.pingyi.shouxi_Name || '无' }}</i></li>
            <li>签字日期：<i>{{ DateFormat(this.pingyi.shouxi_date) }}</i></li>
            <li style="width: 100%;">首席意见：<i>{{ this.pingyi.shouxi_content || '无' }}</i></li>

            <li>边裁（申请人）否同意：<i>{{ this.sfty(this.pingyi.sqrbc_is) }}</i></li>
            <li>边裁（申请人）签字：<i>{{ this.pingyi.sqr_Bc_Name || '无' }}</i></li>
            <li>签字日期：<i>{{ DateFormat(this.pingyi.sqrbc_date) }}</i></li>
            <li style="width: 100%;">边裁（申请人）意见：<i>{{ this.pingyi.sqrbc_content || '无' }}</i></li>

            <li>边裁（被申请人）是否同意：<i>{{ this.sfty(this.pingyi.bsqrbc_is) }}</i></li>
            <li>边裁（被申请人）签字：<i>{{ this.pingyi.bsqr_Bc_Name || '无' }}</i></li>
            <li>签字日期：<i>{{ DateFormat(this.pingyi.bsqrbc_date) }}</i></li>
            <li style="width: 100%;">边裁（被申请人）意见：<i>{{ this.pingyi.bsqbc_content || '无' }}</i></li>
          </ul>
          <ul v-else class="ul1">
            <li>(暂无信息)</li>
          </ul>
        </div>
      </div>


      <div class="mclt" style="width: 100%">
        <div class="item">
          <div class="he">
            <h3>鉴定机构选择情况</h3>
          </div>
          <input v-if="checkupFlow.is_sf=='0'" checked='checked' name="isSf" type="checkbox" value="0">
          <input v-else disabled="disabled" name="isSf" type="checkbox" value="">
          双方共同指定
          <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
                    :data="sfgtzdtableData" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
                    stripe
                    style="width: 100%">
            <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
            <el-table-column align="center" label="是否已有机构" prop="sfyyjg"></el-table-column>
            <el-table-column align="center" label="机构名称" prop="jgmc"></el-table-column>
          </el-table>


          <input v-if="checkupFlow.is_sqr=='0'" checked='checked' name="isSf" type="checkbox"
                 value="0">
          <input v-else disabled="disabled" name="isSf" type="checkbox" value="">
          申请人选择
          <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
                    :data="sqrxztableData" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
                    stripe
                    style="width: 100%">
            <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
            <el-table-column align="center" label="是否已有机构" prop="sfyyjg"></el-table-column>
            <el-table-column align="center" label="机构名称" prop="jgmc"></el-table-column>
          </el-table>


          <input v-if="checkupFlow.is_bsqr=='0'" checked='checked' name="isSf" type="checkbox"
                 value="0">
          <input v-else disabled="disabled" name="isSf" type="checkbox" value="">
          被申请人选择
          <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
                    :data="bsqrxztableData" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
                    stripe
                    style="width: 100%">
            <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
            <el-table-column align="center" label="是否已有机构" prop="sfyyjg"></el-table-column>
            <el-table-column align="center" label="机构名称" prop="jgmc"></el-table-column>
          </el-table>


          <input v-if="checkupFlow.is_sf_gt=='0'" checked='checked' name="isSf" type="checkbox" value="0">
          <input v-else disabled="disabled" name="isSf" type="checkbox" value="">
          双方共同委托本会指定
          <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
                    :data="sfgtwtbhzdtableData" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
                    stripe
                    style="width: 100%">
            <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
            <el-table-column align="center" label="是否已有机构" prop="sfyyjg"></el-table-column>
            <el-table-column align="center" label="机构名称" prop="jgmc"></el-table-column>
          </el-table>

          <input v-if="checkupFlow.is_other=='0'" checked='checked' name="isSf" type="checkbox" value="0">
          <input v-else disabled="disabled" name="isSf" type="checkbox" value="">
          其他
          <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
                    :data="qttableData" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
                    stripe
                    style="width: 100%">
            <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
            <el-table-column align="center" label="是否已有机构" prop="sfyyjg"></el-table-column>
            <el-table-column align="center" label="机构名称" prop="jgmc"></el-table-column>
          </el-table>


          决定委托鉴定机构
          <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
                    :data="jdwtjdjgtableData" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
                    stripe
                    style="width: 100%">
            <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
            <el-table-column align="center" label="是否已有机构" prop="sfyyjg"></el-table-column>
            <el-table-column align="center" label="机构名称" prop="jgmc"></el-table-column>
          </el-table>


          <ul v-if="checkupFlow  " class="ul1">
            <li>联系人：<i v-if="AppraiseJigou==null">无</i><i v-else>{{ AppraiseJigou.lianxi_by || '无' }} </i></li>
            <li>联系电话：<i v-if="AppraiseJigou==null">无</i><i v-else>{{ AppraiseJigou.lianxi_phone || '无' }} </i>
            </li>
            <li style="width: 100%;">备注：<i>{{ checkupFlow.remarks || '无' }} </i></li>

          </ul>
          <ul v-else class="ul1">
            <li>(暂无信息)</li>
          </ul>
        </div>
      </div>

      <div class="mclt" style="width: 100%">
        <div class="item">
          <div class="he">
            <h3>审核信息</h3>
          </div>
          <ul v-if="bananInfo && checkupFlow " class="ul1">
            <li>填报人：<i> {{ checkupFlow.tianbao_name || '无' }}</i></li>
          </ul>
          <ul v-else class="ul1">
            <li>(暂无信息)</li>
          </ul>

          <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
                    :data="shxxtableData" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
                    stripe
                    style="width: 100%">
            <el-table-column align="center" label="职务" prop="zw" width="100"></el-table-column>
            <el-table-column align="center" label="意见" prop="yjxx"></el-table-column>
            <el-table-column align="center" label="签字" prop="qzxx"></el-table-column>
            <el-table-column align="center" label="日期" prop="rq"></el-table-column>
          </el-table>
        </div>
      </div>


      <div class="mclt" style="width: 100%">
        <div class="item">
          <div class="he">
            <h3>工作计划和收费通知</h3>
          </div>
          <ul v-if="bananInfo && checkupFlow " class="ul1">
            <li>开户行：<i v-if="SysdictInfo==null">无</i><i v-else> {{ SysdictInfo.label || '无' }}</i></li>
            <li>开户人：<i> {{ checkupFlow.kaihu_name || '无' }}</i></li>
            <li>收款账户：<i> {{ checkupFlow.bank_num || '无' }}</i></li>
            <li>鉴定费用：<i v-if="checkupFlow.jianding_money==null">无</i> <i v-else>{{
                checkupFlow.jianding_money
              }}元</i></li>
            <li>具体交费期限：<i v-if="checkupFlow.jiaofei_num==null">无</i><i v-else>
              {{ checkupFlow.jiaofei_num }}天</i></li>
            <li style="width: 100%;">鉴定工作计划：<i>
              <table v-for="(item,index) in checkplanidInfo" style="border: 1px solid #ccc;">
                <tr>
                  <td>
                    {{ item.fileName }}
                  </td>
                </tr>
              </table>
            </i></li>
          </ul>
          <ul v-else class="ul1">
            <li>(暂无信息)</li>
          </ul>
        </div>
      </div>


      <div class="mclt" style="width: 100%">
        <div class="item">
          <div class="he">
            <h3>文书信息</h3>
          </div>
          <ul v-if="bananInfo && authenticateList" class="ul1">
            <li>鉴定通知书：<i> {{ this.authenticateList.jdtz || '无' }}</i></li>
            <li>申请人鉴定通知送达回证：<i> {{ this.authenticateList.sqrtzssend || '无' }}</i></li>
            <li>被申请人鉴定通知送达回证：<i> {{ this.authenticateList.bsqrtzssend || '无' }}</i></li>
            <li style="width: 100%;">鉴定通知送达结果：<i>
              <a style="font-size: 14px;
  color: #455ce0;
  background: #ebedfc;
  border-radius: 4px;
  padding: 0 10px;
  line-height: 26px;" @click="sendResultsList('sendResults',bananInfo.bananId,checkupFlow.id,'5')">送达结果</a>
            </i></li>
            <li>鉴定委托书：<i> {{ this.authenticateList.jdwt || '无' }}</i></li>
            <li>鉴定机构送达回证：<i> {{ this.authenticateList.wtssend || '无' }}</i></li>
            <li>鉴定委托书送达回证：<i> {{ this.authenticateList.jdjgqdssend || '无' }}</i></li>
            <li style="width: 100%;">鉴定委托送达结果：<i>
              <a style="font-size: 14px;
  color: #455ce0;
  background: #ebedfc;
  border-radius: 4px;
  padding: 0 10px;
  line-height: 26px;" @click="sendResultsList('sendResults',bananInfo.bananId,checkupFlow.id,'7')">送达结果</a>
            </i></li>
            <li>鉴定机构确定通知书：<i> {{ this.authenticateList.qdtz || '无' }}</i></li>
            <li>预交方鉴定确定通知书：<i> {{ this.authenticateList.jdftz || '无' }}</i></li>
            <li>预交费通知书送达回证：<i> {{ this.authenticateList.yjfsend || '无' }}</i></li>
            <li>申请人鉴定确认通知送达回证：<i> {{ this.authenticateList.sqrqdssend || '无' }}</i></li>
            <li>被申请人鉴定确认通知送达回证：<i> {{ this.authenticateList.bsqrqdssend || '无' }}</i></li>


          </ul>
          <ul v-else class="ul1">
            <li>(暂无信息)</li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialogOpen_sdjg" class="new-dialog"
               custom-class="addMicroDialog"
               title="送达结果" width="95%" @close="closeWindow_sdjg">
      <component v-bind:is="currentPopup" :banan_Id="banan_Id" :checkupFlowid="checkupFlowid" :sd_type="sd_type"
                 @fatherMethod="closeDialog_sdjg"></component>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment/moment";
import sendResults from "@/views/case/details/components/sendResultsList.vue";
import top from "@/views/zcy/top.vue";

export default {
  name: "authenticatexq",
  components: {
    top,
    sendResults,

  },
  props: {
    bananId: '',


  },
  data() {
    return {
      currentPopup: null,
      dialogOpen_sdjg: false,

      sd_type: null,
      banan_Id: null,
      checkupFlowid: null,
      bananInfo: null,
      checkupFlow: null,
      AccessoryInfo: null,
      pingyi: null,
      authenticateList: null,
      sqrCheckJigou: null,
      bsqrCheckJigou: null,
      AppraiseJigou: null,
      zgcczbyInfo: null,
      zgczrbyInfo: null,
      SysdictInfo: null,
      checkplanidInfo: null,
      shxxtableData: [],
      sfgtzdtableData: [],
      sqrxztableData: [],
      bsqrxztableData: [],
      sfgtwtbhzdtableData: [],
      qttableData: [],
      jdwtjdjgtableData: [],

    };
  },
  methods: {
    init() {
      this.axios.get('/api/handling/authenticateXq', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.authenticateList = data.data;
          this.bananInfo = data.data.bananInfo;
          this.checkupFlow = data.data.checkupFlow;
          this.AccessoryInfo = data.data.AccessoryInfo;
          this.pingyi = data.data.pingyi;
          this.sqrCheckJigou = data.data.sqrCheckJigou;
          this.bsqrCheckJigou = data.data.bsqrCheckJigou;
          this.AppraiseJigou = data.data.AppraiseJigou;
          this.zgcczbyInfo = data.data.zgcczbyInfo;
          this.zgczrbyInfo = data.data.zgczrbyInfo;
          this.SysdictInfo = data.data.SysdictInfo;
          this.checkplanidInfo = data.data.checkplanidInfo;
          this.shxxtableData.push(
              {
                zw: '仲管处处',
                yjxx: this.checkupFlow ? this.checkupFlow.zgc_cz_content || '无' : '无',
                qzxx: this.zgcczbyInfo ? this.zgcczbyInfo.name || '无' : '无',
                rq: this.checkupFlow ? this.DateFormat(this.checkupFlow.zgc_cz_date) || '无' : '无'
              },
              {
                zw: '分管领导',
                yjxx: this.checkupFlow ? this.checkupFlow.zgc_zr_content || '无' : '无',
                qzxx: this.zgczrbyInfo ? this.zgczrbyInfo.name || '无' : '无',
                rq: this.checkupFlow ? this.DateFormat(this.checkupFlow.zgc_zr_date) || '无' : '无'
              },
          );
          this.sfgtzdtableData.push(
              {
                xh: '1',
                sfyyjg: this.checkupFlow ? this.sftype(this.checkupFlow.is_sf_kn) : '无',
                jgmc: this.checkupFlow ? this.checkupFlow.jigou_sf_name || '无' : '无',
              },
          );
          this.sqrxztableData.push(
              {
                xh: '1',
                sfyyjg: this.sqrCheckJigou ? this.sftype(this.sqrCheckJigou.is_kn_one) : '无',
                jgmc: this.sqrCheckJigou ? this.sqrCheckJigou.jigou_name_one || '无' : '无',
              },
              {
                xh: '2',
                sfyyjg: this.sqrCheckJigou ? this.sftype(this.sqrCheckJigou.is_kn_Two) : '无',
                jgmc: this.sqrCheckJigou ? this.sqrCheckJigou.jigou_name_Two || '无' : '无',
              },
              {
                xh: '3',
                sfyyjg: this.sqrCheckJigou ? this.sftype(this.sqrCheckJigou.is_kn_third) : '无',
                jgmc: this.sqrCheckJigou ? this.sqrCheckJigou.jigou_name_third || '无' : '无',
              },
          );
          this.bsqrxztableData.push(
              {
                xh: '1',
                sfyyjg: this.bsqrCheckJigou ? this.sftype(this.bsqrCheckJigou.is_kn_one) : '无',
                jgmc: this.bsqrCheckJigou ? this.bsqrCheckJigou.jigou_name_one || '无' : '无',
              },
              {
                xh: '2',
                sfyyjg: this.bsqrCheckJigou ? this.sftype(this.bsqrCheckJigou.is_kn_Two) : '无',
                jgmc: this.bsqrCheckJigou ? this.bsqrCheckJigou.jigou_name_Two || '无' : '无',
              },
              {
                xh: '3',
                sfyyjg: this.bsqrCheckJigou ? this.sftype(this.bsqrCheckJigou.is_kn_third) : '无',
                jgmc: this.bsqrCheckJigou ? this.bsqrCheckJigou.jigou_name_third || '无' : '无',
              },
          );
          this.sfgtwtbhzdtableData.push(
              {
                xh: '1',
                sfyyjg: this.checkupFlow ? this.sftype(this.checkupFlow.is_gt_kn) : '无',
                jgmc: this.checkupFlow ? this.checkupFlow.jigou_gt_name || '无' : '无',
              },
          );
          this.qttableData.push(
              {
                xh: '1',
                sfyyjg: this.checkupFlow ? this.sftype(this.checkupFlow.is_other_kn) : '无',
                jgmc: this.checkupFlow ? this.checkupFlow.jigou_other_name || '无' : '无',
              },
          );
          this.jdwtjdjgtableData.push(
              {
                xh: '1',
                sfyyjg: this.checkupFlow ? this.sftype(this.checkupFlow.is_jd_kn) : '无',
                jgmc: this.checkupFlow ? this.checkupFlow.jigou_name || '无' : '无',
              },
          );
          // alert(data.data.pingyi);
        }
      }).catch(error => {
        console.log(error);
      });

    },
    sendResultsList(popupName, bananid, checkupFlowid, sdtype) {
      this.dialogOpen_sdjg = true
      this.currentPopup = popupName
      this.banan_Id = bananid
      this.checkupFlowid = checkupFlowid
      this.sd_type = sdtype
      //alert(bananid); alert(checkupFlowid);alert(sdtype);

    },
    closeDialog_sdjg(cmd) {
      this.closeWindow_sdjg();
    },
    closeWindow_sdjg() {
      this.currentPopup = null
      // this.bananId = null
      this.dialogOpen_sdjg = false
    },
    DateFormat(value) {
      let date = value != null ? moment(value).format('YYYY-MM-DD') : '' || '无'
      return date;
    },
    sfty(type) {
      switch (type) {
        case '0':
          return '同意';
        case '1':
          return '不同意';
        default:
          return '无';
      }
    },
    sftypeone(type) {
      switch (type) {
        case '0':
          return '是';
        default:
          return '';
      }
    },
    sftype(type) {
      switch (type) {
        case '0':
          return '是';
        case '1':
          return '否';
        default:
          return '无';
      }
    },
  },
  computed: {
    jdsqr() {
      // 使用 split 方法将字符串分割为数组
      let apply_type_array = this.checkupFlow.apply_type.split(",");
      // 使用 map 方法对数组中的每个元素进行处理
      let result_array = apply_type_array.map(item => {
        item = item.trim();
        // 如果数组元素为空，则返回 null
        if (item === '') {
          return null;
        }

        switch (item) {
          case '0':
            return '申请人';
          case '1':
            return '被申请人';
          case '2':
            return '仲裁庭';
          default:
            return '无';
        }
      }).filter(item => item !== null);

      // 如果结果数组中只有一个元素，直接返回该元素
      if (result_array.length === 1) {
        return result_array[0];
      }

      // 使用 join 方法将处理后的数组转换回逗号分隔的字符串
      return result_array.join(", ");
    },
    isJianding() {
      switch (this.checkupFlow.is_jianding) {
        case '0':
          return '是';
        case '1':
          return '否';
        default:
          return '无';
      }
    },
    jigouType() {
      switch (this.checkupFlow.jigou_type) {
        case '0':
          return '保险';
        case '1':
          return '建设工程造价';
        case '2':
          return '文检';
        case '3':
          return '房地产价格评估';
        case '4':
          return '财务审计';
        case '5':
          return '资产评估';
        case '6':
          return '其他';
        case '7':
          return '工程质量、房屋安全';
        case '8':
          return '物证';
        case '9':
          return '知识产权';
        default:
          return '无';
      }
    },
    cailiaoTjf() {
      // 使用 split 方法将字符串分割为数组
      let cailiao_tjf_array = this.checkupFlow.cailiao_tjf.split(",");

      // 使用 map 方法对数组中的每个元素进行处理
      let result_array = cailiao_tjf_array.map(item => {
        item = item.trim();

        // 如果数组元素为空，则返回 null
        if (item === '') {
          return null;
        }

        switch (item) {
          case '0':
            return '申请人';
          case '1':
            return '被申请人';
          default:
            return null; // 如果不匹配任何已知的值，返回 null
        }
      })
          // 使用 filter 方法移除数组中的 null 值
          .filter(item => item !== null);

      // 如果结果数组中只有一个元素，直接返回该元素
      if (result_array.length === 1) {
        return result_array[0];
      }

      // 使用 join 方法将处理后的数组转换回逗号分隔的字符串
      return result_array.join(", ");
    },
    fytjf() {
      // 使用 split 方法将字符串分割为数组
      let cailiao_tjf_array = this.checkupFlow.fy_tjf.split(",");

      // 使用 map 方法对数组中的每个元素进行处理
      let result_array = cailiao_tjf_array.map(item => {
        item = item.trim();

        // 如果数组元素为空，则返回 null
        if (item === '') {
          return null;
        }

        switch (item) {
          case '0':
            return '申请人';
          case '1':
            return '被申请人';
          default:
            return null; // 如果不匹配任何已知的值，返回 null
        }
      })
          // 使用 filter 方法移除数组中的 null 值
          .filter(item => item !== null);

      // 如果结果数组中只有一个元素，直接返回该元素
      if (result_array.length === 1) {
        return result_array[0];
      }

      // 使用 join 方法将处理后的数组转换回逗号分隔的字符串
      return result_array.join(", ");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    //alert(this.bananId);
    this.init()
  }
}
</script>

<!--<style scoped src="@/assets/css/authenticate/stylejd.css">-->
<style scoped src="@/assets/css/zcy/style.css">
.anni {
  font-size: 14px;
  color: #455ce0;
  background: #ebedfc;
  border-radius: 4px;
  padding: 0 10px;
  line-height: 26px;
}
</style>