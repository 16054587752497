<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>结案信息</h3>
    </div>
    <ul v-if="repealList" class="ul1">
      <li>结案方式：<i v-if="repealList.jafs">{{ jafsname(repealList.jafs) }}</i><i v-else>无</i></li>
      <li>结案日期：<i v-if="repealList.jarq">{{ moment(repealList.jarq).format('YYYY-MM-DD') }}</i><i v-else>无</i></li>

      <li v-if="repealList.jafs=='0'">裁决书：<i v-if="repealList.cjs">{{ repealList.cjs }}</i><i v-else>无</i></li>
      <li v-if="repealList.jafs=='0'">发文稿：<i v-if="repealList.fwg">{{ repealList.fwg }}</i><i v-else>无</i></li>
      <li v-if="repealList.jafs=='0'">裁决申请人书送达回证：<i
          v-if="repealList.caijueshuSqrSdhz">{{ repealList.caijueshuSqrSdhz }}</i><i v-else>无</i></li>
      <li v-if="repealList.jafs=='0'">裁决被申请人书送达回证：<i
          v-if="repealList.caijueshuBsqrSdhzId">{{ repealList.caijueshuBsqrSdhzId }}</i><i v-else>无</i></li>

      <li v-if="repealList.jafs=='1'">申请人送达回证：<i v-if="repealList.sqrSendId">{{ repealList.sqrSendId }}</i><i
          v-else>无</i></li>
      <li v-if="repealList.jafs=='1'">被申请人送达回证：<i v-if="repealList.bsqrSendId">{{ repealList.bsqrSendId }}</i><i
          v-else>无</i></li>
      <li v-if="repealList.jafs=='1'">调解书：<i v-if="repealList.tiaojieshu">{{ repealList.tiaojieshu }}</i><i
          v-else>无</i></li>
      <li v-if="repealList.jafs=='1'">调解书发文稿：<i v-if="repealList.fawengao">{{ repealList.fawengao }}</i><i v-else>无</i>
      </li>

      <li v-if="repealList.jafs=='2'">决定书：<i v-if="repealList.jueding">{{ repealList.jueding }}</i><i v-else>无</i>
      </li>
      <li v-if="repealList.jafs=='2'">申请人送达回证：<i v-if="repealList.ch_sqrSendId">{{ repealList.ch_sqrSendId }}</i><i
          v-else>无</i></li>
      <li v-if="repealList.jafs=='2'">被申请人送达回证：<i
          v-if="repealList.ch_bsqrSendId">{{ repealList.ch_bsqrSendId }}</i><i v-else>无</i></li>

      <li v-if="repealList.jafs=='4'">效力异议决定书：<i v-if="repealList.YYjds">{{ repealList.YYjds }}</i><i
          v-else>无</i></li>
      <li v-if="repealList.jafs=='4'">效力异议发文稿：<i v-if="repealList.YYfwg">{{ repealList.YYfwg }}</i><i
          v-else>无</i></li>
      <li v-if="repealList.jafs=='4'">申请人送达回证：<i v-if="repealList.YY_sqrSendId">{{ repealList.YY_sqrSendId }}</i><i
          v-else>无</i></li>
      <li v-if="repealList.jafs=='4'">被申请人送达回证：<i
          v-if="repealList.YY_bsqrSendId">{{ repealList.YY_bsqrSendId }}</i><i v-else>无</i></li>


    </ul>
    <ul v-else class="ul1">
      <li>(暂无信息)</li>
    </ul>
    <span v-if="repealList">
    <span v-if="repealList.jafs=='1'" style="width: 100%;">调解协议：
    <span v-if="tjxytableData!=''">
    <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
              :data="tjxytableData" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
              stripe
              style="width: 100%">
      <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
      <el-table-column align="center" label="名称" prop="fileName"></el-table-column>
    </el-table>
            </span>
    <span v-else>
 无
    </span>
    </span>
    <span v-if="repealList.jafs=='2'" style="width: 100%;"> 撤回申请书：
    <span v-if="chehuitableData!=''">
    <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
              :data="chehuitableData" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
              stripe
              style="width: 100%">
      <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
      <el-table-column align="center" label="名称" prop="fileName"></el-table-column>
    </el-table>
            </span>
    <span v-else>
     无
    </span>
    </span>
      </span>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "Closing",
  computed: {
    moment() {
      return moment
    }
  },
  props: {
    bananId: String,
    caseNum: String
  },
  data() {
    return {
      tjxyList: null,
      repealList: null,
      tjxytableData: [],
      chehuiList: null,
      chehuitableData: [],
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.repeal_init();
        }
      }
    },
    repealList(newVal) {
      if (newVal) {
        const hasData = !!newVal;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    repeal_init() {
      //alert(this.bananId);
      this.axios.get('/api/handling/Closing', {
        // params: {id: '835d27e1aaec4dd08abd9f9877fc0028'}
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        //alert(data.code);
        if (data.code == 200) {
          this.repealList = data.data;

          const tjxyListData = Array.isArray(data.data.xieyiInfo) ? data.data.xieyiInfo : [];
          this.tjxyList = tjxyListData;
          this.tjxyList.forEach((item) => {
            let params = {};
            params.xh = item.xh || '无';
            params.fileName = item.fileName || '无';
            this.tjxytableData.push(params);
          })
          const chehuiListData = Array.isArray(data.data.chehuiInfo) ? data.data.chehuiInfo : [];
          this.chehuiList = chehuiListData;
          this.chehuiList.forEach((item) => {
            let params = {};
            params.xh = item.xh || '无';
            params.fileName = item.fileName || '无';
            this.chehuitableData.push(params);
          })

        }
      }).catch(error => {
        console.log(error);
      });
    },
    jafsname(type) {
      switch (type) {
        case '0':
          return '裁决结案';
        case '1':
          return '调解结案';
        case '2':
          return '撤案结案';
        case '3':
          return '未结案';
        case '4':
          return '管辖异议成立结案';
        default:
          return '无';
      }
    },
  }
}
</script>

<style scoped>

</style>