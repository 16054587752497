<template>
  <div v-if="isVisible">
    <div class="loader">
      <!--      <div class="loading-animation">
              <div class="justify-content-center jimu-primary-loading"></div>
            </div>-->
      <div class="loading-text">正在加载{{ dots }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loaders",
  data() {
    return {
      isVisible: false,
      dots: '',
      dotsInterval: null,
      minDisplayTimer: null,
    }
  },
  methods: {
    show() {
      if (!this.isVisible) {
        this.isVisible = true;
        this.startMinDisplayTimer();
      }
    },
    hide() {
      if (this.minDisplayTimer) {
        clearTimeout(this.minDisplayTimer);
        this.minDisplayTimer = null;
      }
      this.isVisible = false;
    },
    startMinDisplayTimer() {
      this.minDisplayTimer = setTimeout(() => {
        this.minDisplayTimer = null;
        // 这里不直接设置 isVisible = false 是因为可能在2秒内再次调用 show()
        if (!this.isVisible) {
          this.hide();
        }
      }, 2000); // 最少显示2秒
    },
    updateDots() {
      this.dotsInterval = setInterval(() => {
        const maxDots = 3;
        const dotsLength = this.dots.length < maxDots ? this.dots.length + 1 : 0;
        this.dots = '.'.repeat(dotsLength);
      }, 500);
    },
  },
  created() {
    this.updateDots();
  },
  beforeDestroy() {
    if (this.dotsInterval) {
      clearInterval(this.dotsInterval);
    }
    if (this.minDisplayTimer) {
      clearTimeout(this.minDisplayTimer);
    }
  },
};
</script>


<style scoped>
.loader {
  position: absolute; /* 使用 fixed 以保证始终在视口中居中 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text {
  color: #ffffff;
  font-size: 20px; /* 使用视口宽度的百分比 */
  font-weight: bold;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 50%;
  content: '';
  background: #076fe5;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 2vw; /* 使用视口宽度的百分比 */
  height: 5vh; /* 使用视口高度的百分比 */
  transform: translateY(-50%);
}

.jimu-primary-loading:before {
  left: -4vw; /* 使用视口宽度的百分比 */
}

.jimu-primary-loading:after {
  left: 4vw; /* 使用视口宽度的百分比 */
  animation-delay: 0.32s;
}

@keyframes loading-keys-app-loading {
  0%, 80%, 100% {
    opacity: 0.75;
    box-shadow: 0 0 #076fe5;
    height: 5vh; /* 使用视口高度的百分比 */
  }
  40% {
    opacity: 1;
    box-shadow: 0 -2vh #076fe5;
    height: 6vh; /* 使用视口高度的百分比 */
  }
}

/* 媒体查询，适应不同屏幕 */
@media (max-width: 600px) {
  .loading-text {
    font-size: 5vw; /* 小屏幕上增大字体大小 */
  }

  .jimu-primary-loading:before,
  .jimu-primary-loading:after {
    width: 5vw; /* 小屏幕上增大动画大小 */
    height: 10vh;
  }
}

</style>
