<template>
  <div class="item">
    <div class="he">
      <h3>主要荣誉</h3>
    </div>
    <el-table
        :data="tableData"
        stripe
        style="width: 100%">
      <el-table-column
          prop="xh"
          label="序号"
          width="50">
      </el-table-column>
      <el-table-column
          prop="TimeOfHonor"
          label="获得荣誉时间">
      </el-table-column>
      <el-table-column
          prop="CertificateName"
          label="荣誉证书名称">
      </el-table-column>
      <el-table-column
          prop="ScannedCertificate"
          label="荣誉证书扫描件">
        <!-- 使用作用域插槽 -->
        <template slot-scope="scope">
          <span>{{ scope.row.ScannedCertificate }}</span>
          <el-button size="small" @click="viewCertificate(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "major",
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    initMajor() {
      this.axios.get('/pingZhan/inquire/zcyMajorById', {
        params: {
          id: this.$route.params.id
        }
      }).then(response => {
        const data = response.data;
        if (data.code == 200) {
          //data中取到的数据
          const originalData = data.data;
          //按照tableData格式转换为新数组，如果不存在则默认“暂无”
          const newData = originalData.map(item => {
            return {
              xh: item.xh || "暂无",
              TimeOfHonor: item.get_ry_data || "暂无",
              CertificateName: item.ry_name || "暂无",
              ScannedCertificate: item.ry_photo_url || "暂无",
            };
          });
          this.tableData = newData;
        } else {
          this.$message.error("获取工作经历失败")
        }
      }).catch(error => {
        console.log(error);
      });
    },
    viewCertificate(row) {
      console.log("查看证书", row.ScannedCertificate);
    }
  },
  mounted() {
    this.initMajor();
  }
}
</script>

<style scoped>

</style>