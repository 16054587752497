<template>
  <div class="mcpg" id="home_vue">
    <div class="mcbd">
      <div class="mclt">
        <!--基本信息-->
        <basic id="jiben" :user="user"></basic>
        <!--单位信息-->
        <unit id="danwei" :user="user"></unit>
        <!--联系信息-->
        <relation id="lianxi" :user="user"></relation>
        <!--相关从业信息-->
        <business id="congye" :user="user"></business>
        <!--学习经历-->
        <study id="xuexi"></study>
        <!--工作经历-->
        <work id="gongzuo"></work>
        <!--主要荣誉-->
        <major id="zhuyao"></major>
        <!--培训情况-->
        <train id="peixun"></train>
        <!--奖惩情况-->
        <rewards id="jiangcheng"></rewards>
        <!--银行卡号-->
        <bank id="yinhang" :user="user"></bank>
      </div>
      <div class="mcrt">
        <ul>
          <li :class="{ on: section === 'jiben' }"><a @click="scrollToSection('jiben')">1.基本信息</a></li>
          <li :class="{ on: section === 'danwei' }"><a @click="scrollToSection('danwei')">2.单位信息</a></li>
          <li :class="{ on: section === 'lianxi' }"><a @click="scrollToSection('lianxi')">3.联系信息</a></li>
          <li :class="{ on: section === 'congye' }"><a @click="scrollToSection('congye')">4.相关从业信息</a></li>
          <li :class="{ on: section === 'xuexi' }"><a @click="scrollToSection('xuexi')">5.学习经历</a></li>
          <li :class="{ on: section === 'gongzuo' }"><a @click="scrollToSection('gongzuo')">6.工作经历</a></li>
          <li :class="{ on: section === 'zhuyao' }"><a @click="scrollToSection('zhuyao')">7.主要荣誉</a></li>
          <li :class="{ on: section === 'peixun' }"><a @click="scrollToSection('peixun')">8.培训情况</a></li>
          <li :class="{ on: section === 'jiangcheng' }"><a @click="scrollToSection('jiangcheng')">9.奖惩情况</a></li>
          <li :class="{ on: section === 'yinhang' }"><a @click="scrollToSection('yinhang')">10.银行卡号</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import basic from './components/basic.vue'
import unit from './components/unit.vue'
import relation from './components/relation.vue'
import business from './components/business.vue'
import study from './components/study.vue'
import work from './components/work.vue'
import major from './components/major.vue'
import train from './components/train.vue'
import rewards from './components/rewards.vue'
import bank from './components/bank.vue'

export default {
  name: "zcy_details",
  props: {
    user: Object
  },
  components: {
    basic,
    unit,
    relation,
    business,
    study,
    work,
    major,
    train,
    rewards,
    bank,
  },
  data() {
    return {
      section: 'jiben',
      id: null,
    }
  },
  created() {
    this.id = this.$route.params.id;
  },
  methods: {
    scrollToSection(sectionId) {
      this.section = sectionId
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    },
  },
}
</script>
<style lang="css" scoped>
@import "@/assets/css/zcy/style.css";
@import "@/assets/css/zcy/slicy.css";

a {
  cursor: pointer;
}
</style>
