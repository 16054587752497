<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>保全信息</h3>
    </div>
    <span v-if="tableData!=''">
    <el-table
        :cell-style="{
      background:'#fff',
      color: '#7883c5'}"
        :data="tableData"
        :header-cell-style="{
      background:'#F5F7FA',
      color: '#3d4053'}"
        border
        stripe
        style="width: 100%">
      <el-table-column
          align="center"
          label="序号"
          prop="xh"
          width="50">
      </el-table-column>
      <el-table-column
          align="center"
          label="保全案号"
          prop="ah">
      </el-table-column>
      <el-table-column
          align="center"
          label="保全状态"
          prop="executeTime">
      </el-table-column>
      <el-table-column
          align="center"
          label="提请法院"
          prop="sumbitFy">
      </el-table-column>
      <el-table-column
          align="center"
          label="办案秘书名称"
          prop="bananMishuName">
      </el-table-column>
      <el-table-column
          align="center"
          label="保全时间"
          prop="bq_time">
      </el-table-column>
      <el-table-column
          align="center"
          label="保全金额"
          prop="bq_money">
      </el-table-column>
      <el-table-column
          align="center"
          label="保全事项"
          prop="bq_items">
      </el-table-column>
      <el-table-column
          align="center"
          label="保全担保信息"
          prop="bqinfo_db">
      </el-table-column>
      <!--      <el-table-column
                label="操作"
               >
              <template v-slot="{ row }">
                <el-link type="primary" @click="open('xqy',row.id)">查看</el-link>
              </template>
            </el-table-column>-->

    </el-table>
 </span>
    <span v-else>
      (暂无保全信息)
    </span>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialogwjscjl" class="new-dialog"
               custom-class="addMicroDialog"
               title="查看" width="80%" @close="closewjscjl">
      <component v-bind:is="currentPopup" :ywid="ywid"
                 @fatherMethod="closeDialog"></component>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "preserve",
  props: {
    bananId: String,
    caseNum: String,
  },
  components: {
    //xqy

  },
  data() {
    return {
      ywid: '',
      currentPopup: null,
      dialogwjscjl: false,
      tableData: []
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.execute_init();
        }
      }
    },
    tableData(newVal) {
      if (newVal) {
        const hasData = newVal && newVal.length > 0;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    open(popupName, id) {
      this.dialogwjscjl = true
      this.currentPopup = popupName
      this.ywid = id
    },
    closeDialog(cmd) {
      this.closewjscjl();
    },
    closewjscjl() {
      this.currentPopup = null
      this.ywid = null
      this.dialogwjscjl = false
    },
    execute_init() {
      //alert(this.bananId);
      this.axios.get('/api/handling/preserve', {
        //params: {id: '2ba61d3a602748d2aa1b76658c55b5bd'}
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          //data中取到的数据
          const originalData = Array.isArray(data.data) ? data.data : [];
          //按照tableData格式转换为新数组，如果不存在则默认“暂无”
          const newData = originalData.map(item => {
            return {
              id: item.id,
              xh: item.xh,
              ah: item.bqYear + '-' + item.bqNum || "暂无",
              executeTime: item.bqType == '0' ? "已保全" : item.bqType == '1' ? "已续保" : item.bqType == '2' ? "已解封" : item.bqType == '3' ? "已保全(解封)" : item.bqType == '4' ? "已解封(续)" : item.bqType == '5' ? "换封" : "暂无" || "暂无",
              sumbitFy: item.sumbitFy || "暂无",
              bananMishuName: item.bananMishuName || "暂无",
              bq_money: item.bq_money,
              bqinfo_db: item.bqinfo_db,
              bq_time: item.bq_time,
              bq_items: item.bq_items,


            };
          });
          this.tableData = newData;
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

</style>