<template>
  <div id="page2">
    <div class="mcpg mcpg1">
      <div class="mcbd">


        <!-- 时间选择和查询按钮 -->
        <div class="search-area">
          <span>发放时间：</span>
          <el-date-picker
              v-model="startDate"
              type="date"
              placeholder="开始日期"
              value-format="yyyy-MM-dd">
          </el-date-picker>

          <el-date-picker
              v-model="endDate"
              type="date"
              placeholder="结束日期"
              value-format="yyyy-MM-dd">
          </el-date-picker>

          <el-button type="primary" @click="searchRewards">查询</el-button>
        </div>
        <el-table :data="tableData" :span-method="mergeRows" border height="74.5vh" style="width: 100%;">
          <el-table-column prop="issueDate" label="发放日期" align="center"></el-table-column>
          <el-table-column prop="grossReward" label="应发报酬" align="center"></el-table-column>
          <el-table-column prop="tax" label="扣税" align="center"></el-table-column>
          <el-table-column prop="netReward" label="实发" align="center"></el-table-column>
          <el-table-column prop="caseNumber" label="案号" align="center"></el-table-column>
          <el-table-column prop="applicant" label="申请人" align="center"></el-table-column>
          <el-table-column prop="respondent" label="被申请人" align="center"></el-table-column>
          <el-table-column prop="closureDate" label="结案日期" align="center"></el-table-column>
          <el-table-column prop="closureType" label="结案类型" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "zcy_reward",
  props: {
    user: Object
  },
  data() {
    return {
      id: null,
      startDate: null, // 存储开始日期
      endDate: null,   // 存储结束日期
      tableData: [] // 表格数据
    }
  },
  methods: {
    searchRewards() {
      this.axios.get('/pingZhan/inquire/zcyRewardById', {
        params: {
          startTime: this.startDate,
          endTime: this.endDate,
          id: this.id
        }
      }).then(response => {
        let data = response.data;
        if (data.code == 200) {
          this.tableData = data.data.map(item => ({
            issueDate: this.formatDate(item.faFangDate),
            grossReward: item.yingFa,
            tax: item.kouShui,
            netReward: item.shiFa,
            caseNumber: item.caseNum,
            applicant: item.sqrNames,
            respondent: item.bsqrNames,
            closureDate: this.formatDate(item.jieAnDate),
            closureType: item.jieAnTypeName
          }));
        }
      }).catch(error => {

      })
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    mergeRows({row, column, rowIndex, columnIndex}) {
      if (['issueDate', 'grossReward', 'tax', 'netReward'].includes(column.property)) {
        // 如果是第一行或者当前行的发放日期与上一行不同
        if (rowIndex === 0 || this.tableData[rowIndex - 1].issueDate !== row.issueDate) {
          let span = 1; // 至少当前行自身
          // 计算后续有多少行发放日期与当前行相同
          for (let i = rowIndex + 1; i < this.tableData.length; i++) {
            if (this.tableData[i].issueDate === row.issueDate) {
              span++;
            } else {
              break;
            }
          }
          return {rowspan: span, colspan: 1};
        } else {
          // 当前行应该合并到之前的行
          return {rowspan: 0, colspan: 0};
        }
      }
    },
    getLastMonthDates() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth(); // 获取当前月份（0-11，0 表示 1 月）
      // 如果当前是1月，则上个月是去年的12月
      const lastMonth = month === 0 ? 11 : month - 1;
      const lastMonthYear = month === 0 ? year - 1 : year;

      // 上个月的第一天
      const firstDayOfLastMonth = new Date(lastMonthYear, lastMonth, 1);
      // 上个月的最后一天
      const lastDayOfLastMonth = new Date(lastMonthYear, lastMonth + 1, 0);

      this.startDate = this.formatDate(firstDayOfLastMonth);
      this.endDate = this.formatDate(lastDayOfLastMonth);
    }
  },
  created() {
    this.id = this.$route.params.id;

    // 计算上个月的日期并进行查询
    this.getLastMonthDates();
    this.searchRewards(); // 根据上个月的日期进行查询
  },
}
</script>


<style scoped>
.search-area {
  margin-bottom: 20px;
}

.search-area > span {
  margin-right: 10px;
}
</style>
