<template>
  <div class="mcpg mcpg1">
    <top :id="id" :caseNum="caseNum"></top>
    <router-view :id="id" :caseNum="caseNum"></router-view>
  </div>
</template>

<script>
import top from './top.vue'
import Handling_details from "./details/handling_details.vue";
import Handling_assess from "@/views/case/assess/handling_assess.vue";
import Handling_pay from "@/views/case/pay/handling_pay.vue";
import Handling_record from "@/views/case/record/handling_record.vue";
import Handling_writ from "@/views/case/writ/handling_writ.vue";

export default {
  name: "handling",
  components: {
    top,
    Handling_details,
    Handling_assess,
    Handling_pay,
    Handling_record,
    Handling_writ,
  },
  data() {
    return {
      id: null,
      caseNum: null,
    }
  },
  created() {
    this.id = this.$route.params.id;
    if (this.$route.path === `/handling/${this.$route.params.id}`) {
      this.$router.push({path: `/handling_details`});
    }
    this.handling_init();
  },
  methods: {
    //查询案号
    handling_init() {
      this.axios.get('/api/handling/handlingCaseNum', {
        params: {id: this.id}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.caseNum = data.data.case_year_num + '-' + data.data.case_serial_number;
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>
@import "@/assets/css/zcy/style.css";
@import "@/assets/css/zcy/slicy.css";

a {
  cursor: pointer;
}
</style>