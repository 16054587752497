<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>被申请人信息</h3>
    </div>
    <template v-if="respondentList">
      <template v-for="(item,index) in respondentList.bsqr">
        <ul v-if="item.dsrType=='0'" class="ul1">
          <li class="bolder-and-larger-text" style="width: 100%;">被申请人为{{ dsrType(item.dsrType) }}</li>
          <li>姓名：<i>{{ item.dsrName || '无' }}</i></li>
          <li>性别：<i>{{ gender(item.dsrSex) }}</i></li>
          <li>地域：<i>{{ district(item.dsrDistrict) }}</i></li>
          <li>证件类型：<i>{{ zjType(item.zjType) }}</i></li>
          <li>证件号码：<i>{{ item.zjNum || '无' }}</i></li>
          <li>联系电话： <i>{{ item.dsrPhone || '无' }}</i></li>
          <li>邮箱：<i>{{ item.dsrEmail }}</i></li>
          <li>地址：<i>{{ item.dsrAddress || '无' }}</i></li>
          <li>邮政编码：<i>{{ item.postalCode || '无' }}</i></li>
          <li style="width: 100%;">仲裁文书送达地：<i>{{ item.wsSdAddress || '无' }}</i></li>
          <li>法律文书收件人：<i>{{ item.shoujianMan || '无' }}</i></li>
          <li>法律文书收件人电话：<i>{{ item.shoujianPhone || '无' }}</i></li>
          <li>其它联系方式：<i>{{ item.otherFs || '无' }}</i></li>
        </ul>
        <ul v-if="item.dsrType=='1'" class="ul1">
          <li class="bolder-and-larger-text" style="width: 100%;">被申请人为{{ dsrType(item.dsrType) }}</li>
          <li>名称：<i>{{ item.danweiName || '无' }}</i></li>
          <li>法定代表人/负责人：<i>{{ item.dsrName || '无' }}</i></li>
          <li>性别：<i>{{ gender(item.dsrSex) }}</i></li>
          <li>地域：<i>{{ district(item.dsrDistrict) }}</i></li>
          <li>是否三证合一：<i>{{
              item && item.isSanzheng ? item.isSanzheng == '0' ? '是' : '否' : '无'
            }}</i></li>
          <li>统一社会信用代码：<i>{{ item.businessNum || '无' }}</i></li>
          <li>法定代表人证件类型：<i>{{ zjType(item.zjType) }}</i></li>
          <li>法定代表人证件号码：<i>{{ item.zjNum || '无' }}</i></li>
          <template v-if="item.isSanzheng==1">
            <li>法人登记注册账号：<i>{{ item.frDjzcZhanghao || '无' }}</i></li>
            <li>组织机构代码：<i>{{ item.zzjgDaima || '无' }}</i></li>
          </template>
          <li>联系电话：<i>{{ item.zjNum || '无' }}</i></li>
          <li>邮箱：<i>{{ item.dsrEmail || '无' }}</i></li>
          <li>地址：<i>{{ item.dsrAddress || '无' }}</i></li>
          <li>邮政编码：<i>{{ item.postalCode || '无' }}</i></li>
          <li>仲裁文书送达地：<i>{{ item.wsSdAddress || '无' }}</i></li>
          <li>法律文书收件人：<i>{{ item.shoujianMan || '无' }}</i></li>
          <li>法律文书收件人电话：<i>{{ item.shoujianPhone || '无' }}</i></li>
          <li>其它联系方式：<i>{{ item.otherFs || '无' }}</i></li>
        </ul>
        <ul v-if="item.dsrType=='2'" class="ul1">
          <li class="bolder-and-larger-text" style="width: 100%;">被申请人为{{
              dsrType(this.respondentList.bsqr.dsrType)
            }}
          </li>
          <li>名称：<i>{{ respondentList.bsqr.danweiName || '无' }}</i></li>
          <li>法定让代表人/负责人：<i>{{ respondentList.bsqr.dsrName || '无' }}</i></li>
          <li>地域：<i>{{ district(this.respondentList.bsqr.dsrDistrict) }}</i></li>
          <li>联系电话： <i>{{ respondentList.bsqr.dsrPhone || '无' }}</i></li>
          <li>邮箱：<i>{{ respondentList.bsqr.dsrEmail }}</i></li>
          <li>地址：<i>{{ respondentList.bsqr.dsrAddress || '无' }}</i></li>
          <li>邮政编码：<i>{{ respondentList.bsqr.postalCode || '无' }}</i></li>
          <li style="width: 100%;">仲裁文书送达地：<i>{{ respondentList.bsqr.wsSdAddress || '无' }}</i></li>
          <li>法律文书收件人：<i>{{ respondentList.bsqr.shoujianMan || '无' }}</i></li>
          <li>法律文书收件人电话：<i>{{ respondentList.bsqr.shoujianPhone || '无' }}</i></li>
          <li>其它联系方式：<i>{{ respondentList.bsqr.otherFs || '无' }}</i></li>
        </ul>
        <ul v-if="item.dailiIdOne!=null" class="ul1">
          <li style="width: 100%;">代理人一信息</li>
          <li>代理人类型：<i>{{ dlrType(item.dlrTypeOne) }}</i></li>
          <template v-if="item.dlrTypeOne==0">
            <li>姓名：<i>{{ item.dlrNameOne || '无' }}</i></li>
            <li>证件类型：<i>{{ zjType(item.dlrZjTypeOne) }}</i></li>
            <li>证件号码：<i>{{ item.dlrZjNumOne || '无' }}</i></li>
            <li>联系电话：<i>{{ item.dlrPhoneOne || '无' }}</i></li>
            <li>邮编：<i>{{ item.dlrEmailOne || '无' }}</i></li>
          </template>
          <template v-if="item.dlrTypeOne==1 || item.dlrTypeOne==2">
            <li>姓名：<i>{{ item.dlrNameOne || '无' }}</i></li>
            <li>工作单位：<i>{{ item.danweiNameOne || '无' }}</i></li>
            <li>联系电话：<i>{{ item.dlrPhoneOne || '无' }}</i></li>
            <li>执业证号：<i>{{ item.zhiyeNumOne || '无' }}</i></li>
          </template>
          <template v-if="item.dlrTypeOne==3">
            <li>姓名：<i>{{ item.dlrNameOne || '无' }}</i></li>
            <li>证件类型：<i>{{ zjType(item.dlrZjTypeOne) }}</i></li>
            <li>证件号码：<i>{{ item.dlrZjNumOne || '无' }}</i></li>
            <li>公司名称：<i>{{ item.gongsiNameOne || '无' }}</i></li>
            <li>职务：<i>{{ item.gsPostOne || '无' }}</i></li>
            <li>联系电话：<i>{{ item.dlrPhoneOne || '无' }}</i></li>
            <li>邮编：<i>{{ item.dlrEmailOne || '无' }}</i></li>
          </template>
        </ul>
        <ul v-if="item.dailiIdTwo!=null" class="ul1">
          <li style="width: 100%;">代理人二信息</li>
          <li>代理人类型：<i>{{ dlrType(item.dlrTypeTwo) }}</i></li>
          <template v-if="item.dlrTypeTwo==0">
            <li>姓名：<i>{{ item.dlrNameTwo || '无' }}</i></li>
            <li>证件类型：<i>{{ zjType(item.dlrNameTwo) }}</i></li>
            <li>证件号码：<i>{{ item.dlrZjNumTwo || '无' }}</i></li>
            <li>联系电话：<i>{{ item.dlrPhoneTwo || '无' }}</i></li>
            <li>邮编：<i>{{ item.dlrEmailTwo || '无' }}</i></li>
          </template>
          <template v-if="item.dlrTypeTwo==1 || item.dlrTypeTwo==2">
            <li>姓名：<i>{{ item.dlrNameTwo || '无' }}</i></li>
            <li>工作单位：<i>{{ item.danweiNameTwo || '无' }}</i></li>
            <li>联系电话：<i>{{ item.dlrPhoneTwo || '无' }}</i></li>
            <li>执业证号：<i>{{ item.zhiyeNumTwo || '无' }}</i></li>
          </template>
          <template v-if="item.dlrTypeTwo==3">
            <li>姓名：<i>{{ item.dlrNameTwo || '无' }}</i></li>
            <li>证件类型：<i>{{ zjType(item.dlrZjTypeTwo) }}</i></li>
            <li>证件号码：<i>{{ item.dlrZjNumTwo || '无' }}</i></li>
            <li>公司名称：<i>{{ item.gongsiNameTwo || '无' }}</i></li>
            <li>职务：<i>{{ item.gsPostTwo || '无' }}</i></li>
            <li>联系电话：<i>{{ item.dlrPhoneTwo || '无' }}</i></li>
            <li>邮编：<i>{{ item.dlrEmailTwo || '无' }}</i></li>
          </template>
        </ul>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "respondent",
  props: {
    bananId: String,
    caseNum: String,
  },
  data() {
    return {
      respondentList: null,
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.respondent_init();
        }
      }
    },
    respondentList(newVal) {
      if (newVal) {
        const hasData = !!newVal;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    respondent_init() {
      this.axios.get('/api/handling/respondent', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.respondentList = data.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    zjType(type) {
      switch (type) {
        case '0':
          return '居民身份证';
        case '1':
          return '护照';
        case '2':
          return '军官证';
        default:
          return '无';
      }
    },
    dlrType(type) {
      switch (type) {
        case '0':
          return '公民';
        case '1':
          return '法律工作者';
        case '2':
          return '律师';
        case '3':
          return '员工';
        default:
          return '无';
      }
    },
    dsrType(type) {
      switch (type) {
        case '0':
          return '自然人';
        case '1':
          return '法人';
        case '2':
          return '非法人其他组织';
        default:
          return '无';
      }
    },
    gender(type) {
      switch (type) {
        case '1':
          return '男';
        case '2':
          return '女';
        default:
          return '无';
      }
    },
    district(type) {
      switch (type) {
        case '0':
          return '济南市';
        case '1':
          return '外地';
        case '2':
          return '港澳台';
        case '3':
          return '海外';
        default:
          return '无';
      }
    },
  },
}
</script>

<style scoped>
.bolder-and-larger-text {
  font-weight: bold;
  font-size: 1.2em;
}
</style>