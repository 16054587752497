<template>
  <div>
    <loaders ref="loadersRef"></loaders>
    <div class="mask"></div>
    <div class="mskbana">
      <div class="gb" @click="close()"></div>
      <div class="tit">
        <h2>仲裁员列表</h2>
      </div>
      <div class="chax">
        <div class="lt">
          <form action="" method="post">
            <input id="" v-model="inquireForm.text" class="t1" name="" placeholder="请输入姓名搜索" type="text">
            <input class="sub" disabled style="cursor: default;" type="submit" value=""/>
          </form>
        </div>
        <div class="rt layui-form">
          <dl id="test6" class="test6">
            <dd>
              <div class="bgs">
                <el-date-picker
                    v-model="inquireForm.year"
                    align="right"
                    class="lsele"
                    format="yyyy"
                    placeholder="开始日期"
                    type="year"
                    value-format="yyyy">
                </el-date-picker>
              </div>
            </dd>
          </dl>
          <a class="btn" href="#" @click="inquire(inquireForm)"><span>查询</span></a>
        </div>
      </div>
      <div class="uittab">
        <div class="tps">
          <h3>序号</h3>
          <h3>姓名</h3>
          <h3>承办总数</h3>
          <h3>已办结</h3>
          <h3>未办结</h3>
          <h3>收结比</h3>
          <h3>超期办结</h3>
          <h3>超期未办结</h3>
          <h3>被撤销案件</h3>
          <h3>不予执行案件</h3>
          <h3>长期未结数</h3>
          <h3>鉴定</h3>
          <h3>中止</h3>
          <h3>延期</h3>
        </div>
        <dl>
          <dd v-for="(item,index) in tableData">
            <h4>{{ item.xh }}</h4>
            <h4 style="cursor:pointer;" @click="goToDetails(item.id)">{{ item.name }}</h4>
            <h4>{{ item.zongShu }}</h4>
            <h4>{{ item.jieAnShu }}</h4>
            <h4>{{ item.weiJieAnShu }}</h4>
            <h4>
              {{
                item.zongShu && item.zongShu > 0
                    ? ((item.jieAnShu / item.zongShu * 100).toFixed(2) + '%')
                    : '暂无'
              }}
            </h4>
            <h4>{{ item.chaoqibanjie }}</h4>
            <h4>{{ item.chaoqiweibanjie }}</h4>
            <h4>{{ item.beichexiao }}</h4>
            <h4>{{ item.buyuzhixing }}</h4>
            <h4>{{ item.changqiweijie }}</h4>
            <h4>{{ item.jianding }}</h4>
            <h4>{{ item.zhongzhi }}</h4>
            <h4>{{ item.yanqi }}</h4>
          </dd>
        </dl>
      </div>
      <div class="paginationCss">
        <el-pagination
            :current-page="pagination.currentPage"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            layout="total, prev, pager, next"
            @prev-click="prevPage"
            @next-click="nextPage"
            @current-change="PageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "more",
  components: {},
  data() {
    return {
      type: '',
      inquireForm: {
        text: '',
        year: '2023',
      },
      data: null,
      pagination: {
        type: '',
        total: 0,
        currentPage: 1,
        pageSize: 16
      },
      tableData: []
    }
  },
  computed: {},
  watch: {
    'pagination.type': function (newType, oldType) {
      this.pagination.currentPage = 1;
    }
  },
  methods: {
    async opens(item) {
      this.data = item;
      await this.waitForLoaders();
      this.$refs.loadersRef.show();
      this.init();
    },
    waitForLoaders() {
      return new Promise(resolve => {
        const checkExist = setInterval(() => {
          if (this.$refs.loadersRef) {
            clearInterval(checkExist);
            resolve();
          }
        }, 100); // 检查间隔
      });
    },
    close() {
      /*关闭时应该清除查询参数*/
      this.clearData();
      this.$emit('close');
    },
    clearData() {
      this.type = '';
      this.data = null;
      this.inquireForm.text = '';
      this.inquireForm.year = '';
      this.pagination.type = '';
      this.pagination.total = 0;
      this.pagination.currentPage = 0;
      this.tableData = [];
    },
    onEscKey(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
    restPagination(){
      this.pagination={
        type: '',
        total: 0,
        currentPage: 1,
        pageSize: 10
      }
    },
    inquire(form) {
      this.$refs.loadersRef.show();
      this.restPagination()
      this.pagination.type = 'inquire';

      this.axios.get('/pingZhan/inquire/zcyByName', {
        params: {
          name: this.inquireForm.text,
          startTime: this.inquireForm.startTime,
          endTime: this.inquireForm.endTime,
          currentYear: this.inquireForm.year,
          current: this.pagination.currentPage,
          pageSize: this.pagination.pageSize
        }
      }).then((response) => {
        let data = response.data;
        if (data.code == 200) {
          this.tableData = data.data.list;
          this.pagination.total = data.data.total;
        } else {
          console.log('数据获取失败');
        }
        this.$refs.loadersRef.hide();
      }).catch((error) => {
        console.error('发生错误:', error);
      });

    },
    init() {
      this.pagination.type = 'init';
      this.axios.get('/pingZhan/inquire/zcyList', {
        params: {
          currentYear: this.inquireForm.year,
          current: this.pagination.currentPage,
          pageSize: this.pagination.pageSize
        }
      }).then((response) => {
        let data = response.data;
        console.log(data);
        if (data.code == 200) {
          this.tableData = data.data.list;
          this.pagination.total = data.data.total;
        } else {
          console.log('数据获取失败');
        }
        this.$refs.loadersRef.hide();
      }).catch((error) => {
        console.error('发生错误:', error);
      });
    },
    prevPage(val) {
      if (this.pagination.currentPage > 1) {
        this.pagination.currentPage--;
        if (this.pagination.type === 'init') {
          this.init(); // 如果是初始化，调用 init 方法
        } else {
          this.inquire(this.inquireForm); // 否则调用 inquire 方法
        }
      }
    },
    nextPage(val) {
      if (this.pagination.currentPage < Math.ceil(this.pagination.total / this.pagination.pageSize)) {
        this.pagination.currentPage++;
        if (this.pagination.type === 'init') {
          this.init(); // 如果是初始化，调用 init 方法
        } else {
          this.inquire(this.inquireForm); // 否则调用 inquire 方法
        }
      }
    },
    PageChange(val) {
      if (val !== this.pagination.currentPage) {
        this.pagination.currentPage = val;
        if (this.pagination.type === 'init') {
          this.init(); // 如果是初始化，调用 init 方法
        } else {
          this.inquire(this.inquireForm); // 否则调用 inquire 方法
        }
      }
    },
    reset() {
      this.inquireForm = {
        text: '',
        year: '',
      };
      this.init()
    },
    goToDetails(id) {
      const baseUrl = window.location.origin; // 获取应用的基础 URL
      const url = `${baseUrl}/zcy_details/${id}`; // 构建完整 URL
      window.open(url, '_blank'); // 在新标签页打开 URL
    }

  },
  created() {
  },
  mounted() {
    // 添加 ESC 键盘事件监听器
    window.addEventListener('keydown', this.onEscKey);
    this.opens(this.popupData);
  },
  beforeDestroy() {
    // 移除 ESC 键盘事件监听器以防止内存泄漏
    window.removeEventListener('keydown', this.onEscKey);
  },

};
</script>

<style scoped>
/*时间选择框*/
.el-input >>> .el-input__inner {
  background-color: #fff0;
  border: 1px solid #ffffff00;
  color: #1bceed;
}

.paginationCss {
  text-align: center;
  margin-top: -0.5%;
}

/*修改分页器样式*/
.el-pagination >>> .btn-next, .el-pagination >>> .btn-prev {
  background: center center no-repeat #fff0;
  background-size: 16px;
  cursor: pointer;
  margin: 0;
  color: #ffffff;
}

.el-pagination button:disabled {
  color: #ffffff;
  background-color: #fff0;
  cursor: not-allowed;
}

.el-pagination >>> .el-dialog, .el-pagination >>> .el-pager li {
  background: #fff0;
  -webkit-box-sizing: border-box;
}

.el-pagination >>> .el-pagination__total {
  color: #ffffff;
}

.el-pagination >>> .el-pager {
  color: #ffffff;
}

/*导出*/
.cssbuttons-io-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  padding: 0.35em 0.5em;
  color: white;
  background: linear-gradient(0deg, rgb(77, 54, 208) 0%, rgb(132, 116, 254) 100%);
  border: none;
  box-shadow: rgba(77, 54, 208, 0.745) 0px 0.7em 1.5em -0.5em;
  letter-spacing: 0.05em;
  border-radius: 0.3em;
  margin-left: 1vh;
}

.cssbuttons-io-button svg {
  margin-right: 8px;
}

.cssbuttons-io-button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #4d36d0be;
}

.cssbuttons-io-button:active {
  box-shadow: 0 0.3em 1em -0.5em #4d36d0be;
}
</style>
