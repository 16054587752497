export default [
    {
        id: 1,
        label: "正卷",
        children: [
            {
                id: 2,
                label: "立案登记表",
                children: [

                    {
                        id: 111,
                        label: "附件1",
                        fileType: "image",
                        url: "https://img1.baidu.com/it/u=4049906368,438977941&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500"
                    },
                    {
                        id: 112,
                        label: "附件2",
                        fileType: "image",
                        url: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimage109.360doc.com%2FDownloadImg%2F2021%2F09%2F2501%2F231033624_1_20210925010834976&refer=http%3A%2F%2Fimage109.360doc.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1711680826&t=e156fbc3f50fe400c529b581add8c098"
                    },
                    {
                        id: 113,
                        label: "附件3",
                        fileType: "image",
                        url: "https://img0.baidu.com/it/u=3120426427,3366290321&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500"
                    },
                    {
                        id: 114,
                        label: "附件4",
                        fileType: "image",
                        url: "https://img2.baidu.com/it/u=2143583675,3369511440&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=380"
                    },
                    {
                        id: 115,
                        label: "附件5",
                        fileType: "image",
                        url: "https://sc.68design.net/articlefiles/201401/m6170lWQmz.jpg"
                    },
                    {
                        id: 116,
                        label: "附件6",
                        fileType: "image",
                        url: "https://img0.baidu.com/it/u=3703508522,3487723650&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500"
                    },
                    {
                        id: 117,
                        label: "附件7",
                        fileType: "image",
                        url: "https://pic.birdnet.cn/forum/201506/24/060539zv9fm2eveeekmzwk.jpg"
                    },


                ]
            },
            {
                id: 3,
                label: "仲裁申请书",
                children: []
            },
            {
                id: 4,
                label: "仲裁协议",
                children: []
            },
            {
                id: 5,
                label: "申请人受理通知书、举证通知书",
                children: []
            },
            {
                id: 6,
                label: "预收仲裁费收据",
                children: []
            },
            {
                id: 7,
                label: "减、免、缓交仲裁费手续",
                children: []
            },
            {
                id: 8,
                label: "被申请人受理通知书、举证通知书",
                children: []
            },
            {
                id: 9,
                label: "管辖异议书",
                children: []
            },
            {
                id: 10,
                label: "答辩书",
                children: []
            },
            {
                id: 11,
                label: "反请求申请书",
                children: []
            },
            {
                id: 12,
                label: "法定代表人身份证明及授权委托书",
                children: []
            },
            {
                id: 13,
                label: "保全材料",
                children: []
            },
            {
                id: 14,
                label: "证据材料",
                children: []
            },
            {
                id: 15,
                label: "仲裁员选定书、指定仲裁员委托书",
                children: []
            },
            {
                id: 16,
                label: "仲裁员指定书",
                children: []
            },
            {
                id: 17,
                label: "组庭通知书",
                children: []
            },
            {
                id: 18,
                label: "询问、调查笔录",
                children: []
            },
            {
                id: 19,
                label: "鉴定委托书及鉴定结论",
                children: []
            },
            {
                id: 20,
                label: "开庭通知书或书面仲裁协议",
                children: []
            },
            {
                id: 21,
                label: "仲裁庭开庭笔录",
                children: []
            },
            {
                id: 22,
                label: "代理词",
                children: []
            },
            {
                id: 23,
                label: "调解笔录",
                children: []
            },
            {
                id: 24,
                label: "调解协议",
                children: []
            },
            {
                id: 25,
                label: "撤回仲裁申请书",
                children: []
            },
            {
                id: 26,
                label: "裁决书、调解书、决定书正本",
                children: []
            },
            {
                id: 27,
                label: "送达回证",
                children: []
            },
            {
                id: 28,
                label: "仲裁费收据",
                children: []
            },
            {
                id: 29,
                label: "备考表",
                children: []
            },
        ]
    },
    {
        id: 21 ,
        label: "副卷",
        children:[
            {
                id: 211 ,
                label: "组庭审批表",
            },
            {
                id: 212 ,
                label: "仲裁庭阅卷笔录",
            },
            {
                id: 213 ,
                label: "仲裁庭审查报告",
            },
            {
                id: 214 ,
                label: "仲裁庭与有关部门内部交换意见的材料",
            },
            {
                id: 215 ,
                label: "仲裁庭开庭提纲",
            },
            {
                id: 216 ,
                label: "仲裁庭评议案件笔录",
            },
            {
                id: 217 ,
                label: "仲裁庭研究、汇报案件记录",
            },
            {
                id: 218 ,
                label: "案件评议委员会讨论记录",
            },
            {
                id: 219 ,
                label: "仲裁庭结案报告",
            },
            {
                id: 220 ,
                label: "裁决书、调解书、决定书原本",
            },
            {
                id: 221 ,
                label: "仲裁员报酬领取单据",
            },
            {
                id: 222 ,
                label: "其他不宜对外公开的材料",
            },
            {
                id: 223 ,
                label: "备考表",
            },
        ]
    }

];