<template>
  <div>
    <loaders ref="loadersRef"></loaders>
    <div class="mask"></div>
    <div class="mskbana">
      <div class="gb" @click="close()"></div>
      <div class="tit">
        <h2 v-if="this.caseType2=='shouan'">收案数据展示</h2>
        <h2 v-if="this.caseType2=='jiean'">结案数据展示</h2>
        <h2 v-if="this.caseType2=='weijie'">未结案数据展示</h2>
      </div>
      <div class="chax">
        <div class="lt">
          <form action="" method="post">
            <input id="" v-model="inquireForm.text" class="t1" name="" placeholder="请输入关键字搜索" type="text">
            <input class="sub" disabled style="cursor: default;" type="submit" value=""/>
          </form>
        </div>
        <div class="rt layui-form">
          <dl id="test6" class="test6">
            <dd>
              <div class="bgs">
                <el-date-picker
                    v-model="inquireForm.startTime"
                    align="right"
                    class="lsele"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="开始日期"
                    type="date">
                </el-date-picker>
              </div>
            </dd>
            <dd><span></span></dd>
            <dd>
              <div class="bgs">
                <el-date-picker
                    v-model="inquireForm.endTime"
                    align="right"
                    class="lsele"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="结束日期"
                    type="date">
                </el-date-picker>
              </div>
            </dd>
          </dl>
          <a class="btn" href="#" @click="inquire(inquireForm)"><span>查询</span></a>
          <!--          <button @click="reset">
                      重置
                    </button>-->
        </div>
        <button class="cssbuttons-io-button" @click="exportExcel(caseType2)">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path
                d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"
                fill="currentColor"></path>
          </svg>
          <span>导出</span>
        </button>

      </div>
      <div class="uittab">
        <div class="tps">
          <h3>序号</h3>
          <h3>案号</h3>
          <h3>立案日期</h3>
          <h3>申请人</h3>
          <h3>被申请人</h3>
          <h3>案由</h3>
          <!--          <h3>数据来源</h3>-->
          <h3>承办人</h3>
          <!--          <h3 v-if="this.caseType2=='shouan'">当前环节</h3>-->
          <h3>是否结案</h3>
          <h3>结案时间</h3>
          <h3>标的额</h3>

        </div>
        <dl>
          <dd v-for="(item,index) in tableData">
            <h4>{{ item.order }}</h4>
            <h4>{{ item.caseYearNum }}-{{ item.caseSerialNumber }}</h4>
            <h4>{{ item.liAnDate }}</h4>
            <h4>{{ item.sqrStr }}</h4>
            <h4>{{ item.bsqrStr }}</h4>
            <h4>{{ item.caseReasonName }}</h4>
            <!--            <h4 v-if="item.dataType=='0' ">立案</h4>
                        <h4 v-else-if="item.dataType=='1' ">办案</h4>-->
            <h4>{{ item.appointSecretary.user.name }}</h4>
            <!--            <h4 v-if="this.caseType2=='shouan'">{{ item.act.taskName }}</h4>-->
            <h4 v-if="item.jieanType=='0' ">裁决结案</h4>
            <h4 v-if="item.jieanType=='1' ">调解结案</h4>
            <h4 v-if="item.jieanType=='2' ">撤案结案</h4>
            <h4 v-if="item.jieanType=='3' ">未结案</h4>
            <h4 v-if="item.jieanType=='4' ">管辖异议成立结案</h4>
            <h4>{{ item.jieanDate }}</h4>
            <h4>{{ item.biaoDiE }}</h4>

          </dd>
        </dl>
      </div>
      <div class="paginationCss">
        <el-pagination
            :current-page="pagination.currentPage"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            layout="total, prev, pager, next"
            @prev-click="prevPage"
            @next-click="nextPage"
            @current-change="PageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "centerComponent",
  components: {},
  props: {
    selectedyear: Number
  },
  data() {
    return {
      type: '',
      inquireForm: {
        text: '',
        startTime: '',
        endTime: '',
      },
      data: null,
      caseType2: '',
      pagination: {
        type: '',
        total: 0,
        currentPage: 1,
        pageSize: 10
      },
      tableData: []
    }
  },
  computed: {},
  watch: {
    'pagination.type': function (newType, oldType) {
      this.pagination.currentPage = 1;
    }
  },
  methods: {
    async opens(item, caseType) {
      await this.waitForLoaders();
      this.data = item;
      this.caseType2 = caseType;
      this.init(caseType);
    },
    waitForLoaders() {
      return new Promise(resolve => {
        const checkExist = setInterval(() => {
          if (this.$refs.loadersRef) {
            clearInterval(checkExist);
            resolve();
          }
        }, 100); // 检查间隔
      });
    },
    close() {
      /*关闭时应该清除查询参数*/
      this.clearData();
      this.$parent.showCenter(false);
    },
    clearData() {
      this.type = '';
      this.data = null;
      this.pagination.type = '';
      this.pagination.total = 0;
      this.pagination.currentPage = 0;
      this.tableData = [];
      this.inquireForm.startTime = '';
      this.inquireForm.endTime = '';
      this.inquireForm.text = '';
    },
    onEscKey(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
    inquire(form) {

        this.$refs.loadersRef.show();
        this.tableData = [];
        this.pagination.type = 'inquire';
        let centerStr = '';
        if (this.caseType2 == "shouan") {
          centerStr = '/pingZhan/center/shouanbypara';
        } else if (this.caseType2 == "jiean") {
          centerStr = '/pingZhan/center/jieanbypara';
        } else if (this.caseType2 == "weijie") {
          centerStr = '/pingZhan/center/weijiepara';
        }
        this.axios.get(centerStr, {
          params: {
            currentYear: this.selectedyear,
            selectStartDate: this.inquireForm.startTime,
            selectEndDate: this.inquireForm.endTime,
            text: this.inquireForm.text,
            current: this.pagination.currentPage,
            pageSize: 10,
          }
        }).then((response) => {
          let data = response.data;
          if (data.code == 200) {
            this.tableData = data.data.list.map((item, index) => ({
              order: (this.pagination.currentPage - 1) * this.pagination.pageSize + (index + 1),
              ...item
            }));
            this.pagination.total = data.data.total;
          } else {
            console.log('数据获取失败');
          }
          this.$refs.loadersRef.hide();
        }).catch((error) => {
          console.error('发生错误:', error);
        });
    },
    init(caseType) {
      this.pagination.type = 'init';
      if (caseType == "shouan") {
        this.$refs.loadersRef.show();
        this.axios.get('/pingZhan/center/shouan', {
          params: {
            currentYear: this.selectedyear,
            current: this.pagination.currentPage,
            pageSize: 10,
          }
        }).then((response) => {
          let data = response.data;
          if (data.code == 200) {
            this.tableData = data.data.list.map((item, index) => ({
              order: (this.pagination.currentPage - 1) * this.pagination.pageSize + (index + 1),
              ...item
            }));
            this.pagination.total = data.data.total;
          } else {
            console.log('数据获取失败');
          }
          this.$refs.loadersRef.hide();
        }).catch((error) => {
          console.error('发生错误:', error);
          //this.$refs.loadersRef.hide();
        });
      }
      if (caseType == "jiean") {
        this.$refs.loadersRef.show();
        this.axios.get('/pingZhan/center/jiean', {
          params: {
            currentYear: this.selectedyear,
            current: this.pagination.currentPage,
            pageSize: 10,
          }
        }).then((response) => {
          let data = response.data;
          //console.log("data=" + data)
          if (data.code == 200) {
            this.tableData = data.data.list.map((item, index) => ({
              order: (this.pagination.currentPage - 1) * this.pagination.pageSize + (index + 1),
              ...item
            }));
            this.pagination.total = data.data.total;
          } else {
            console.log('数据获取失败');
          }
          this.$refs.loadersRef.hide();
        }).catch((error) => {
          console.error('发生错误:', error);
          this.$refs.loadersRef.hide();
        });
      }
      if (caseType == "weijie") {
        this.$refs.loadersRef.show();
        this.axios.get('/pingZhan/center/weijie', {
          params: {
            currentYear: this.selectedyear,
            current: this.pagination.currentPage,
            pageSize: 10,
          }
        }).then((response) => {
          let data = response.data;
          console.log("data=" + data)
          if (data.code == 200) {
            this.tableData = data.data.list.map((item, index) => ({
              order: (this.pagination.currentPage - 1) * this.pagination.pageSize + (index + 1),
              ...item
            }));
            this.pagination.total = data.data.total;
          } else {
            console.log('数据获取失败');
          }
          this.$refs.loadersRef.hide();
        }).catch((error) => {
          console.error('发生错误:', error);
          this.$refs.loadersRef.hide();
        });
      }
    },
    prevPage(val) {
      if (this.pagination.currentPage > 1) {
        this.pagination.currentPage--;
        if (this.pagination.type === 'init') {
          this.init(this.caseType2); // 如果是初始化，调用 init 方法
        } else {
          this.inquire(this.inquireForm); // 否则调用 inquire 方法
        }
      }
    },
    nextPage(val) {
      if (this.pagination.currentPage < Math.ceil(this.pagination.total / this.pagination.pageSize)) {
        this.pagination.currentPage++;
        if (this.pagination.type === 'init') {
          this.init(this.caseType2); // 如果是初始化，调用 init 方法
        } else {
          this.inquire(this.inquireForm); // 否则调用 inquire 方法
        }
      }
    },
    PageChange(val) {
      if (val !== this.pagination.currentPage) {
        this.pagination.currentPage = val;
        if (this.pagination.type === 'init') {
          this.init(this.caseType2); // 如果是初始化，调用 init 方法
        } else {
          this.inquire(this.inquireForm); // 否则调用 inquire 方法
        }
      }
    },
    reset() {
      this.inquireForm = {
        text: '',
        startTime: '',
        endTime: ''
      };
      this.init(this.caseType2)
    },
    exportExcel(caseType) {
      this.$refs.loadersRef.show();
      var urlStr = '';
      if (caseType == "weijie") {
        urlStr = '/pingZhan/CenterExport/weijie';
      } else if (caseType == "jiean") {
        urlStr = '/pingZhan/CenterExport/jiean';
      } else if (caseType == "shouan") {
        urlStr = '/pingZhan/CenterExport/shouan';
      } else {
        console.log('参数错误');
        return;
      }
      this.axios.get(urlStr, {
        params: {
          text: this.inquireForm.text,
          selectStartDate: this.inquireForm.startTime,
          selectEndDate: this.inquireForm.endTime,
          currentYear: this.selectedyear,
          isExport: 1,
          exportType: this.pagination.type
        },
        responseType: 'blob' // 指定响应类型为二进制数据
      }).then((response) => {
        this.$refs.loadersRef.hide();
        if (response.status === 200) {
          // 创建一个链接
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.style.display = 'none';

          // 获取当前时间并将其格式化为 "yyyy-MM-dd HH:mm"
          const currentTime = new Date();
          const formattedTime = currentTime.toLocaleString().replace(/:|\s/g, '-');

          a.download = formattedTime + '.xlsx';

          a.href = url;
          document.body.appendChild(a);
          a.click(); // 触发下载
          window.URL.revokeObjectURL(url); // 释放 URL 对象
          this.$message({
            message: '导出成功！',
            type: 'success'
          });
        } else {
          console.log('数据获取失败');
        }
      }).catch((error) => {
        console.error('发生错误:', error);
      });

    },
    created() {
    },
    mounted() {
      // 添加 ESC 键盘事件监听器
      window.addEventListener('keydown', this.onEscKey);

    },
    beforeDestroy() {
      // 移除 ESC 键盘事件监听器以防止内存泄漏
      window.removeEventListener('keydown', this.onEscKey);
    }
  },
}
</script>

<style scoped>
/*时间选择框*/
.el-input >>> .el-input__inner {
  background-color: #fff0;
  border: 1px solid #ffffff00;
  color: #1bceed;
}

.paginationCss {
  text-align: center;
  margin-top: 1.5%;
}

/*修改分页器样式*/
.el-pagination >>> .btn-next, .el-pagination >>> .btn-prev {
  background: center center no-repeat #fff0;
  background-size: 16px;
  cursor: pointer;
  margin: 0;
  color: #ffffff;
}

.el-pagination button:disabled {
  color: #ffffff;
  background-color: #fff0;
  cursor: not-allowed;
}

.el-pagination >>> .el-dialog, .el-pagination >>> .el-pager li {
  background: #fff0;
  -webkit-box-sizing: border-box;
}

.el-pagination >>> .el-pagination__total {
  color: #ffffff;
}

.el-pagination >>> .el-pager {
  color: #ffffff;
}


/*重置按钮*/
button {
  padding: 0px 6px;
  border: 0;
  border-radius: 100px;
  background-color: #2ba8fb;
  color: #ffffff;
  font-weight: Bold;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

button:hover {
  background-color: #6fc5ff;
  box-shadow: 0 0 20px #6fc5ff50;
  transform: scale(1.1);
}

button:active {
  background-color: #3d94cf;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  box-shadow: none;
  transform: scale(0.98);
}

/*导出*/
.cssbuttons-io-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  padding: 0.35em 0.5em;
  color: white;
  background: linear-gradient(0deg, rgb(77, 54, 208) 0%, rgb(132, 116, 254) 100%);
  border: none;
  box-shadow: rgba(77, 54, 208, 0.745) 0px 0.7em 1.5em -0.5em;
  letter-spacing: 0.05em;
  border-radius: 0.3em;
  margin-left: 1vh;
}

.cssbuttons-io-button svg {
  margin-right: 8px;
}

.cssbuttons-io-button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #4d36d0be;
}

.cssbuttons-io-button:active {
  box-shadow: 0 0.3em 1em -0.5em #4d36d0be;
}
</style>
