export const componentsConfig = [
    {name: "Basic", section: "jiben", number: 1, title: "案件基本信息"},
    {name: "Proposer", section: "sqr", number: 2, title: "申请人信息"},
    {name: "Respondent", section: "bsqr", number: 3, title: "被申请人信息"},
    {name: "change", section: "biangeng", number: 4, title: "变更请求信息"},
    {name: "Counterclaim", section: "fanqingqiu", number: 5, title: "反请求信息"},
    {name: "CounterclaimChange", section: "fanqingqiubg", number: 6, title: "反请求变更请求信息"},
    {name: "Group", section: "zuting", number: 7, title: "组庭信息"},
    {name: "Court", section: "kaiting", number: 8, title: "开庭信息"},
    {name: "Suspend", section: "zhongzhi", number: 9, title: "中止"},
    {name: "Authenticate", section: "jianding", number: 10, title: "鉴定"},
    {name: "Postpone", section: "yanqi", number: 11, title: "延期"},
    {name: "CheckAndApprove", section: "heyue", number: 12, title: "核阅"},
    {name: "Closing", section: "jiean", number: 13, title: "结案信息"},
    {name: "Delivery", section: "songda", number: 14, title: "送达"},
    {name: "Preserve", section: "baoquan", number: 15, title: "保全信息"},
    {name: "Execute", section: "zhixing", number: 16, title: "执行信息"},
    {name: "Repeal", section: "byzx", number: 17, title: "被撤销或不予执行"},
];