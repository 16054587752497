<template>
  <div class="wenl" v-if="user">
    <ul>
      <li v-for="(item, index) in items" :key="index" :class="{ on: index === activeIndex }"
          @click="setActive(item.caseNumber,index)">
        <a href="#" :style="{ height: item.caseNumber === '身份证' ? '150px' : '' }">
          <h3>{{ item.caseNumber }}</h3>
          <dl>
            <!-- 照片 -->
            <template v-if="item.caseNumber === '照片'">
              <el-tag class="tagc"
                      :class="{ 'disabled': !user.photo_url }"
                      @click="user.professional_url ?downloadFile(user.photo_url,'照片'):null">下载
              </el-tag>
              <dd><img alt="" :src="item.icon3">{{ item.date }}</dd>
            </template>

            <!-- 身份证 -->
            <template v-else-if="item.caseNumber === '身份证'">
              <el-tag class="tagc"
                      :class="{ 'disabled': !user.idphoto_urlup || !user.idphoto_urldown }"
                      @click.stop="user.idphoto_urlup && user.idphoto_urldown ? downloadIdCard(user.idphoto_urlup, user.idphoto_urldown, '身份证') : null">
                下载
              </el-tag>
              <dd @click.stop="viewIdCard(user.idphoto_urlup, '身份证正面')"><img alt="身份证正面" :src="item.icon1">正面
              </dd>
              <dd @click.stop="viewIdCard(user.idphoto_urldown, '身份证反面')"><img alt="身份证反面" :src="item.icon2">反面
              </dd>
              <dd><img alt="" :src="item.icon3">{{ item.date }}</dd>
            </template>

            <!-- 职称证 -->
            <template v-else-if="item.caseNumber === '职称证'">
              <el-tag class="tagc"
                      :class="{ 'disabled': !user.professional_url }"
                      @click="user.professional_url ? downloadFile(user.professional_url,'职称证'): null">
                下载
              </el-tag>
              <dd><img alt="" :src="item.icon1">职称</dd>
              <dd><img alt="" :src="item.icon3">{{ item.date }}</dd>
            </template>

            <!-- 司法证 -->
            <template v-else-if="item.caseNumber === '司法证'">
              <el-tag class="tagc"
                      :class="{ 'disabled': !user.judicature_url }"
                      @click="user.judicature_url ? downloadFile(user.judicature_url, '司法证') : null">
                下载
              </el-tag>
              <dd><img alt="" :src="item.icon3">{{ item.date }}</dd>
            </template>
          </dl>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "left",
  props: {
    user: Object
  },
  data() {
    return {
      activeIndex: 0, // 初始激活的索引
      items: [
        {
          caseNumber: '照片',
          icon1: require('@/assets/image/zcy/kico7.png'),
          icon2: require('@/assets/image/zcy/kico8.png'),
          icon3: require('@/assets/image/zcy/kico9.png'),
          date: '2023-12-08 13:50:12'
        },
        {
          caseNumber: '身份证',
          icon1: require('@/assets/image/zcy/kico7.png'),
          icon2: require('@/assets/image/zcy/kico8.png'),
          icon3: require('@/assets/image/zcy/kico9.png'),
          date: '2023-12-08 13:50:12'
        },
        {
          caseNumber: '职称证',
          icon1: require('@/assets/image/zcy/kico7.png'),
          icon2: require('@/assets/image/zcy/kico8.png'),
          icon3: require('@/assets/image/zcy/kico9.png'),
          date: '2023-12-08 13:50:12'
        },
        {
          caseNumber: '司法证',
          icon1: require('@/assets/image/zcy/kico7.png'),
          icon2: require('@/assets/image/zcy/kico8.png'),
          icon3: require('@/assets/image/zcy/kico9.png'),
          date: '2023-12-08 13:50:12'
        },
      ],
    };
  },
  watch: {
    // 监视 user 对象的变化
    user: {
      deep: true,
      handler(newValue, oldValue) {
        this.setActive(this.items[0].caseNumber, 0);
      }
    }
  },
  methods: {
    setActive(item, index) {
      //console.log(item);
      this.activeIndex = index;
      switch (item) {
        case '照片':
          this.photo(this.user.photo_url); /* 照片的 URL */
          break;
        case '身份证':
          this.viewIdCard(this.user.idphoto_urlup, this.user.idphoto_urldown); /* 身份证的 URL */
          break;
        case '职称证':
          this.professional(this.user.professional_url); /* 职称证的 URL */
          break;
        case '司法证':
          this.judicature(this.user.judicature_url); /* 司法证的 URL */
          break;
      }
    },
    photo(url) {
      this.loadImage(url, '照片');
    },
    viewIdCard(urlUp, urlDown) {
      this.loadImage(urlUp, '身份证正面');
      this.idCardUrls = {
        front: urlUp,
        back: urlDown
      };
    },
    professional(url) {
      this.loadImage(url, '职称证');
    },
    judicature(url) {
      this.loadImage(url, '司法证');
    },
    loadImage(url, type) {
      this.axios.get('/pingZhan/inquire/zcyPhotoById', {
        params: {
          url
        }
      }).then(response => {
        let data = response.data;
        if (data.code == 200) {
          this.updateIframeUrl(data.data.server_url); // 直接调用 updateIframeUrl
          //this.updateIframeUrl("https://img0.baidu.com/it/u=3232582821,3516640051&fm=253&fmt=auto&app=138&f=JPEG?w=625&h=500"); // 直接调用 updateIframeUrl
        } else {
          this.handleError(type);
        }
      }).catch(error => {
        console.error(error);
        this.handleError(type);
      });
    },
    updateIframeUrl(url) {
      this.$emit('update-iframe', url);
    },
    handleError(type) {
      this.$message.error(`暂无${type}图片`);
    },
    downloadIdCard(urlUp, urlDown, filename) {
      // 处理下载身份证正反面的逻辑
      this.downloadFile(urlUp, filename + '正面.png');
      this.downloadFile(urlDown, filename + '反面.png');
    },
    downloadFile(url, type) {
      this.axios.get('/pingZhan/inquire/zcyPhotoById', {
        params: {
          url
        }
      }).then(response => {
        let data = response.data;
        if (data.code == 200) {
          const downloadUrl = "https://img0.baidu.com/it/u=3232582821,3516640051&fm=253&fmt=auto&app=138&f=JPEG?w=625&h=500"; // 获取的 URL
          const filename = type + '.png'; // 根据 type 构建文件名
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.handleError(type);
        }
      }).catch(error => {
        console.error(error);
        this.handleError(type);
      });
    }
  },
}
</script>

<style scoped>
.tagc {
  position: relative;
  left: 22vh;
  bottom: 3.5vh;
}

.tagc.disabled {
  /* 禁用状态的样式 */
  cursor: not-allowed;
  opacity: 0.5;
}
</style>