<template>
  <div class="item" v-if="user">
    <div class="he">
      <h3>单位信息</h3>
    </div>
    <ul class="ul1">
      <li>工作单位： <i>{{displayValue('work_unit')}}</i></li>
      <li>单位性质： <i>{{unitNatureText}}</i></li>
      <li>本职岗位： <i>{{displayValue('work_own')}}</i></li>
      <li>工作地址： <i>{{displayValue('work_address')}}</i></li>
      <li>单位固定电话： <i>{{displayValue('unit_fixedtelephone')}}</i></li>
      <li>工作语言： <i>{{displayValue('yuyan')}}</i></li>
      <li style="width: 100%;">单位介绍： <i v-html="user.company_profile || '暂无'"></i></li>

    </ul>
  </div>
</template>

<script>
export default {
  name: "unit",
  props:{
    user:Object
  },
  methods:{
    displayValue(field) {
      return this.user[field] ? this.user[field] : '暂无';
    },
  },
  computed:{
    unitNatureText() {
      const unitNatureMap = {
        '0': '省直机关',
        '1': '市直机关',
        '2': '企业',
        '3': '教育机构',
        '4': '医院',
        '5': '律所',
        '6': '银行',
        '7': '保险公司',
        '8': '其他',
      };
      return this.user.unit_nature in unitNatureMap ? unitNatureMap[this.user.unit_nature] : '暂无';
    },
  }

}
</script>

<style scoped>

</style>