<template>
  <div id="page2">
    <div style="width:100%">
      <div style="width:80%; margin-left :auto ; margin-right:auto;">
        <el-table :data="tableData" border height="60.5vh" style="width: 100%;"
                  width="90%">
          <el-table-column align="center" label="文件名称" prop="fileName"></el-table-column>
          <el-table-column align="center" label="文件大小" prop="fileSize2">
            <template v-slot="{ row }">
              <template v-if="row.fileSize < 1024">
                {{ row.fileSize }}B
              </template>
              <template v-else-if="row.fileSize >= 1024 && row.fileSize < 1024 * 1024">
                {{ (row.fileSize / 1024) }}.0KB
              </template>
              <template v-else-if="row.fileSize >= 1024 * 1024 && row.fileSize < 1024 * 1024 * 1024">
                {{ (row.fileSize / 1024 / 1024) }}.0MB
              </template>
              <template v-else-if="row.fileSize >= 1024 * 1024 * 1024 && row.fileSize < 1024 * 1024 * 1024 * 1024">
                {{ (row.fileSize / 1024 / 1024 / 1024) }}.0GB
              </template>
              <template
                  v-else-if="row.fileSize >= 1024 * 1024 * 1024 * 1024 && row.fileSize < 1024 * 1024 * 1024 * 1024 * 102">
                {{ (row.fileSize / 1024 / 1024 / 1024 / 1024) }}.0TB
              </template>
              <template v-else>
                {{ row.fileSize }}B
              </template>
            </template>
          </el-table-column>
          <el-table-column align="center" label="创建人" prop="createByname"></el-table-column>
          <el-table-column align="center" label="创建时间" prop="createDate"></el-table-column>
          <el-table-column align="center" label="操作" prop="caozuo">
            <template v-slot="{ row }">
              <el-link type="primary" @click="open('',row.id)">查看</el-link>
            </template>
          </el-table-column>
        </el-table>

      </div>
    </div>


  </div>


</template>

<script>


export default {
  name: "zcy_wjscjl",
  props: {
    caseInfoId_s: '',
    lhkhWod: ''
  },
  components: {},

  data() {
    return {
      tableData: null,// 存放获取到的数据
    }
  },
  //方法集合
  methods: {

    init() {
      this.startLoading();
      // alert("=="+this.lhkhWod);
      this.axios.get('/pingZhan/assess/getwjscjl', {
        params: {
          wodId: this.lhkhWod
        }
      }).then(response => {
        this.Loading.close();
        const data = response.data;
        if (data.code == 200) {
          this.tableData = data.data.wjscjl.map(item =>
              ({
                fileName: item.fileName,
                createByname: item.createByname,
                createDate: item.createDate,
                id: item.id,
                fileSize: item.fileSize,
              })
          );
        } else {
          this.remind(data.message);
          this.$emit("fatherMethod", "");
        }
      }).catch(error => {
        this.remind('发生错误:', error);
        this.Loading.close();
        this.$emit("fatherMethod", "");
        console.error('发生错误:', error);
      })

    },


    remind(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'warning'
      });
    },
    startLoading() {
      this.Loading = this.$loading({
        lock: false,
        text: '正在加载中...',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.6)'//遮罩背景色

      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    //alert(this.caseInfoId_s);
    this.init()
  }
}

</script>

<style scoped>

</style>