<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>送达</h3>
    </div>
    <span class="bolder-and-larger-text" style="width: 100%;">受理文书：</span>
    <span v-if="sjwssd!=''">
    <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
              :data="sjwssd" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
              stripe
              style="width: 100%">
      <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
      <el-table-column align="center" label="送达次数" prop="sendNum" width="50"></el-table-column>
      <el-table-column align="center" label="送达类型" prop="sdTypeName"></el-table-column>
      <el-table-column align="center" label="被送达人" prop="dsrFlag"></el-table-column>
      <el-table-column align="center" label="被送达人名称" prop="dsrName"></el-table-column>
      <el-table-column align="center" label="寄出时间" prop="beginTime"></el-table-column>
      <el-table-column align="center" label="送达形式" prop="sdxsName"></el-table-column>
      <el-table-column align="center" label="单号" prop="danhao"></el-table-column>
      <el-table-column align="center" label="送达结果" prop="sdjg"></el-table-column>
      <el-table-column align="center" label="送达时间" prop="endTime"></el-table-column>
      <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
    </el-table>
      </span>
    <span v-else>
      (暂无信息)
    </span>
    <br> <br>
    <span class="bolder-and-larger-text" style="width: 100%;">组庭文书：</span>
    <span v-if="ztwssd!=''">
    <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
              :data="ztwssd" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
              stripe
              style="width: 100%">
      <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
      <el-table-column align="center" label="送达次数" prop="sendNum" width="50"></el-table-column>
      <el-table-column align="center" label="送达类型" prop="sdTypeName"></el-table-column>
      <el-table-column align="center" label="被送达人" prop="dsrFlag"></el-table-column>
      <el-table-column align="center" label="被送达人名称" prop="dsrName"></el-table-column>
      <el-table-column align="center" label="寄出时间" prop="beginTime"></el-table-column>
      <el-table-column align="center" label="送达形式" prop="sdxsName"></el-table-column>
      <el-table-column align="center" label="单号" prop="danhao"></el-table-column>
      <el-table-column align="center" label="送达结果" prop="sdjg"></el-table-column>
      <el-table-column align="center" label="送达时间" prop="endTime"></el-table-column>
      <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
    </el-table>
      </span>
    <span v-else>
      (暂无信息)
    </span>
    <br> <br>
    <span class="bolder-and-larger-text" style="width: 100%;">开庭文书：</span>
    <span v-if="ktwssd!=''">
    <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
              :data="ktwssd" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
              stripe
              style="width: 100%">
      <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
      <el-table-column align="center" label="送达次数" prop="sendNum" width="50"></el-table-column>
      <el-table-column align="center" label="送达类型" prop="sdTypeName"></el-table-column>
      <el-table-column align="center" label="被送达人" prop="dsrFlag"></el-table-column>
      <el-table-column align="center" label="被送达人名称" prop="dsrName"></el-table-column>
      <el-table-column align="center" label="寄出时间" prop="beginTime"></el-table-column>
      <el-table-column align="center" label="送达形式" prop="sdxsName"></el-table-column>
      <el-table-column align="center" label="单号" prop="danhao"></el-table-column>
      <el-table-column align="center" label="送达结果" prop="sdjg"></el-table-column>
      <el-table-column align="center" label="送达时间" prop="endTime"></el-table-column>
      <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
    </el-table>
      </span>
    <span v-else>
      (暂无信息)
    </span>
    <br> <br>
    <span class="bolder-and-larger-text" style="width: 100%;">裁决文书：</span>
    <span v-if="cjwssd!=''">
    <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
              :data="cjwssd" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
              stripe
              style="width: 100%">
      <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
      <el-table-column align="center" label="送达次数" prop="sendNum" width="50"></el-table-column>
      <el-table-column align="center" label="送达类型" prop="sdTypeName"></el-table-column>
      <el-table-column align="center" label="被送达人" prop="dsrFlag"></el-table-column>
      <el-table-column align="center" label="被送达人名称" prop="dsrName"></el-table-column>
      <el-table-column align="center" label="寄出时间" prop="beginTime"></el-table-column>
      <el-table-column align="center" label="送达形式" prop="sdxsName"></el-table-column>
      <el-table-column align="center" label="单号" prop="danhao"></el-table-column>
      <el-table-column align="center" label="送达结果" prop="sdjg"></el-table-column>
      <el-table-column align="center" label="送达时间" prop="endTime"></el-table-column>
      <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
    </el-table>
      </span>
    <span v-else>
      (暂无信息)
    </span>
    <br> <br>
    <span class="bolder-and-larger-text" style="width: 100%;">调解书：</span>
    <span v-if="tjwssd!=''">
    <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
              :data="tjwssd" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
              stripe
              style="width: 100%">
      <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
      <el-table-column align="center" label="送达次数" prop="sendNum" width="50"></el-table-column>
      <el-table-column align="center" label="送达类型" prop="sdTypeName"></el-table-column>
      <el-table-column align="center" label="被送达人" prop="dsrFlag"></el-table-column>
      <el-table-column align="center" label="被送达人名称" prop="dsrName"></el-table-column>
      <el-table-column align="center" label="寄出时间" prop="beginTime"></el-table-column>
      <el-table-column align="center" label="送达形式" prop="sdxsName"></el-table-column>
      <el-table-column align="center" label="单号" prop="danhao"></el-table-column>
      <el-table-column align="center" label="送达结果" prop="sdjg"></el-table-column>
      <el-table-column align="center" label="送达时间" prop="endTime"></el-table-column>
      <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
    </el-table>
      </span>
    <span v-else>
      (暂无信息)
    </span>
    <br> <br>
    <span class="bolder-and-larger-text" style="width: 100%;">撤案决定书：</span>
    <span v-if="cajdwssd!=''">
    <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
              :data="cajdwssd" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
              stripe
              style="width: 100%">
      <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
      <el-table-column align="center" label="送达次数" prop="sendNum" width="50"></el-table-column>
      <el-table-column align="center" label="送达类型" prop="sdTypeName"></el-table-column>
      <el-table-column align="center" label="被送达人" prop="dsrFlag"></el-table-column>
      <el-table-column align="center" label="被送达人名称" prop="dsrName"></el-table-column>
      <el-table-column align="center" label="寄出时间" prop="beginTime"></el-table-column>
      <el-table-column align="center" label="送达形式" prop="sdxsName"></el-table-column>
      <el-table-column align="center" label="单号" prop="danhao"></el-table-column>
      <el-table-column align="center" label="送达结果" prop="sdjg"></el-table-column>
      <el-table-column align="center" label="送达时间" prop="endTime"></el-table-column>
      <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
    </el-table>
      </span>
    <span v-else>
      (暂无信息)
    </span>
    <br> <br>
    <span class="bolder-and-larger-text" style="width: 100%;">效力异议决定书：</span>
    <span v-if="xlyyjdwssd!=''">
    <el-table :cell-style="{background:'#fff',color: '#7883c5'}"
              :data="xlyyjdwssd" :header-cell-style="{ background:'#F5F7FA',color: '#3d4053'}" border
              stripe
              style="width: 100%">
      <el-table-column align="center" label="序号" prop="xh" width="50"></el-table-column>
      <el-table-column align="center" label="送达次数" prop="sendNum" width="50"></el-table-column>
      <el-table-column align="center" label="送达类型" prop="sdTypeName"></el-table-column>
      <el-table-column align="center" label="被送达人" prop="dsrFlag"></el-table-column>
      <el-table-column align="center" label="被送达人名称" prop="dsrName"></el-table-column>
      <el-table-column align="center" label="寄出时间" prop="beginTime"></el-table-column>
      <el-table-column align="center" label="送达形式" prop="sdxsName"></el-table-column>
      <el-table-column align="center" label="单号" prop="danhao"></el-table-column>
      <el-table-column align="center" label="送达结果" prop="sdjg"></el-table-column>
      <el-table-column align="center" label="送达时间" prop="endTime"></el-table-column>
      <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
    </el-table>
      </span>
    <span v-else>
      (暂无信息)
    </span>

  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "delivery",
  props: {
    bananId: String,
    caseNum: String
  },
  data() {
    return {
      deliveryList: null,
      sjwssd: [],
      ztwssd: [],
      ktwssd: [],
      cjwssd: [],
      tjwssd: [],
      cajdwssd: [],
      xlyyjdwssd: [],
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.execute_init();
        }
      }
    },
    deliveryList(newVal) {
      if (newVal) {
        const hasData = newVal && newVal.length > 0;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    execute_init() {
      this.axios.get('/api/handling/delivery', {
        params: {
          // id: '00c9b06cd63a40bb91fcd63c539cea86'
          id: this.bananId
        }
        //params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        if (data.code == 200) {
          this.tableData(data.data.sjwssd, "sjwssd");
          this.tableData(data.data.ztwssd, "ztwssd");
          this.tableData(data.data.ktwssd, "ktwssd");
          this.tableData(data.data.cjwssd, "cjwssd");
          this.tableData(data.data.tjwssd, "tjwssd");
          this.tableData(data.data.cajdwssd, "cajdwssd");
          this.tableData(data.data.xlyyjdwssd, "xlyyjdwssd");
        }
      }).catch(error => {
        console.log(error);
      });
    },
    tableData(data, dataname) {
      this.deliveryList = data;
      this.deliveryList.forEach((item) => {
        let params = {};
        params.xh = item.xh || '无';
        params.sendNum = item.sendNum || '无';
        params.sdTypeName = item.sdTypeName || '无';
        params.dsrFlag = this.bsdr(item.dsrFlag) || '无';
        params.dsrName = item.dsrType == "0" ? item.dsrName : item.danweiName || '无';
        params.beginTime = item.beginTime != null ? moment(item.beginTime).format('YYYY-MM-DD') : '' || '无'
        params.sdxsName = item.sdxsName || '无';
        params.danhao = item.danhao || '无';
        params.sdjg = item.sdxs == "0" ? this.yjsd(item.sendEnd) : item.sdxs == "1" ? this.dmsd(item.sendEnd) : '无' || '无';
        params.endTime = item.endTime != null ? moment(item.endTime).format('YYYY-MM-DD') : '' || '无'
        params.remarks = item.remarks || '无';
        if (dataname == "sjwssd") {
          this.sjwssd.push(params);
        } else if (dataname == "ztwssd") {
          this.ztwssd.push(params);
        } else if (dataname == "ktwssd") {
          this.ktwssd.push(params);
        } else if (dataname == "cjwssd") {
          this.cjwssd.push(params);
        } else if (dataname == "tjwssd") {
          this.tjwssd.push(params);
        } else if (dataname == "cajdwssd") {
          this.cajdwssd.push(params);
        } else if (dataname == "xlyyjdwssd") {
          this.xlyyjdwssd.push(params);
        }

      })
    },
    dmsd(type) {
      switch (type) {
        case '0':
          return '签收';
        case '1':
          return '拒收';
        case '2':
          return '留置';
        default:
          return '无';
      }
    },
    yjsd(type) {
      switch (type) {
        case '0':
          return '退件';
        case '1':
          return '妥投';
        case '2':
          return '其他';
        default:
          return '无';
      }
    },
    bsdr(type) {
      switch (type) {
        case '0':
          return '申请人';
        case '1':
          return '被申请人';
        default:
          return '无';
      }
    },
  }
}
</script>

<style scoped>
.bolder-and-larger-text {
  font-weight: bold;
  font-size: 1.2em;
}
</style>