<template>
  <div>
    <div v-if="isLoading" class="loading-container">
      <loading></loading>
    </div>
    <div v-else>
      <ul>
        <li>
          <a style="cursor:pointer;" @click="openCenter(selectedyear,'shouan')">
            <h3>
              <span class="counter">{{ shouanshu }}</span>
            </h3>
            <p>收案数</p>
          </a>
        </li>
        <!--    <li>
              <h3><span class="counter" style="text-align: center">{{ biaodee }}</span></h3>
              <p>标的额(万元)</p>
            </li>-->
        <li>
          <a style="cursor:pointer;" @click="openCenter(selectedyear,'weijie')">
            <h3><span class="counter" style="text-align: center">{{ zaiban }}</span></h3>
            <p>在办案件数</p>
          </a>
        </li>
        <li>
          <a style="cursor:pointer;" @click="openCenter(selectedyear,'jiean')">
            <h3><span class="counter">{{ jieanshu }}</span></h3>
            <p>结案数</p>
          </a>
        </li>
        <li>
          <h3><span class="counter">{{ banantianshu }}</span></h3>
          <p>平均办案天数</p>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  name: "dynamicUl",
  components: {},
  props: {
    selectedyear: Number
  },
  data() {
    return {
      shouanshu: 0,
      biaodee: 0,
      jieanshu: 0,
      banantianshu: 0,
      zaiban: 0,
      isLoading: true, // 加载状态标志
    }
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    selectedyear(newYear, oldYear) {
      this.init();
    }
  },
  //方法集合
  methods: {
    init() {
      this.isLoading = true; // 开始加载数据
      this.axios.get('/pingZhan/report/dynamicUl', {
        params: {
          currentYear: this.selectedyear
        }
      }).then((response) => {
        let data = response.data;
        //console.log('data:');
        //console.log(data);
        if (data.code == 200) {
          this.shouanshu = data.data.shouanshu;
          this.biaodee = data.data.biaodee;
          this.jieanshu = data.data.jieanshu;
          this.zaiban = data.data.zaiban;
          if (data.data.banantianshu == null) {
            this.banantianshu = 0;
          } else {
            this.banantianshu = (data.data.banantianshu.total_day / this.jieanshu).toFixed(1);
          }
          this.isLoading = false; // 数据加载完成
        } else {
          console.log('数据获取失败');
          this.isLoading = false; // 数据加载完成
        }
      }).catch((error) => {
        console.error('发生错误:', error);
        this.isLoading = false; // 数据加载完成
      });
    },
    openCenter(item, caseType) {
      this.$parent.showCenter(true);
      this.$parent.openPopup2(caseType, item);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  }
};
</script>

<style scoped>
@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* 向上移动 */
  }
}

.counter {
  animation: floatAnimation 2s ease-in-out infinite; /* 应用动画 */
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
