<template>
  <div id="page1">
    <div :class="{ 'active1': isExpanded }" class="mcpg mcpg1">
      <div class="mcbd">
        <div class="wenshubox">
          <!--          左-->
          <left :user="user" @update-iframe="handleIframeUpdate"></left>
          <!--          右-->
          <div class="wenr">
            <ul>
              <li @click="toggleList" class="zhank"><a href="#">
                <img alt=""
                     src="@/assets/image/zcy/kico10.png"><i>{{ isExpanded ? '收起列表' : '展开列表' }}</i></a>
              </li>
            </ul>
            <!--            <iframe frameborder="0"
                                :src="iframeSrc"
                                style="width: 100%;min-height: 80vh;"></iframe>-->
            <el-image :src="iframeSrc"></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import left from "./left.vue"

export default {
  name: "zcy_documents",
  components: {
    left
  },
  props: {
    user: Object
  },
  data() {//数据
    return {
      isExpanded: false,
      iframeSrc: "",
      id: null,
    }
  },
  methods: {
    toggleList() {
      this.isExpanded = !this.isExpanded;
    },
    handleIframeUpdate(newUrl) {
      console.log(newUrl);
      this.iframeSrc = newUrl;
    }
  },
  created() {
    this.id = this.$route.params.id;
  },
}
</script>


<style scoped>

</style>