<template>
  <div>
    <div v-if="isLoading" class="loading-container">
      <loading></loading>
    </div>
    <div v-else>
      <div v-if="data!=null">
        <ul>
          <li>
            <h6><span class="counter">{{
                (data[selectedyear].jieanshu / data[selectedyear].shouanshu * 100).toFixed(2)
              }}%</span></h6>
            <p>收结比</p>
          </li>
          <li style="cursor:pointer;" @click="$parent.openPopup('ycjas', null)">
            <h6><span class="counter" style="cursor:pointer;">{{ data[selectedyear].yichang }}</span></h6>
            <p>异常结案数</p>
          </li>
          <!--      <li>
                  <h6><span class="counter">{{ data[selectedyear].banantianshu.total_day }}</span></h6>
                  <p>办案天数</p>
                </li>
                <li>
                  <h6><span class="counter">{{
                      (data[selectedyear].jieanlv / data[selectedyear].shouanshu * 100).toFixed(2)
                    }}%</span></h6>
                  <p><i>正常审限<br/>
                    内结案率</i></p>
                </li>-->
          <li>
            <h6><span class="counter">{{
                (data[selectedyear].fading / data[selectedyear].shouanshu * 100).toFixed(2)
              }}%</span></h6>
            <p><i>法定审限<br/>
              内结案率</i></p>
          </li>
        </ul>
        <div class="uittab">
          <div class="tps">
            <h3>年度</h3>
            <h3>收结比</h3>
            <h3>异常结案<br/>数</h3>
            <!--        <h3>异常结案<br/>数</h3>-->
            <!--            <h3>平均办案<br/>
                          天数</h3>-->
            <!--        <h3>正常审限<br/>
                      内结案率</h3>-->
            <h3>法定审限<br/>
              内结案率</h3>
            <h3>长期未结<br/>
              案件数量</h3>
          </div>
          <dl>
            <template v-for="(item,index) in list">
              <dd>
                <h4>{{ item.year }}</h4>
                <h4>{{ (item.data.jieanshu / item.data.shouanshu * 100).toFixed(2) }}%</h4>
                <h4>{{ item.data.yichang }}</h4>
                <!--            <h4>{{ parseInt((item.data.jieanshu / item.data.shouanshu) * 100) }}%</h4>-->
                <text v-if="item.inspector">
                  <h4>{{ (item.data.banantianshu.total_day / item.data.jieanshu).toFixed(1) }}</h4>
                </text>
                <!--            <h4>{{ (item.data.jieanlv / item.data.shouanshu * 100).toFixed(2) }}%</h4>-->
                <h4>{{ (item.data.fading / item.data.shouanshu * 100).toFixed(2) }}%</h4>
                <h4>{{ item.data.changqiweijie }}</h4>
              </dd>
            </template>
          </dl>
        </div>
      </div>
    </div>
  </div>

</template>
<!--
/**
 * @Description
 * @className CaseClosureAssistant.vue
 * @author Zhu
 * @date 2023-10-07 10:49
 * @TODO 办案效率
 * @param
 * @return
 */
-->
<script>
export default {
  name: "CaseEfficiencyOptimizer",
  components: {},
  props: {selectedyear: Number},
  data() {
    return {
      data: null,
      list: [],
      isLoading: true, // 加载状态标志
    }
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    selectedyear(newYear, oldYear) {
      if (newYear != 2021) {
        this.init();
      }
    }
  },
  //方法集合
  methods: {
    init() {
      this.isLoading = true; // 开始加载数据
      this.axios.get('/pingZhan/report/CaseEfficiencyOptimizer', {
        params: {
          currentYear: this.selectedyear
        }
      }).then((response) => {
        //console.log(response);
        let data = response.data;
        //console.log(data);
        if (data.code == 200) {
          this.data = data.data;
          // 使用Object.keys()提取对象的键（年份）
          const years = Object.keys(this.data);
          // 创建一个新数组，将年份和对应数据组合成对象
          const dataArray = years.map(year => ({
            year: year,
            data: this.data[year]
          }));
          dataArray.reverse();
          this.list = dataArray;
        } else {
          console.log('数据获取失败');
        }
      }).catch((error) => {
        console.error('发生错误:', error);
      }).finally(() => {
        this.isLoading = false; // 数据加载完成
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  }
};
</script>

<style scoped>
@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* 向上移动 */
  }
}

.counter {
  animation: floatAnimation 2s ease-in-out infinite; /* 应用动画 */
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
