<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>核阅</h3>
    </div>
    <ul v-if="hyList" class="ul1">
      <li>仲裁庭意见是否一致：<i v-if="hyList.zctOption">{{ yjsfyz(hyList.zctOption) }}</i><i v-else>无</i></li>

      <li>办案秘书核阅意见：<i v-if="hyList.bananInfo.ms_hy">{{ yj(hyList.bananInfo.ms_hy) }}</i><i v-else>无</i></li>
      <li>秘书核阅原因简述：<i v-if="hyList.bananInfo.ms_hyReason">{{ hyList.bananInfo.ms_hyReason }}</i><i v-else>无</i>
      </li>

      <li>组长核阅意见：<i v-if="hyList.bananInfo.zz_hy">{{ yj(hyList.bananInfo.zz_hy) }}</i><i v-else>无</i></li>
      <li>组长核阅原因简述：<i v-if="hyList.bananInfo.zz_hyReason">{{ hyList.bananInfo.zz_hyReason }}</i><i v-else>无</i>
      </li>

      <li>是否核阅：<i v-if="hyList.bananInfo.cz_hy">{{ yj(hyList.bananInfo.cz_hy) }}</i><i v-else>无</i></li>
      <li>核阅原因简述：<i v-if="hyList.bananInfo.czhyReason">{{ hyList.bananInfo.czhyReason }}</i><i v-else>无</i></li>

      <li>报送核阅表：<i v-if="hyList.hyb">{{ hyList.hyb }}</i><i v-else>无</i></li>

    </ul>
    <ul v-else class="ul1">
      <li>(暂无核阅信息)</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CheckAndApprove",
  props: {
    bananId: String,
    caseNum: String,
  },
  data() {
    return {
      hyList: null,
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.CheckAndApprove_init();
        }
      }
    },
    hyList(newVal) {
      if (newVal) {
        const hasData = !!newVal;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    yj(type) {
      switch (type) {
        case '5':
          return '未选择';
        case '1':
          return '建议核阅';
        case '2':
          return '核阅-其他原因';
        default:
          return '无';
      }
    },
    yjsfyz(type) {
      switch (type) {
        case '0':
          return '一致';
        case '1':
          return '不一致';
        default:
          return '无';
      }
    },
    CheckAndApprove_init() {
      //alert(this.bananId);
      this.axios.get('/api/handling/CheckAndApprove', {
        //params: {id: '1b170bee75ef43c2b83f03c057135dc4'}
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.hyList = data.data;
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

</style>