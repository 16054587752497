<template>
  <div class="item">
    <div class="he">
      <h3>工作经历</h3>
    </div>
    <el-table
        :data="tableData"
        stripe
        style="width: 100%">
      <el-table-column
          prop="xh"
          label="序号"
          width="50">
      </el-table-column>
      <el-table-column
          prop="entryTime"
          label="入职时间">
      </el-table-column>
      <el-table-column
          prop="resignationTime"
          label="离职时间">
      </el-table-column>
      <el-table-column
          prop="companyName"
          label="公司名称">
      </el-table-column>
      <el-table-column
          prop="SubordinatePost"
          label="所从岗位">
      </el-table-column>
      <el-table-column
          prop="jobContent"
          label="工作内容">
      </el-table-column>
      <el-table-column
          prop="companyProfile"
          label="公司简介">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "work",
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    initStudy() {
      this.axios.get('/pingZhan/inquire/zcyWorkById', {
        params: {
          id: this.$route.params.id
        }
      }).then(response => {
        const data = response.data;
        if (data.code == 200) {
          //data中取到的数据
          const originalData = data.data;
          //按照tableData格式转换为新数组，如果不存在则默认“暂无”
          const newData = originalData.map(item => {
            return {
              xh: item.xh || "暂无",
              entryTime: item.inwork_data || "暂无",
              resignationTime: item.outwork_data || "暂无",
              companyName: item.work_name || "暂无",
              SubordinatePost: item.post || "暂无",
              jobContent: item.ex_gs_content || "暂无",
              companyProfile: item.ex_gs_profile || "暂无",
            };
          });
          this.tableData = newData;
        } else {
          this.$message.error("获取工作经历失败")
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.initStudy();
  }
}
</script>

<style scoped>

</style>