<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>被撤销或不予执行</h3>
    </div>
    <ul v-if="repealList" class="ul1">
      <li>类型：<i v-if="repealList.askTypeName">{{ repealList.askTypeName }}</i><i v-else>无</i></li>
      <li>时间：<i v-if="repealList.update_date">{{ repealList.update_date }}</i><i v-else>无</i></li>
      <li>理由：<i v-if="repealList.executereason">{{ repealList.executereason }}</i><i v-else>无</i></li>
    </ul>
    <ul v-else class="ul1">
      <li>(暂无被撤销或不予执行信息)</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "repeal",
  props: {
    bananId: String,
    caseNum: String,
  },
  data() {
    return {
      repealList: null,
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.repeal_init();
        }
      }
    },
    tableData(newVal) {
      if (newVal) {
        const hasData = newVal.bsqr && newVal.bsqr.length > 0;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    repeal_init() {
      //alert(this.bananId);
      this.axios.get('/api/handling/repeal', {
        //params: {id: 'a9b9f44e61a0463992d82b26ba99f2ce'}
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        //console.log(data);
        if (data.code == 200) {
          this.repealList = data.data;
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

</style>