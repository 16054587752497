import zcy_details from "@/views/zcy/zcy_details/zcy_details.vue";
import zcy_documents from "@/views/zcy/zcy_documents/zcy_documents.vue";
import zcy_handling from "@/views/zcy/zcy_handling/zcy_handling.vue";
import zcy_reward from "@/views/zcy/zcy_reward/zcy_reward.vue";
import zcy_point from "@/views/zcy/zcy_point/zcy_point.vue";
import zcyRight from "./zcy_handling_right";


export default [
    {//仲裁员-基本信息
        path: '/zcy_details/:id',
        name: 'zcy_details',
        component: zcy_details,
    },
    {//仲裁员-证件信息
        path: '/zcy_documents/:id',
        name: 'zcy_documents',
        component: zcy_documents
    },
    {//仲裁员-办案信息
        path: '/zcy_handling/:id',
        name: 'zcy_handling',
        redirect: '/zcy_handling/:id/right/all-cases', //重定向到子路由第一个
        component: zcy_handling,
        children: zcyRight
    },
    {//仲裁员-报酬信息
        path: '/zcy_reward/:id',
        name: 'zcy_reward',
        component: zcy_reward
    },
    {//仲裁员-扣分
        path: '/zcy_point/:id',
        name: 'zcy_point',
        component: zcy_point
    },
];