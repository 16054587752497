<template>
  <div class="item" v-if="user">
    <div class="he">
      <h3>银行卡号</h3>
    </div>
    <el-table
        :data="tableData"
        stripe
        style="width: 100%">
      <el-table-column
          prop="xh"
          label="序号"
          width="50">
      </el-table-column>
      <el-table-column
          prop="card"
          label="银行卡号">
      </el-table-column>
      <el-table-column
          prop="name"
          label="开户行名称">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "bank",
  props: {
    user: Object
  },
  data() {
    return {
      tableData: []
    }
  },
  watch: {
    // 监视 user 对象的变化
    user: {
      deep: true,
      handler(newValue, oldValue) {
        // 当 user 发生变化时，调用 checkBankCard 方法
        this.checkBankCard(newValue);
      }
    }
  },
  methods: {
    checkBankCard(user) {
      if (user && user.is_bank_card === '0') {
        const bankCardNum = user.bank_card_num;
        const openBankName = user.openbank_name;
        const newData = {
          xh: "1",
          card: user.bank_card_num || "暂无",
          name: user.openbank_name || "暂无",
        };
        this.tableData.push(newData);
      } else {
        console.log("该仲裁员无银行卡")
      }
    }
  }
}
</script>

<style scoped>

</style>