<template>
  <el-container>
    <el-aside width="250px">
      <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
      >
        <el-menu-item index="1" @click="navigate('全部案件')">全部案件</el-menu-item>
        <el-menu-item index="2" @click="navigate('已办结')">已办结</el-menu-item>
        <el-menu-item index="3" @click="navigate('未办结')">未办结</el-menu-item>
        <el-menu-item index="4" @click="navigate('超期办结')">超期办结</el-menu-item>
        <el-menu-item index="5" @click="navigate('超期未办结')">超期未办结</el-menu-item>
        <el-menu-item index="6" @click="navigate('长期未结')">长期未结</el-menu-item>
        <el-menu-item index="7" @click="navigate('鉴定')">鉴定</el-menu-item>
        <el-menu-item index="8" @click="navigate('中止')">中止</el-menu-item>
        <el-menu-item index="9" @click="navigate('延期')">延期</el-menu-item>
        <el-menu-item index="10" @click="navigate('被撤销')">被撤销</el-menu-item>
        <el-menu-item index="11" @click="navigate('不予执行')">不予执行</el-menu-item>
        <el-menu-item index="12" @click="navigate('逾期归档案件')">逾期归档案件</el-menu-item>
      </el-menu>
    </el-aside>

    <el-main>

    </el-main>
  </el-container>
</template>


<script>
export default {
  name: "left",
  data() {
    return {};
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    navigate(routeName) {
      this.$router.push({name: routeName}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          // 处理或抛出其他错误
          throw err;
        }
        // 忽略 NavigationDuplicated 错误
      });
    },

  }
}
</script>

<style scoped>

</style>
