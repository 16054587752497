<template>
  <div>
    <h2 class="mctit">
      <img alt="" src="../../assets/image/zcy/kico1.png">
      {{ caseNum }}
    </h2>
    <div class="mchd">
      <ul>
        <li :class="{ on: isActive('/handling_details' ) }">
          <router-link :to="{ name: 'handling_details', params: { id: id } }">案件详情</router-link>
        </li>
        <li :class="{ on: isActive('/handling_writ' ) }">
          <router-link :to="{ name: 'handling_writ', params: { id: id }}">文书信息</router-link>
        </li>
        <li :class="{ on: isActive('/handling_pay' ) }">
          <router-link :to="{ name: 'handling_pay', params: { id: id } }">交费信息</router-link>
        </li>
        <li :class="{ on: isActive('/handling_record' ) }">
          <router-link :to="{ name: 'handling_record', params: { id: id } }">档案信息</router-link>
        </li>
        <li :class="{ on: isActive('/handling_assess' ) }">
          <router-link :to="{ name: 'handling_assess', params: { id: id } }">量化考核表</router-link>
        </li>
      </ul>
      <!--      <h6>案件状态：<a href="#">案件查看</a></h6>-->
    </div>
  </div>
</template>

<script>

export default {
  name: "top",
  props: {
    id: String,
    caseNum: String
  },
  computed: {
    // 计算属性用于判断路由
    isActive() {
      return (path) => {
        return this.$route.path.includes(path);
      };
    },
  },

}
</script>

<style scoped>

</style>