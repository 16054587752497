<template>
  <div v-if="caseNum" class="item">
    <div class="he">
      <h3>开庭信息</h3>
    </div>
    <el-table
        :cell-style="{
      background:'#fff',
      color: '#7883c5'}"
        :data="tableData"
        :header-cell-style="{
      background:'#F5F7FA',
      color: '#3d4053'}"
        border
        stripe
        style="width: 100%">
      <el-table-column
          align="center"
          label="开庭时间"
          prop="ktTime"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="结束时间"
          prop="endTime"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="开庭地点"
          prop="ktAddress"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="开庭结果"
          prop="ktResult"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="笔录"
          prop="biluUrl"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="备注"
          prop="remark"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "Court",
  props: {
    bananId: String,
    caseNum: String,
  },
  data() {
    return {
      CourtList: null,
      tableData: [],
    }
  },
  watch: {
    bananId: {
      immediate: true, // 立即执行一次
      handler(newValue, oldValue) {
        if (newValue) {
          this.Court_init();
        }
      }
    },
    CourtList(newVal) {
      if (newVal) {
        const hasData = newVal && newVal.length > 0;
        this.$emit('data-exists', {componentName: this.$options.name, dataExists: hasData});
      }
    }
  },
  methods: {
    Court_init() {
      this.axios.get('/api/handling/Court', {
        params: {id: this.bananId}
      }).then(response => {
        let data = response.data
        console.log(data);
        if (data.code == 200) {
          this.CourtList = data.data.court;
          this.CourtList.forEach((item) => {
            let params = {};
            params.ktTime = moment(item.kaitingDate).format('YYYY-MM-DD HH:mm:ss') || '无';
            params.endTime = moment(item.endDate).format('YYYY-MM-DD HH:mm:ss') || '无';
            params.ktAddress = item.addressName || '无';
            params.ktResult = this.ktEnd(item.ktEnd) || '无';
            params.biluUrl = item.biluUrl !== '' ? '已上传' : '暂无';
            params.remark = item.remarks || '无';
            this.tableData.push(params);
          })
        }
      }).catch(error => {
        console.log(error);
      });
    },
    ktEnd(type) {
      switch (type) {
        case '0':
          return '正常开庭';
        case '1':
          return '未开庭';
        case '2':
          return '正常退回';
        default:
          return '无';
      }
    },
  }
}


</script>

<style scoped>

</style>


