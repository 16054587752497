import handling_assess from '@/views/case/assess/handling_assess.vue'
import handling_details from '@/views/case/details/handling_details.vue'
import handling_record from '@/views/case/record/handling_record.vue'
import handling_writ from '@/views/case/writ/handling_writ.vue'
import handling_pay from '@/views/case/pay/handling_pay.vue'

export default [
    {//案件详情
        path: 'handling_details',
        name: 'handling_details',
        component: handling_details
    },
    {//文书信息
        path: 'handling_writ',
        name: 'handling_writ',
        component: handling_writ
    },
    {//交费信息
        path: 'handling_pay',
        name: 'handling_pay',
        component: handling_pay
    },
    {//档案信息
        path: 'handling_record',
        name: 'handling_record',
        component: handling_record
    },
    {//量化考核表
        path: 'handling_assess',
        name: 'handling_assess',
        component: handling_assess
    },
];